import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import trackAction from '../../Utilities/tracker';
import Button from '@material-ui/core/Button';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  presenterImage: {
    padding: theme.spacing(6),
    display: 'block',
    borderRadius: '35px',
    maxHeight: '240px',
    margin: 'auto',
  },
  exitTab: {
    color: '#b30000CC',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  underTabsContainer: {
    padding: theme.spacing(2),
  },
  presenterTab: {
    maxWidth: '50vw',
  },
  iFrameContainer: {
    overflow: 'hidden',
    positon: 'relative',
    textAlign: 'center',
  },
  video: {
    borderRadius: '15px',
    minWidth: '426px',
    minHeight: '240px',
    maxHeight: '83vh',
    maxWidth: '100%',
  },
  posterPDF: {
    borderRadius: '15px',
    height: '83vh',
    width: '80%',
  },
  presenterBio: {
    overflow: 'hidden',
    positon: 'relative',
  },
  button: {
    color: "white", 
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
      },
    textDecoration: 'none',
    marginTop: theme.spacing(1),
},
divider: {
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
},
linePhoto: {
  borderRadius: "50%",
  width: "5rem",
},
lineTitle: {
  fontSize: "1.5rem",
  fontWeight: "bold",
  textAlign: "left",
  paddingBottom: theme.spacing(1),
},
lineAbstract: {
  fontSize: "1rem",
  textAlign: "left",
},
lineSpeakerName: {
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(1),
},
lineSpeakerInfo: {
  fontSize: "1rem",
  textAlign: "left",
},
tabSingle: {
  minWidth: 'auto',
}
});

class PosterFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      poster: null,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  
  recordEvent = (data, name) => {
    let newName = name;
if (name === 'Agenda' && this.props.agenda.archive_link) {
  newName = 'Archive'
}
    const event = {
      action: "Clicked",
      resource_type: newName,
      resource_id: data.abstract_id,
      url_visited: '/agenda',
      resource_account_id: data.account_id,
      resource_account_name: data.name,
      resource_name: data.title,
    }

    trackAction(
      event
      )
  }

  componentDidMount() {

      this.props.agenda.presenters = this.props.agenda.contacts.filter((contact) => contact.role.toLowerCase() === 'presenter')
      this.props.agenda.authors = this.props.agenda.contacts.filter((contact) => contact.role.toLowerCase() === 'author')
      this.props.agenda.submitters = this.props.agenda.contacts.filter((contact) => contact.role.toLowerCase() === 'submitter')

      this.setState({
        agenda: this.props.agenda
      })
      
      this.recordEvent(this.props.agenda, "Agenda")        

  }

  briefcaseSelect (){
    this.setState({tabValue: this.state.tabValue,})

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (this.props.briefcase.briefcase[n].resource_id === this.state.agenda.abstract_id) {
          favorited = true;
          break;
        }
      }
    }
    
    if(favorited){
      this.props.deleteBriefcaseElement(this.state.agenda.abstract_id)

    } else {
      this.props.addBriefcaseElement(
        'Poster',
        this.state.agenda.abstract_id,
        this.state.agenda.title,
        this.state.agenda.account_id,
        this.props.user.user.account_id
      )
    }

  }


  render() {
    const { classes } = this.props;
    let agendaContent;

    if (this.state.agenda){
    switch (this.state.tabValue) {
      case 1:
        agendaContent = (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant='h4' color='textPrimary'>
                {this.state.agenda.title}
              </Typography>
              <br/>
              <Typography variant='h6' color='textPrimary'>
                <b>Topic: </b>{this.state.agenda.topic}
              </Typography>
              <br/>
              <Divider className={classes.divider} variant="fullWidth" />
            </Grid>
            <Grid item xs={12} sm={4}>
            {this.state.agenda.presentation_link ? 
            <a target="_blank" rel="noopener noreferrer" href={this.state.agenda.presentation_link} style={{textDecoration: 'none'}}>
              <Button color="inherit" variant="contained" className={classes.button}>
                Meet Me
              </Button>
            </a>
            : 
            <Button disabled color="inherit" variant="contained" className={classes.button}>
              Meet Me
            </Button>
            }
          </Grid>
          <Grid item xs={12} sm={4}>
            {this.state.agenda.presentation_pdf ?
            <React.Fragment>
              <a target="_blank" rel="noopener noreferrer" href={this.state.agenda.presentation_pdf} style={{textDecoration: 'none'}}>
                <Button color="inherit" variant="contained" className={classes.button} onClick={() => this.recordEvent(this.props.agenda, "Presentation")}>
                  Poster PDF
                </Button>
              </a>
            </React.Fragment>
            : 
            <Button disabled color="inherit" variant="contained" className={classes.button}>
              Poster PDF
           </Button>
            }
            </Grid>
            <Grid item xs={12} sm={4}>
              {this.state.agenda.presentation_video ? 
              <a target="_blank" rel="noopener noreferrer" href={this.state.agenda.presentation_video} style={{textDecoration: 'none'}}>
                <Button color="inherit" variant="contained" className={classes.button}>
                  Intro Video
                </Button>
              </a>
              : 
              <Button disabled color="inherit" variant="contained" className={classes.button}>
                Intro Video
              </Button>
              }
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} variant="fullWidth" />
              <br/>
              <Typography variant='h6' color='textPrimary' align='center'>
                <b>Abstract </b>
              </Typography>
              <pre style={{whiteSpace: "pre-line", wordWrap: "break-word",}}>
              <Typography variant='body1' color='textPrimary' align="justify">
                {this.state.agenda.content}
              </Typography>
              </pre>
            </Grid>
          </React.Fragment>
        );
        break;
      case 2:
        const keyValue = (key, value) => {
          if (value) {
            return <><b>{key}:</b> {value}</>
          }
        }
        agendaContent = (
          <React.Fragment>
              <Grid item xs={12}>
              {this.state.agenda.presenters.length ?
                  <React.Fragment>
                    <Typography variant='h5' color='textPrimary'>
                      <b>Presenters</b>
                      </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                    </React.Fragment>
                    : <React.Fragment/>}
                {this.state.agenda.presenters.map((presenter, index)=>{
                  return (
                    <Grid container key={index}>
                      <Grid item xs={12}>
                          <Typography className={classes.lineSpeakerName} variant='h6'>
                            {`${presenter.first_name} ${presenter.last_name}`}
                          </Typography>
                        </Grid>
                      <Grid item xs={2} >
                      {presenter.photo_url ? (
                        <img
                        className={classes.linePhoto}
                        src={presenter.photo_url}
                        alt={"Presenter"}
                        />
                        ) : (
                            null
                        )}
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Title', presenter.title)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Account', presenter.account_name)}
                        </Typography>
                        <Typography variant='body1' className={classes.lineSpeakerInfo}>
                          {keyValue('Department', presenter.department)}
                        </Typography>
                        <Typography variant='body1' className={classes.lineSpeakerInfo}>
                          {keyValue('Expertise', presenter.expertise_area)}
                        </Typography>
                        <Typography className={classes.lineSpeakerInfo}>
                          {keyValue('Email', presenter.email)}
                        </Typography>
                        <Typography variant='body1' className={classes.lineSpeakerInfo}>
                          {keyValue('Biography', presenter.bio)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={classes.divider} variant="fullWidth" />
                      </Grid>
                    </Grid>
                    )
                  })}
              </Grid>
              <Grid item xs={12} style={{paddingTop: '3vw'}}>
                {this.state.agenda.authors.length ?
                  <React.Fragment>
                    <Typography variant='h5' color='textPrimary'>
                      <b>Authors</b>
                      </Typography>
                    <Divider className={classes.divider} variant="fullWidth" />
                    </React.Fragment>
                    : <React.Fragment/>}
                  {this.state.agenda.authors.map((author, index)=>{
                    return (
                      <Grid container key={index}>
                        <Grid item xs={12}>
                          <Typography className={classes.lineSpeakerName} variant='h6'>
                            {`${author.first_name} ${author.last_name}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} >
                          {author.photo_url ? (
                          <img
                          className={classes.linePhoto}
                          src={author.photo_url}
                          alt={"Presenter"}
                          />
                          ) : (
                              null
                          )}
                        </Grid>
                        <Grid item xs={10}>
                          <Typography className={classes.lineSpeakerInfo}>
                            {keyValue('Title', author.title)}
                          </Typography>
                          <Typography className={classes.lineSpeakerInfo}>
                            {keyValue('Account', author.account_name)}
                          </Typography>
                          <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Department', author.department)}
                          </Typography>
                          <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Expertise', author.expertise_area)}
                          </Typography>
                          <Typography className={classes.lineSpeakerInfo}>
                            {keyValue('Email', author.email)}
                          </Typography>
                          {/* <Typography variant='body1' className={classes.lineSpeakerInfo}>
                            {keyValue('Biography', author.bio)}
                          </Typography> */}
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.divider} variant="fullWidth" />
                        </Grid>
                      </Grid>
                      )
                    })}
                </Grid>
          </React.Fragment>
        );
        break;
      default:
        agendaContent = <div>No Content</div>;
        break;
    }
  } else {
    agendaContent = <div></div>;
    return <div></div>
  }

  let favorited = false;
  if (this.props.briefcase.briefcase) {
    for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
      if (this.props.briefcase.briefcase[n].resource_id === this.state.agenda.abstract_id) {
        favorited = true;
        break;
      }
    }
  }

    return (
      <Grid
        container
        direction='row'
        justify='center'
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={2} align='left'>
          <Button onClick={() => this.props.closeAgenda()}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid item xs={8}>
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              {favorited ?
                <Tab 
                  className={classes.tabSingle} 
                  onClick={() => {
                    this.briefcaseSelect()
                  }} 
                  icon = {<WorkIcon/>}
                />
                :
                <Tab 
                  className={classes.tabSingle} 
                  onClick={() => {
                    this.briefcaseSelect()
                  }}
                  icon = {<WorkOutlineIcon />}
              /> }
              <Tab className={classes.tabSingle} label={'Presentation'} />
              <Tab className={classes.tabSingle} label={'Contributors'} />
            </Tabs>
        </Grid>
        <Grid item xs={2} align='right'/>
        <Grid item xs={12} >
          <Paper className={classes.paper}>
            <Grid
              className={classes.underTabsContainer}
              container
              spacing={2}
              justify='center'
              alignItems='center'
            >
              {agendaContent}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(PosterFull));