import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import ExhibitBoothImage1 from '../../Components/Images/ExhibitBoothImage1-BACKUP';
import ExhibitBoothImage2 from '../../Components/Images/ExhibitBoothImage2';
import ExhibitBoothImage3 from '../../Components/Images/ExhibitBoothImage3';
import ExhibitBoothImage4 from '../../Components/Images/ExhibitBoothImage4';
import ExhibitBoothImage5 from '../../Components/Images/ExhibitBoothImage5';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExhibitProductItem from './ExhibitProductItem';
// import ExhibitResourceItem from './ExhibitResourceItem';
import Container from '@material-ui/core/Container';
import trackAction from '../../Utilities/tracker';
import BoothChat from './BoothChat';
import Chip from '@material-ui/core/Chip';
// import BoothStaff from './BoothStaff';
import BoothAbstracts from './BoothAbstracts';
// import PerkinElmerResources from './PerkinElmerResources';
import getValidUrl from '../../Utilities/getValidUrl'
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { eventId } from '../../config'

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  header: {
    margin: 'auto',
  },
  image: {
    maxHeight: '80vh',
  },
  imageMapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    borderRadius: '0.5%',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  mainPictureImage: {
    maxWidth: '100%',
  },
  gridContainer: {
    marginTop: theme.spacing(3),
    scrollBehavior: 'smooth',
  },
  sash: {
    marginTop: '-60px',
    marginRight: '-70px',
    width: '300px',
    position: 'sticky',
    top: '50px',
    left: 'auto',
    textAlign: 'center',
    lineHeight: '2.5em',
    color: 'white',
    transform: 'rotate(45deg)',
    webkitTransform: 'rotate(45deg)',
    boxShadow: '0 0 3px rgba(0,0,0,.3)',
    textShadow: '0px 5px 20px #000000CC',
    float: 'right',
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  tabSingle: {
    minWidth: 'auto',
  }
});

class ExhibitionBooth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      perkinTabValue: 0,
      visual: true,
      exhibit: null,
      products: [],
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleTabChangeImage = (event, newValue) => {
    this.setState({ tabValue: newValue+1 });
  };
  
  updateTabChangePerkinElmer = (event, newValue) => {
    this.setState({ perkinTabValue: newValue });
  };

  handleTabChangePerkinElmer = (event, newValue, perkinNewValue) => {
    this.setState({ tabValue: newValue, perkinTabValue: perkinNewValue });
  };

  recordEvent = (data) => {

    const event = {
      action: "Clicked",
      resource_type: "Exhibit",
      resource_id: data.exhibition_id,
      url_visited: '/exhibithall',
      resource_account_id: data.account_id,
      resource_account_name: data.name,
      resource_name: data.title ? data.title : data.name,
    }


    trackAction(
      event
    )
  }

  async componentDidMount() {

    try {

    const user = await Auth.currentSession()

    const exhibit = await axios({
      method: 'get',
      url: `/exhibitions/${eventId}/accountid/${this.props.boothId}`, //account id is arbitrary but necessary to hit proper route
      headers: { idtoken: user.idToken.jwtToken },
    })

    const products = await axios({
      method: 'get',
      url: `/products/${eventId}/${this.props.boothId}`,
      headers: { idtoken: user.idToken.jwtToken },
    })

    if(products.data.data.length){
      exhibit.data[0].products = products.data.data.sort((a,b) => {

        if(!a.booth_order){
          a.booth_order = 100
        }

        if(!b.booth_order){
          b.booth_order = 100
        }

        return a.booth_order - b.booth_order
      })

    } else {
      exhibit.data[0].products = []
    }

    await this.setState({
        exhibit: exhibit.data[0]
      })
      this.recordEvent(exhibit.data[0])


    } catch(error){

      this.setState({
        exhibit: null
      })
      if (this.props.debug.debug) {
        alert(`Error: Could not display Exhibit Booth. ${error}`)
      }
    }
  }

  handleVisualToggle = () => {
    this.setState({ visual: !this.state.visual });
  };

  briefcaseSelect (){

    this.setState({
      tabValue: this.state.tabValue,
    })

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (this.props.briefcase.briefcase[n].resource_id === this.state.exhibit.exhibition_id) {
          favorited = true;
          break;
        }
      }
    }
    
    if(favorited){
      this.props.deleteBriefcaseElement(this.state.exhibit.exhibition_id)

    } else {
      this.props.addBriefcaseElement(
        'Exhibit',
        this.state.exhibit.exhibition_id,
        this.state.exhibit.title,
        this.state.exhibit.account_id,
        this.props.user.user.account_id
      )
    }

  }

  render() {
    const { classes } = this.props;

    if (!this.state.exhibit) {
      return <div></div>
    }

    let exhibitBoothContent;
    switch (this.state.tabValue) {
      case 1: // overview
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Container>
              <br/><br/>
                <Typography color='textPrimary' variant='h4' gutterBottom align='center'>
                {this.state.exhibit.title}
              </Typography>
              <br/><br/>
              <Button variant='contained' size='large' disableElevation target='_blank' href={getValidUrl(this.state.exhibit.url)} style={{marginTop: '16px', marginBottom: '16px',}}>
                {this.state.exhibit.url ? this.state.exhibit.url : this.state.exhibit.account.website}
              </Button>
              <br /><br />
              <pre style={{maxWidth: '70vw', whiteSpace: "pre-line", wordWrap: "break-word",}}>
              <Typography color='textPrimary' variant='body1' gutterBottom align='justify'>
                {this.state.exhibit.main_text}
              </Typography>
              </pre>
              <br/><br/>
              <div className={classes.chips}>
                {this.state.exhibit.linked_in_tags ?
                  this.state.exhibit.linked_in_tags.split(',').map((tag, index) => {
                    return (
                      <Chip
                        key={index}
                        label={tag}
                        color='primary'
                      />
                    )
                  })
                  :
                  <div></div>
                }
              </div>
            </Container>
            <br /><br />
          </Grid>
        );
        break;
      case 2: // products & services
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Typography variant='h3' gutterBottom align='center'>
              {this.state.exhibit.products.map((product, index) => {
                return <ExhibitProductItem key={index} product={product} />;
              })}
            </Typography>
          </Grid>
        );
        break;
      case 3: // event activities
        exhibitBoothContent = (
          <Grid item xs={12}>
            <BoothAbstracts
              exhibit={this.state.exhibit}
            />
          </Grid>
        );
        break;
      default: 
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Typography variant='h3' gutterBottom align='center'>
              {this.state.exhibit.title}
            </Typography>
          </Grid>
        );
        break;
    }

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (this.props.briefcase.briefcase[n].resource_id === this.state.exhibit.exhibition_id) {
          favorited = true;
          break;
        }
      }
    }

    let tabRow = (
      <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
          >

            {favorited ?
            <Tab 
              className={classes.tabSingle} 
              onClick={() => {
                this.briefcaseSelect()
              }} 
              icon = {<WorkIcon/>}
            />
            :
            <Tab 
              className={classes.tabSingle} 
              onClick={() => {
                this.briefcaseSelect()
              }}
              icon = {<WorkOutlineIcon />}
            /> }
            <Tab className={classes.tabSingle} label={'Overview'} />
            <Tab className={classes.tabSingle} label={'Products & Services'} />
            <Tab className={classes.tabSingle} label={'Event Activities'} />
          </Tabs>
    )

    let boothHeader = (
      <React.Fragment>
        <Grid item xs={2} align='left'>
          <Button onClick={() => this.props.closeBooth()}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid container item xs={8} justify='center'>
          {tabRow}
        </Grid>
        <Grid item xs={2} align='right'>
          <Button onClick={this.handleVisualToggle}>
            Display Booth
            <Switch
              checked={this.state.visual}
              onChange={this.handleVisualToggle}
              color='primary'
              name='Visual'
              inputProps={{ 'aria-label': 'Visual' }}
            />
          </Button>
        </Grid>
      </React.Fragment>
    );
    let level;
    let color;
    let lightColor;
    switch (this.state.exhibit.booth_type) {
      case '1':
        color = '#0600b1';
        lightColor = '#554fff';
        level = 'Signature Sponsor';
        break;
      case '2':
        color = '#996ae6';
        lightColor = '#c2a7f0';
        level = 'Platinum Sponsor';
        break;
      case '3':
        color = '#ff9a0d';
        lightColor = '#fcb044';
        level = 'Gold Sponsor';
        break;
      case '4':
        color = '#9e9e9e';
        lightColor = '#c0c0c0';
        level = 'Silver Sponsor';
        break;
      case '7':
        color = '#9e9e9e';
        lightColor = '#c0c0c0';
        level = 'Media Partner';
        break;
      case '8':
        color = '#f0a82b';
        lightColor = '#edb85c';
        level = 'Event Manager';
        break;
        case '9':
          color = '#990000';
          lightColor = '#ab2b2b';
          level = 'University';
          break;
        case '10':
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Information';
          break;
        case '11':
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Host';
          break;
      default:
        color = '#ffffff00';
        lightColor = '#ffffff00';
        level = '';
        break;
    }

    let sash
    if (this.state.exhibit.type === 'Internal' || this.state.exhibit.type === 'Digital Lab' || this.state.exhibit.name === 'PlanetConnect, Event Manager') {
      sash = null;
    } else {
      sash = (
        <Typography
          variant='h5'
          className={classes.sash}
          color='textPrimary'
          style={{
            backgroundImage: `linear-gradient(${color}, ${lightColor})`,
          }}
        >
          {level}
        </Typography>
      );
    }

    let ExhibitBoothImageContainer;
    let booth_type = parseInt(this.state.exhibit.booth_type);
    switch (booth_type) {
      case 1:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage1
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 2:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage2
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 3:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage3
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 4:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            {sash}
            <ExhibitBoothImage4
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
        case 8:
          ExhibitBoothImageContainer = (
            <div style={{ overflow: 'hidden' }}>
              {sash}
              <ExhibitBoothImage2
                handleTabChange={this.handleTabChangeImage.bind(this)}
                exhibit={this.state.exhibit}
                tabValue={this.state.tabValue}
              />
            </div>
          );
          break;
        case 9:
          ExhibitBoothImageContainer = (
            <div style={{ overflow: 'hidden' }}>
              {sash}
              <ExhibitBoothImage3
                handleTabChange={this.handleTabChangeImage.bind(this)}
                exhibit={this.state.exhibit}
                tabValue={this.state.tabValue}
              />
            </div>
          );
          break;
      default:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: 'hidden' }}>
            <ExhibitBoothImage5
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              tabValue={this.state.tabValue}
              style={{ padding: '200px' }}
            />
          </div>
        );
        break;
    }

    let boothLayout;

    let chatBox = null;

    chatBox = (
      <Grid item xs={12} lg={12} align='center'>
        <Paper className={classes.paper} id={booth_type <= 2 ? 'goTo' : ''}>
          <BoothChat
            exhibit={this.state.exhibit}
            channel={this.state.exhibit.exhibition_id}
          />
        </Paper>
      </Grid>
    )

      if (this.state.visual) {


      boothLayout = (
        <React.Fragment>
          <Grid item xs={12} md={booth_type > 2 ? 6 : 12} align='center'>
            <Paper className={classes.paper}>
              {ExhibitBoothImageContainer}
            </Paper>
            {booth_type > 2 ? 
            <Paper className={classes.paper} style={{marginTop: '16px',}}>{exhibitBoothContent}</Paper> : 
            <React.Fragment/>}
          </Grid>          
          {booth_type <= 2 ? 
          <React.Fragment>
          <Grid container item xs={12} md={12} justify='center' style={{textAlign: 'center',}}>
            {tabRow}
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper} >
              {exhibitBoothContent}
            </Paper>
          </Grid>
          </React.Fragment> : 
          <React.Fragment/>}
          <Grid item xs={12} md={6} align='center'>
            {chatBox}
          </Grid>
        </React.Fragment>
      );
    } else {
      boothLayout = (
        <React.Fragment>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              {exhibitBoothContent}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} align='center'>
            {chatBox}
          </Grid>
        </React.Fragment>
      );
    }
    return (
      <Grid
        container
        className={classes.gridContainer}
        layout={'row'}
        spacing={2}
      >
        {boothHeader}
        {boothLayout}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    debug: state.debug,
    briefcase: state.briefcase,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ExhibitionBooth));
