
import { SET_SNACKBAR, REMOVE_SNACKBAR }  from '../Actions/types';

const initialState = {
    id: null,
    userClicked: false,
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_SNACKBAR:
            return {
                ...state,
                id: action.payload
            };
        case REMOVE_SNACKBAR:
            return {
                ...state,
                id: action.payload,
                userClicked: true
            };
        default:
            return state;
    }
}

export default reducer;