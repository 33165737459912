import { combineReducers } from 'redux';
import SidebarReducer from './sidebar';
import UserReducer from './user';
import BriefcaseReducer from './briefcase';
import ChatReducer from './chat';
import SnackbarReducer from './snackbar';
import DebugReducer from './debug';
import EventReducer from './event';

const rootReducer = combineReducers({
  sidebar: SidebarReducer,
  user: UserReducer,
  briefcase: BriefcaseReducer,
  chat: ChatReducer,
  snackbar: SnackbarReducer,
  debug: DebugReducer,
  event: EventReducer,
});

export default rootReducer;