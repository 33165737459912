import React, { Component } from 'react';

import PubNubReact from 'pubnub';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../../Store/Actions/getChatMessages';

const useStyles = theme => ({
    headings: {
      padding: theme.spacing(3),
    },
    inputControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
  title: {
    fontSize: 14,
  },

  root: {
    width: '95%',
    margin: theme.spacing(2),
},
paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
},
formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '80%'
},
formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '50%'
},
button: {
    color: "white",
    backgroundColor: theme.palette.primary.main
},
own: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    padding: '5px',
    marginBottom: '3px',
    borderRadius: '15px',
    wordWrap: "break-word",
    whiteSpace: "pre-line",
},
other: {
    whiteSpace: "pre-line",
    wordWrap: "break-word",
},
expansionPanelMain: { padding: '0px 8px 0px 8px' },
secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
},
icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
},
merck: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
}
});

class ChatPage extends Component{
    constructor(props) {
        super(props);
        this.pubnub = new PubNubReact({
            publishKey: this.props.event.event.publish_key,
            subscribeKey: this.props.event.event.subscribe_key,
            uuid: this.props.user.user.email,
            autoNetworkDetection: true,
            restore: true,
        });
        this.state = {
            memberships: [],
            subscriptions: [],
            channelOccupants: [],
            messages: [],
            selectedChat: null,
            input: '',
            
        }
    }
    

    async componentDidMount(){

    try {

    // Set the current user's meta data
      await this.pubnub.objects.setUUIDMetadata({
        data: {
            name: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
            email: this.props.user.user.email,
            custom: {
                accountName: this.props.user.user.account_name,
                department: this.props.user.user.department,
                site: this.props.user.user.site,
                title: this.props.user.user.title,
            }
        }
    });


    await this.pubnub.addListener({
        status: function (statusEvent) {
            console.log("Chat Page Status: ",statusEvent)
        },
        message: (response) => { 
            console.log("Chat Page New Message: ", response)

            if(response.channel === this.state.selectedChat){
                const newMessage = {
                    channel: response.channel,
                    timetoken: response.timetoken,
                    entry: {
                        from: response.message.from,
                        text: response.message.text,
                        accountName: response.message.accountName,
                        department: response.message.department,
                        email: response.message.email,
                        first_name: response.message.first_name,
                        last_name: response.message.last_name,
                        roles: response.message.roles,
                        site: response.message.site,
                        title: response.message.title,
                    }
                }
        
                this.setState({
                    messages: [...this.state.messages, newMessage]
                })
            }

        }, presence: function (presenceEvent) {
            console.log("Chat Page Presence:", presenceEvent)
            }
        })
  
        let memberships = [];
        let subscriptions = [];
  
        // Get all memberships that the current user is a part of
         const newMemberships = await this.pubnub.objects.getMemberships({
            uuid: this.props.user.user.email,
            include: {
                channelFields: true
            }
        });
  
        newMemberships.data.map((e) => memberships.push(e))
  
        // Subscribe to each one of these memberships
        await this.pubnub.subscribe({
            channels: [...newMemberships.data.map((element) => element.channel.id)],
            withPresence: true,
          });
  
        // Get the current user's subscribed channels.
        const newSubscriptions = await this.pubnub.getSubscribedChannels();
  
        newSubscriptions.map((e)=> subscriptions.push(e))

         await this.setState({
              memberships: [...memberships],
              subscriptions: [...subscriptions]
          })

          let users = [];

          const channelUsers = await memberships.map(membership => {
            return (
                this.pubnub.objects.getChannelMembers({
                    channel: membership.channel.id
                }).then((response) => {
                    let newChannelUser = response.data.filter((user) => user.uuid.id !==  this.props.user.user.email)[0]
                    newChannelUser.channel = membership.channel.id
                    users.push(newChannelUser)
            }).catch((err) =>{
                console.log('not found')
            })
            )
        })

        await Promise.all(channelUsers)

        let detailedUserArray = [];

        const detailedUsers = await users.map(user => {
            return (
            this.pubnub.objects.getUUIDMetadata({
                uuid: user.uuid.id,
            }).then((response) => {
                let newDetailedUser = response.data
                newDetailedUser.channel = user.channel
                detailedUserArray.push(response.data)
            }).catch((err) =>{
                console.log('not found')
            })
            )
        })

        await Promise.all(detailedUsers).then(res => {
            this.setState({
                channelOccupants: [...this.state.channelOccupants, ...detailedUserArray]
            })
        })

  
      } catch (error){
        console.log(error)
      }
    }

    handleChange(event, inputIdentifier) {
        this.setState({ [inputIdentifier]: event.target.value });
    }

      sendMessage = async () => {
        if (this.state.input) {
        try {
           await this.pubnub.publish(
                {
                    channel: this.props.chat.activePrivateChat,
                    message: {
                        text: this.state.input,
                        from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
                        accountName: this.props.user.user.account_name,
                        department: this.props.user.user.department,
                        email: this.props.user.user.email,
                        first_name: this.props.user.user.first_name,
                        last_name: this.props.user.user.last_name,
                        roles: this.props.user.user.roles,
                        site: this.props.user.user.site,
                        title: this.props.user.user.title,
                    }
                },
                await this.setState({
                    input: '',
                })
            );
        } catch (error){
            await this.setState({
                input: '',
            })
            console.log(error)
        }
        }
    }

      componentWillUnmount(){
        this.pubnub.removeListener(); 
      }

     async selectChat(channel){

        this.setState({
            selectedChat: channel
        })

        try { 

            await this.pubnub.history({
                channel: channel,
                count: 20, 
                stringifiedTimeToken: true, 
            },
                (status, response) => {
                    this.setState({
                        messages: [...response.messages]
                    })
                }
            );
    
      } catch (error) {
          console.log(error)
      }
      }

    render(){
      const { classes } = this.props;

      let occupants = this.state.channelOccupants.map((occupant, index) => {

        if(occupant.id === this.props.user.user.email){
            return <React.Fragment key={occupant.email}></React.Fragment>
        }

        let color = 'other'

        return (
            <Accordion
            key={occupant.email}
            className={classes.root}
          >
            <AccordionSummary
              className={classes.expansionPanelMain + " " + classes[color]}
              expandIcon={<ExpandMoreIcon />}
            >
            <Typography variant="body1" component='div'>
                    {occupant.name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                layout={'row'}
                spacing={2}
                alignItems='center'
                justify='space-between'
              >
                <Grid item xs={12}>
                <Typography variant="body1" align='left' component='div'>
                    {occupant.custom.accountName? <p><b>Company:</b> {occupant.custom.accountName}</p>: <p></p>}
                </Typography>
                <Typography variant="body1" align='left' component='div'>
                    {occupant.custom.title ? <p><b>Title:</b> {occupant.custom.title}</p>: <p></p>}   
                </Typography>
                <Typography variant="body1" align='left' component='div'>
                    {occupant.custom.department ? <p><b>Department:</b> {occupant.custom.department}</p>: <p></p>}
                </Typography>
                <Typography variant="body1" align='left' component='div'>
                    {occupant.custom.site ? <p><b>Site:</b> {occupant.custom.site}</p>: <p></p>}
                </Typography>
                <FormControl className={classes.formControlButton}>
                    <Button variant="contained" className={classes.button} onClick={() => this.selectChat(occupant.channel)} >
                         Enter Chat
                    </Button>
                </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )
    })
 
            let previousSender;
            let messages = this.state.messages.map((message) => {
                if (!message.entry.text) {
                    return null
                }
    
                let align = 'left'
                let color = 'other'
    
                if (message.entry.email === this.props.user.user.email) {
                    align = 'right'
                    color = 'own'
                }
    
                if (previousSender === message.entry.email) {
                    return (
                        <React.Fragment key={message.timetoken} >
                            <Typography variant="body1" component='p' align={align} className={classes[color]}>
                            {message.entry.text}
                            </Typography>
                        </React.Fragment>
                    )
                } else {
                    previousSender = message.entry.email
                    return (
                        <React.Fragment key={message.timetoken} >
                            <br />
                            <Typography variant="h6" component='p' align={align}>
                                {message.entry.from ? message.entry.from : 'Anonymous'}
                            </Typography>
                            <Typography variant="body1" component='p' align={align} className={classes[color]}>
                            {message.entry.text}
                            </Typography>
                           <br/>
                        </React.Fragment>
                    )
                }
            })
    
            return (
                    <Grid
                        className={classes.root}
                        container
                        layout={'row'}
                        spacing={2}
                        alignItems='center'
                        justify='space-between'
                        >
                        <Grid item xs={12} lg={6} style={{ maxHeight: '80vh', overflowY: 'scroll', overflowX: 'hidden', padding: '2vw' }}>
                        <Typography variant="h5" component='div' align='center'>
                            Private Chats
                        </Typography>
                            <br/><br/>
                            {occupants}
                        </Grid>
                        {this.state.selectedChat ?
                        <Grid item xs={12} lg={6} >
                        <Paper className={classes.paper} style={{ maxHeight: '60vh', overflowY: 'scroll', overflowX: 'hidden', padding: '2vw' }}>
                            {messages}
                        </Paper>
                        <Paper className={classes.paper} style={{ maxHeight: '20vh', padding: '2vw' }}>
                        <FormControl className={classes.formControl} fullWidth>
                                <TextField onChange={(event) => this.handleChange(event, "input")}
                                    value={this.state.input}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlButton}>
                                <Button variant="contained" onClick={this.sendMessage} className={classes.button}>
                                    Send
                             </Button>
                            </FormControl>
                        </Paper>
                        </Grid>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    </Grid>
        )
    }
}

const MyTheme = {
    button: { backgroundColor: '#6f1a1d' },
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getChatMessages: getChatMessages,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
        chat: state.chat,
        event: state.event,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(withAuthenticator(ChatPage, false, [], null, MyTheme))));
