import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setDebug from '../Store/Actions/setDebug';


const useStyles = theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
});

class Debug extends Component{

      handleChange = () => {
        let newState = !this.props.debug.debug
        this.props.setDebug(newState)

      }

    render(){
      const { classes } = this.props;

        return(
            <Grid container layout={"row"} spacing={2} className={classes.root} >
              <Grid item xs={12} md={12} lg={12}>
                <Card >
                  <CardContent>
                    <Typography variant='h4' align='center' gutterBottom>
                      Debug Mode
                    </Typography>
                    <Typography variant='body1' align='center'>
                      If enabled, errors will display throughout the system if you encounter an error. <br/>
                      Default is debug disabled and to not display error messages. 
                    </Typography>
                    <br/>
                    <Switch
                        checked={this.props.debug.debug}
                        onChange={this.handleChange}
                        color = "primary"
                    />
                    {this.props.debug.debug ? 
                    <Typography variant='body1' align='justify'>Enabled</Typography>:
                    <Typography variant='body1' align='justify'>Disabled</Typography>  
                    }
                  </CardContent>
                </Card>
                </Grid>
            </Grid>
        )
    }
}

const MyTheme = {
    button: { backgroundColor: "#E13E2F"},
  };

  function mapStateToProps(state) {
    return {
      debug: state.debug,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      setDebug: setDebug,
    }, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withAuthenticator(Debug, false, [], null, MyTheme)));