import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import AgendaRowItem from './WorkshopRowItem';
import { Typography, Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { posterSessionTopicImages } from '../../config';
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda'
import { eventId } from '../../config';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {
  },
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  button: {
    color: 'white',
    backgroundColor: '#6f1a1d',
  },
  card: {
    maxWidth: 345,
  },
  day: {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  }
});

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
      days: [],
      rooms: [],
      selectedDate: '',
      ETtoUTCoffset: '',
      timeDifference: '',
    };
  }
  componentDidMount() {
    this.props.setBriefcase();

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
          },
        })
          .then((response) => {

            console.log(response)

            // logic used to calculat difference betweeen ET and attendees time zone
            let newYork = momentTimezone.tz(new Date(), "America/New_York");
            // divide by 60 to convert from minutes to hours
            let ETtoUTCoffset = newYork._offset / 60
            // find offset between attendee timezone and UTC
            let currentZoneToUTCoffset = new Date().getTimezoneOffset();
            // set offset to match the timezone modifier such as -5:00 or +3:00. the negative is required.
            currentZoneToUTCoffset = -currentZoneToUTCoffset / 60
            // calculate difference between EST and attendee current timezone
            let timeDifference = currentZoneToUTCoffset - ETtoUTCoffset

            let abstracts = [];
            let days = [];
            let rooms = [];

            for (let x = 0; x < response.data.data.length; x++) {
              let abstract = response.data.data[x];
              abstract.date = moment(abstract.date, "YYYY/MM/DD").format();
              if (abstract.start_time && abstract.accepted_type === 'Workshop') {
                let startTimeEST = abstract.start_time
                abstract.start_time = moment(abstract.start_time, "HH:mm:ss").add(timeDifference, 'hours').format("HH:mm:ss")
                abstract.end_time = moment(abstract.end_time, "HH:mm:ss").add(timeDifference, 'hours').format("HH:mm:ss")
                if (parseInt(moment(startTimeEST, "HH:mm:ss").format("HH")) + timeDifference >= 24) {
                  abstract.date = moment(abstract.date, "YYYY/MM/DD").add(1, 'days').format()
                }
                if (parseInt(moment(startTimeEST, "HH:mm:ss").format("HH")) + timeDifference < 0) {

                  abstract.date = moment(abstract.date, "MM/DD/YYYY").add(-1, 'days').format()
                }
                abstracts.push(abstract);
              }

              if (abstract.date && !days.includes(abstract.date) && abstract.start_time) {
                days.push(abstract.date);
              }

              if (abstract.room && !rooms.includes(abstract.room) && abstract.start_time) {
                 if(abstract.room === "FEATURED"){
                  rooms.unshift(abstract.room);
                 } else{
                    rooms.push(abstract.room);
                 }
              }
            }
            
            abstracts.sort((a,b) => {
              return moment.duration(a.start_time).asMinutes() - moment.duration(b.start_time).asMinutes()
          });

            days.sort();

            const currentDayIndex = days.findIndex((day) => day.substring(0, 10) === moment().format("YYYY-MM-DD"))

            let currentDay;
            if (currentDayIndex > -1){
              currentDay = days[currentDayIndex]
            } else {
              currentDay = days[0]
            }
            this.setState({
              abstracts,
              days,
              rooms,
              selectedDate: currentDay,
              timeDifference: timeDifference,
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Agenda. ${error}`)
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Agenda. ${error}`)
        }
      });
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(this.props.event.event.available_routes).routes
    let pageTitle = ''
    allRoutes.forEach(route => {
      if (route.route === '/workshop') {
        pageTitle = route.title
      }
    })

    return (
      <React.Fragment>
        <Grid
          container
          layout={'row'}
          spacing={2}
          justify='center'
          className={classes.header}
        >
          <Grid item xs={12}>
              <Paper>
                <Typography style={{textAlign: 'center', padding: '16px 0px 8px 0px'}} variant='h4' color='textPrimary'>
                  {pageTitle}
                </Typography>
              </Paper>
            </Grid>
          {this.state.days.map((day, index) => {
            if (day === this.state.selectedDate) {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper
                    className={classes.paper + " " + classes.day}
                    variant='elevation'
                    elevation={10}
                  >
                    <Typography variant='h5'>
                      {`${moment(day.substring(0, 10)).format('dddd')}, ${moment(day.substring(0, 10)).format('MMMM')} ${moment(day.substring(0, 10)).format('Do')}`}
                    </Typography>
                  </Paper>
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper className={classes.paper}>
                    <Typography variant='h5'>
                      {`${moment(day.substring(0, 10)).format('dddd')}, ${moment(day.substring(0, 10)).format('MMMM')} ${moment(day.substring(0, 10)).format('Do')}`}
                    </Typography>
                  </Paper>
                </Grid>
              );
            }
          })}
        </Grid>
        <Typography variant='h6' color='textSecondary' align='center' style={{padding: '16px 0px 0px 0px',}}>
          All times displayed are in your local time zone. ({moment.tz.guess()})
        </Typography>
        {this.state.abstracts.map((abstract, index) => {
          if (this.state.selectedDate === abstract.date) {
            let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title']
            let calendarAbstract = prepareObject(abstract, requiredFields, 'presentation')
          return (
            <AgendaRowItem
              key={index}
              abstract={abstract}
              calendarAbstract={calendarAbstract} // object needed to create calendar .ics
              roomImage={posterSessionTopicImages[abstract.room]}
              selectedDate={this.state.selectedDate}
              timeDifference={this.state.timeDifference}
            />
          );
        } else {
          return (null)
        }
        })}
      </React.Fragment>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: '#E13E2F' },
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
      debug: state.debug,
      event: state.event,
  }
}

export default connect( mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withAuthenticator(Agenda, false, [], null, MyTheme)));
