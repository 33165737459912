import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { withRouter } from "react-router-dom";
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import removeAuthenticatedUser from '../Store/Actions/removeUser';
import { registrationPage } from '../config';

const useStyles = theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    headings: {
      padding: theme.spacing(3),
    },
    inputControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class Account extends Component{
    constructor(props) {
        super(props);
        this.state = {
        };
      }

      signOut = () => {
        Auth.signOut({ global: true })
          .then((data) => {
            this.props.removeAuthenticatedUser()
            this.props.history.push('/')
          })
          .catch(err => console.log(err));
      }

    render(){
      const { classes } = this.props;
        return(
            <Grid container layout={"row"} spacing={2} >
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant='h4' align='center' gutterBottom>
                    You are not Registered for this Event
                    </Typography>
                    <Typography variant='body1' align='justify'>
                        {`Your sign up and login was successful, but you are not currently registered for this event. 
                        If you are an attendee, please head to ${registrationPage} to register for this event. 
                        If you are an exhibitor, please reach out to your internal team and register as a booth staff.
                        Reach out to support if you have any trouble; we're happy to help! Thank you for your patience!`}
                    </Typography>
                    <Button variant="contained" onClick={this.signOut} className={classes.button}>
                        Sign Out
                    </Button>
                  </CardContent>
                </Card>
                </Grid>
            </Grid>
        )
    }
}

const MyTheme = {
    button: { backgroundColor: "#6f1a1d"},
  };

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      removeAuthenticatedUser: removeAuthenticatedUser
    }, dispatch);
  }

export default connect(null, mapDispatchToProps)(withRouter(withStyles(useStyles)(withAuthenticator(Account, false, [], null, MyTheme))));