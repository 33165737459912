import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
  },
});

class SimpleDialog extends Component {

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        aria-labelledby='simple-dialog-title'
        fullWidth={true}
        maxWidth='lg'
        open={this.props.open}
        onClose={(event) => this.props.handleClose(event)}
      >
        <div className={classes.paper}>{this.props.header}{this.props.children}{this.props.footer}</div>
      </Dialog>
    );
  }
}

export default withStyles(useStyles)(SimpleDialog);
