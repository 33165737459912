import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Badge from '@material-ui/core/Badge';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../../Store/Actions/getChatMessages';
import { eventId } from '../../config';

const useStyles = theme => ({
 
});

class PrivateChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            member: '',
        };
    }

    async componentDidMount(){

        await this.getNewUser()
    }
    

    async getNewUser(){
        const currentUser = await Auth.currentSession()

        const chatMember = await axios({
            method: 'get',
            url: `/show-attendees/${eventId}/${this.props.chat.activePrivateMember}`,
            headers: { idtoken: currentUser.idToken.jwtToken },
        })

        await this.setState({
            member: chatMember.data[0]
        })
    }

        shouldComponentUpdate(nextProps, nextState){
        if(this.state.member){
            if(this.props.chat.activePrivateMember !== this.state.member.email){
                this.getNewUser()   
            }
            return true
        } else {
            return false
        }
    }



    render() {
        // const { classes } = this.props;

        if(!this.props.chat.activePrivateChat || !this.props.sidebar.chatOpen || !this.state.member ){
            return null
        }
        let avatar;

        if(this.state.member.roles === "Pharma Attendee"){
            if(this.state.member.photo_url){
                avatar = (
                    <ListItemIcon>
                        <Badge 
                        // badgeContent={"Merck"} 
                        color="primary"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                        <Avatar alt="private chat avatar" src={this.state.member.photo_url}>
                        </Avatar>
                        </Badge>
                    </ListItemIcon>
                )
            } else{
                avatar = (
                    <ListItemIcon>
                        <Badge 
                        // badgeContent={"Merck"} 
                        color="primary"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                        <Avatar>
                        {this.state.member.first_name.charAt(0) + this.state.member.last_name.charAt(0)}
                         </Avatar>
                         </Badge>
                    </ListItemIcon>
                )
            }
        } else{
            if(this.state.member.photo_url){
                avatar = (
                    <ListItemIcon>
                        <Avatar alt="private chat avatar" src={this.state.member.photo_url}>
                        </Avatar>
                    </ListItemIcon>
                )
            } else{
                avatar = (
                    <ListItemIcon>
                    <Avatar>
                    {this.state.member.first_name.charAt(0) + this.state.member.last_name.charAt(0)}
                     </Avatar>
                </ListItemIcon>
                )
            }
        }
  
        return (
            <ListItem key={this.state.member.first_name} >
                {avatar}
                <Typography variant="h6">
                {this.state.member.first_name + " " + this.state.member.last_name}
            </Typography>
            </ListItem>
        );
    }
}

const MyTheme = {
    button: { backgroundColor: '#6f1a1d' },
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getChatMessages: getChatMessages,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
        chat: state.chat,
        sidebar: state.sidebar,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(withAuthenticator(PrivateChat, false, [], null, MyTheme))));
