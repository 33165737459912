import React, { Component } from 'react';
import axios from 'axios';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import NavBar from '../../Components/Navbar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckIcon from '@material-ui/icons/Check';
import { Formik, Form, Field, useField, FieldArray } from 'formik';
import Divider from '@material-ui/core/Divider';
import { NativeSelect } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { eventId } from '../../config';

import { Img } from 'react-image';
import { FileUploader } from '../../Utilities/fileUploader' //LIKELY WILL NEED FOR VERSION 2
// import PersonIcon from '@material-ui/icons/Person'; //LIKELY WILL NEED FOR VERSION 2

import { connect } from 'react-redux';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        color: "white", 
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
          },
    },
    toggleButton: {
        '& .Mui-selected' : {
            backgroundColor: theme.palette.primary.light
        },
        '& .Mui-selected:hover' : {
            backgroundColor: theme.palette.primary.light
        },
    },
    acceptButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: '40%',
        marginLeft: '5%',
        marginRight: '5%',
        fontSize: '1rem',
    },
    closeButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        width: '40%',
        marginLeft: '5%',
        marginRight: '5%',
        fontSize: '1rem',
    },
    gridContainer: {
        textAlign: 'center',
        justifyContent: 'center',
    },
    navButton: {
        margin: theme.spacing(1),
    },
    submitButton: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        // color: ,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            // borderColor: '#0062cc',
            // boxShadow: 'none',
          },
    },
    infoField: {
        '&.MuiTextField-root': {
            margin: 0,
            marginTop: theme.spacing(0.75),
            marginBottom: theme.spacing(0.75),
        },
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
    },
    selectField: {
        '&.MuiInputBase-fullWidth': {
            margin: 0,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            '&.MuiNativeSelect-select': {
                padding: theme.spacing(5),
            },
            '&.MuiSelect-select': {
                padding: theme.spacing(5),
            }
        }
    },
    divider: {
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
    },
    toggleText: {
        marginLeft: '16px', 
        alignSelf: 'center', 
    },
    error: {
        color: 'red',
        fontFamily: 'Roboto',
    },
    tabNoCursor: {
        cursor: 'default',
    },
});

class AbstractSubmission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            existingRegistrant: null,
            emailNotRegistered: false,
            typeAsObject: {talk: false, poster: false},
            topicMap: ['Sample1', 'Sample2'],
            file: null,
            abstractUrl: '',
            submitterIsAuthor: false,
            presenterUnknown: false,
            submitterIsPresenter: false,  
            authorCount: 0,
            presenterCount: 0,   
        }
    }

    handleTabChange = (event, newValue) => {
        this.setState({ tabValue: newValue });
    };

    handleChange(value, event) {
        this.setState({value: event});
    }

    handleCheckboxChange = (event) => {
        console.log(event.target.name);
        this.setState({typeAsObject: { ...this.state.typeAsObject, [event.target.name]: event.target.checked }});
      };

    handleFileUpload = (file, meta, status) => {
        console.log(file, meta, status)
        this.setState({
            file: file,
            abstractUrl: meta.previewUrl
        })
    }

    handleReset = (values) => {

        this.handleTabChange(null, 0)
        this.setState({
            submitterIsAuthor: false,
            presenterUnknown: false,
            submitterIsPresenter: false,
            file: null,
            abstractUrl: '',
            typeAsObject: {talk: false, poster: false}
        })
    }

    populateSubmitterAsAuthor = (setFieldValue) => {
        this.setState({submitterIsAuthor: !this.state.submitterIsAuthor})
        if (!this.state.submitterIsAuthor) {
            setFieldValue('authors[0].authorFirstName', this.state.existingRegistrant.first_name);
            setFieldValue('authors[0].authorLastName', this.state.existingRegistrant.last_name);
            setFieldValue('authors[0].authorEmail', this.state.existingRegistrant.email);
            setFieldValue('authors[0].authorJobTitle', this.state.existingRegistrant.title);
            setFieldValue('authors[0].authorCompany', this.state.existingRegistrant.account_name);
        } else {
            setFieldValue('authors[0].authorFirstName', '');
            setFieldValue('authors[0].authorLastName', '');
            setFieldValue('authors[0].authorEmail', '');
            setFieldValue('authors[0].authorJobTitle', '');
            setFieldValue('authors[0].authorCompany', '');
        }
    }
    
    populateSubmitterAsPresenter = (setFieldValue, setFieldTouched) => {
        // this.setState({submitterIsPresenter: !this.state.submitterIsPresenter})
        if (!this.state.submitterIsPresenter) {
            setFieldValue('presenters[0].presenterFirstName', this.state.existingRegistrant.first_name);
            setFieldValue('presenters[0].presenterLastName', this.state.existingRegistrant.last_name);
            setFieldValue('presenters[0].presenterEmail', this.state.existingRegistrant.email);
            setFieldValue('presenters[0].presenterJobTitle', this.state.existingRegistrant.title);
            setFieldValue('presenters[0].presenterCompany', this.state.existingRegistrant.account_name);
        } else {
            setFieldValue('presenters[0].presenterFirstName', '');
            setFieldValue('presenters[0].presenterLastName', '');
            setFieldValue('presenters[0].presenterEmail', '');
            setFieldValue('presenters[0].presenterJobTitle', '');
            setFieldValue('presenters[0].presenterCompany', '');
        }
        setFieldTouched('presenters[0].presenterFirstName', false);
        setFieldTouched('presenters[0].presenterLastName', false);
        setFieldTouched('presenters[0].presenterEmail', false);
        setFieldTouched('presenters[0].presenterJobTitle', false);
        setFieldTouched('presenters[0].presenterCompany', false);
        this.setState({submitterIsPresenter: !this.state.submitterIsPresenter})
    }

    handlePresenterUnknown = (setFieldValue, setFieldTouched) => {
        if (this.state.submitterIsPresenter && !this.state.presenterUnknown) {
            setFieldValue('presenters[0].presenterFirstName', '');
            setFieldValue('presenters[0].presenterLastName', '');
            setFieldValue('presenters[0].presenterEmail', '');
            setFieldValue('presenters[0].presenterJobTitle', '');
            setFieldValue('presenters[0].presenterCompany', '');
        }
        if (this.state.submitterIsPresenter && this.state.presenterUnknown) {
            setFieldValue('presenters[0].presenterFirstName', this.state.existingRegistrant.first_name);
            setFieldValue('presenters[0].presenterLastName', this.state.existingRegistrant.last_name);
            setFieldValue('presenters[0].presenterEmail', this.state.existingRegistrant.email);
            setFieldValue('presenters[0].presenterJobTitle', this.state.existingRegistrant.title);
            setFieldValue('presenters[0].presenterCompany', this.state.existingRegistrant.account_name);
        }
        this.setState({presenterUnknown: !this.state.presenterUnknown})
    }

    render() {
        const { classes } = this.props;

        let tabRow;
        if (this.state.tabValue > 3) {
            tabRow = null;
        } else {tabRow = (
            <Tabs
                value={this.state.tabValue}
                indicatorColor='primary'
                textColor='primary'
                centered
            >
                <Tab disableRipple className={classes.tabNoCursor} label={'1 Email'} />
                <Tab disableRipple className={classes.tabNoCursor} label={'2 Abstract Info'} />
                <Tab disableRipple className={classes.tabNoCursor} label={'3 Author Info'} />
                <Tab disableRipple className={classes.tabNoCursor} label={'4 Presenter Info'} />
            </Tabs>
        )}

        const InfoField = ({ ...props }) => {
            // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
            // which we can spread on <Field> and alse replace ErrorMessage entirely.
            const [field, meta] = useField(props);
            if (!field.value) {
                field.value = '';
            }

            return (
                <>
                    <Field
                        onChange={() => this.handleChange(field.name, field.value)}
                        className={classes.infoField}
                        component={TextField}
                        margin='dense'
                        variant='outlined'
                        fullWidth
                        {...field} {...props}
                    />
                    {meta.touched && meta.error ? (
                        <div className={classes.error}>{meta.error}</div>
                    ) : null}
                </>
            );
        };

        const SelectField = ({ ...props }) => {
            // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
            // which we can spread on <Field> and alse replace ErrorMessage entirely.
            const [field, meta] = useField(props);
            if (!field.value) {
                field.value = '';
            }
       
            let passedOptions;
            if (props.options) {
                passedOptions = props.options.map((item, index)=>{
                return <option value={item} key={index}>{item}</option>
                })
            }
            return (
                <>
                    <Field
                        onChange={() => this.handleChange(field.name, field.value)}
                        className={classes.selectField}
                        component={NativeSelect}
                        margin='none'
                        variant='standard'
                        fullWidth
                        {...field} {...props}
                    >
                        <option aria-label="None" value="" >Select {props.placeholder}</option>
                        {passedOptions}
                    </Field>
                    {meta.touched && meta.error ? (
                        <div className={classes.error}>{meta.error}</div>
                    ) : null}
                </>
            );
        };

        const CheckboxField = ({ ...props }) => {
            // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
            // which we can spread on <Field> and alse replace ErrorMessage entirely.
            const [field, meta] = useField(props);
            if (!field.value) {
                field.value = '';
            }

            return (
                <div className={classes.root}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Select {props.placeholder}</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.typeAsObject.talk} onChange={this.handleCheckboxChange} name="talk" />}
                                label="Talk"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.typeAsObject.poster} onChange={this.handleCheckboxChange} name="poster" />}
                                label="Poster"
                            />
                        </FormGroup>
                        {meta.touched && meta.error ? (
                            <div className={classes.error}>{meta.error}</div>
                        ) : null}
                    </FormControl>
                    </div>
            )
        };

        const CompanyTagField = ({ ...props }) => {
            const [field, meta, helpers] = useField(props); //eslint-disable-line
            const { setValue } = helpers;
        
            let tags = [];
            tags = props.values.tags;
        
            const handleOnKeyDown = (e) => {
                if (e.keyCode === 13) {
                    props.values.tags = [...tags, e.target.value];
                    setValue('')
                }
            }
        
            return (
                <Box 
                    component="ul" 
                    style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: '2px',
                    margin: 0,
                    }}
                >
                    <Field
                        component={TextField}
                        className={classes.infoField}
                        // type="text"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        onKeyDown={handleOnKeyDown}
                        {...field} {...props}
                    />
                </Box>
            );
        }
        
        const CompanyChips = ({ ...props }) => {
            // const classes = useStyles();
            const [field, meta, helpers] = useField(props); //eslint-disable-line
            const { setValue } = helpers;
        
            let tags = props.values.tags;
        
            const handleDelete = (e) => {
        
                tags = tags.filter((tag) => {
                    return tag !== e
                })
                props.values.tags = tags;
                setValue(tags)
        
            };
        
            let tagsMapped 
            if (tags) {
                tagsMapped = tags.map((data, index) => {
                    return (
                        <li key={index}>
                            <Chip
                                label={data}
                                style={{margin: '2px'}}
                                color="primary"
                                onDelete={() => handleDelete(data)}        
                            />
                        </li>
                    );
                }
                )
            } else {
                tagsMapped = null;
            }
        
            return (
                <Box component="ul" style={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                padding: '2px',
                margin: 0,
                }}>
                    {tagsMapped}
                </Box>)
        }

        let authorObject = () => { 
            return {
            authorFirstName: '',
            authorLastName: '',
            authorEmail: '',
            authorJobTitle: '',
            authorCompany: '',
        }}

        let authorGroup = [];
        for (let i=0; i <= this.state.authorCount; i++) {
            authorGroup.push(authorObject())
        }

        let presenterObject = () => {
            return {
            presenterFirstName: '',
            presenterLastName: '',
            presenterEmail: '',
            presenterJobTitle: '',
            presenterCompany: '',
            presenterBiography: '',            
        }}

        let presenterGroup = [];
        for (let i=0; i <= this.state.presenterCount; i++) {
            presenterGroup.push(presenterObject())
        }

        let baseValues = {
            email: '',
            type: [],
            topic: '',
            title: '',
            abstractContent: '',
            currentTag: '',
            tags: [],
            abstractFile: '', 
            authors: [...authorGroup],
            presenters: [...presenterGroup],
        }

        let checkEmail = async (values) => {
            await axios({
                method: 'GET',
                url: `/show-attendees/${eventId}/${values.email}`,
                }).then((responseRegistrant) => {
                    console.log(responseRegistrant.data)
                    let existingRegistrant;
                    if (responseRegistrant.data[0]) {
                        existingRegistrant = responseRegistrant.data[0]
                        this.setState({existingRegistrant: existingRegistrant, emailNotRegistered: false})
                        this.handleTabChange(null, 1);
                    } else {
                        existingRegistrant = null;
                        this.setState({emailNotRegistered: true})
                        setTimeout(() => {
                            this.setState({emailNotRegistered: false})
                            }, 4000);
                    }                      
                })
            if (this.state.existingRegistrant) {

                await axios({
                    method: 'get',
                    url: 'events/' + this.state.existingRegistrant.show_id,
                    // headers: { idtoken: data.idToken.jwtToken },
                    params: { eventId: this.state.existingRegistrant.show_id }
                })
                    .then((response) => {
                        // set up variables to assign to state
                        let config = response.data;
                        let topicMap = JSON.parse(config.topic_map)
                        const topicMapArray = Object.keys(topicMap);
                        this.setState({
                            topicMap: topicMapArray,
                        })
                    })
            }
            
        }

        let formikSubmit = async (values, setSubmitting) => {
            setSubmitting(true);
            // const user = await Auth.currentSession()
            
            this.handleTabChange(null, 4);

            values.tags = values.tags.join(',')
            let typeAsArray = [];
            for (let type in this.state.typeAsObject) {
                if (this.state.typeAsObject[type]) {
                    typeAsArray.push(type)
                }
            }
            values.type = typeAsArray

            if(this.state.file){

                let formData = new FormData();
                formData.append('file', this.state.file);
        
                const uploadFile = await axios({
                    method: "POST",
                    url: '/upload',
                    headers: { 
                        'Content-Type': 'multipart/form-data',
                    },
                    data: formData
                });
                
                values.abstractFile = uploadFile.data.Location;

            }

            // if (false) {       
            if (this.state.tabValue > 3) {   // UNCOMMENT OUT WHEN READY TO ACTUALLY POST  
                if (this.state.presenterUnknown) {
                    values.presenters = [presenterObject()];
                } 

                console.log(this.state.existingRegistrant.contact_id)
                setTimeout(() => {
                    axios({
                        method: 'POST',
                        url: `zoho/abstracts/${this.state.existingRegistrant.show_id}/${this.state.existingRegistrant.account_id}`,
                        // headers: { idtoken: user.idToken.jwtToken },
                        data: {
                            values,
                            contactId: this.state.existingRegistrant.contact_id
                        },
                    });
                  }, 500);
                    
                

            }   
            setSubmitting(false);
        }

        function onKeyDown(keyEvent) {
            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
              keyEvent.preventDefault();
            }
          }

        let validateEmail = (value) => {
            let error;
            if (!value) {
              error = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
              error = 'Invalid email address';
            }
            return error;
          }

        let validateEmailOptional = (value) => {
            let error;
            if (!value) {
              error = null;
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
              error = 'Invalid email address';
            }
            return error;
          }

        let validateRequired = (value) => {
            let error;
            if (!value) {
              error = 'Required';
            } 
            return error;
          }

        let authorField = (key, validateEmailOptional) => (
            <Grid item container xs={12} key={key} justify='center'>
                <Grid item xs={10}>
                    {/* <Divider className={classes.divider}/> */}
                        <Typography  variant='subtitle1' >
                            Author ({key+1})
                        </Typography>
                    <Divider className={classes.divider}/>
                </Grid>
                {key>0 ? 
                <Grid item xs={2}>
                    <Button 
                        style={{backgroundColor: 'red', width: '80%', margin: '0% 20%'}}
                        onClick={()=>{
                            // console.log('AUTHOR REMOVED')
                            this.setState({authorCount: this.state.authorCount-1});
                        }}
                        >
                        X
                    </Button>
                </Grid>
                : null}
                
                <Grid item xs={6} style={{paddingRight: '8px'}}>
                    <InfoField
                        name={'authors[' + key + '].authorFirstName'}
                        id={'authors[' + key + '].authorFirstName'}
                        label='First Name'
                        type='text'
                        placeholder='First Name'
                        />
                </Grid>
                <Grid item xs={6} style={{paddingLeft: '8px', }}>
                    <InfoField
                        name={'authors[' + key + '].authorLastName'}
                        id={'authors[' + key + '].authorLastName'}
                        label='Last Name'
                        type='text'
                        placeholder='Last Name'
                        />
                </Grid>
                <InfoField
                    name={'authors[' + key + '].authorEmail'}
                    id={'authors[' + key + '].authorEmail'}
                    label='Email'
                    type='text'
                    placeholder="Author's Email"
                    validate={validateEmailOptional}
                />
                <InfoField
                    name={'authors[' + key + '].authorCompany'}
                    id={'authors[' + key + '].authorCompany'}
                    label='Company'
                    type='text'
                    placeholder="Author's Company"
                />
                <InfoField
                    name={'authors[' + key + '].authorJobTitle'}
                    id={'authors[' + key + '].authorJobTitle'}
                    label='Title'
                    type='text'
                    placeholder="Author's Job Title"
                />
                <Grid item xs={12}><Divider className={classes.divider}/></Grid>
            </Grid>
        )

        let presenterField = (key) => (
            <Grid item container xs={12} key={key}>
                <Grid item xs={6} style={{paddingRight: '8px'}}>
                    <InfoField
                        name={'presenters[' + key + '].presenterFirstName'}
                        id={'presenters[' + key + '].presenterFirstName'}
                        label='First Name'
                        type='text'
                        placeholder='First Name'
                        validate={validateRequired} required
                        />
                </Grid>
                <Grid item xs={6} style={{paddingLeft: '8px', }}>
                    <InfoField
                        name={'presenters[' + key + '].presenterLastName'}
                        id={'presenters[' + key + '].presenterLastName'}
                        label='Last Name'
                        type='text'
                        placeholder='Last Name'
                        validate={validateRequired} required
                        />
                </Grid>
                <InfoField
                    name={'presenters[' + key + '].presenterEmail'}
                    id={'presenters[' + key + '].presenterEmail'}
                    label='Email'
                    type='text'
                    placeholder="Presenter's Email"
                    validate={validateEmail} required
                />
                <InfoField
                    name={'presenters[' + key + '].presenterCompany'}
                    id={'presenters[' + key + '].presenterCompany'}
                    label='Company'
                    type='text'
                    placeholder="Presenter's Company"
                    validate={validateRequired} required
                />
                <InfoField
                    name={'presenters[' + key + '].presenterJobTitle'}
                    id={'presenters[' + key + '].presenterJobTitle'}
                    label='Title'
                    type='text'
                    placeholder="Presenter's Job Title"
                    validate={validateRequired} required
                />
                {/* <InfoField
                    name={'presenters[' + key + '].presenterPhone'}
                    id={'presenters[' + key + '].presenterPhone'}
                    label='Phone'
                    type='number'
                    placeholder="Presenter's Phone"
                    validate={this.state.presenterUnknown ? null : validateRequired}
                /> */}
                <InfoField
                    name={'presenters[' + key + '].presenterBiography'}
                    id={'presenters[' + key + '].presenterBiography'}
                    label='Biography'
                    type='text'
                    placeholder="Presenter's Biography"
                    rows={5}
                    rowsMax={Infinity}
                    multiline
                />
                <Typography align='left' variant='body2' >
                    Optionally provide a short Biography of the Presenter. The maximum word count is 150 words. Please do not include any Markup. 
                </Typography>
                {/* <Typography align='left' variant='h6' >Presenter Photo</Typography>
                <Grid item container xs={12} justify='flex-start' align='center' >
                    <Grid item xs={3} >
                        <PersonIcon className={classes.img} style={{width: '80%', height: '100%', background: '#CCCCCC', marginRight: '20%',}}/>
                    </Grid>
                    <Grid item xs={9} style={{width: '100%', height: '100%',}} >
                        <FileUploader 
                            handleFileUpload = {this.handleFileUpload.bind(this)}
                            />
                    </Grid>
                </Grid> */}
                {/* the above is removed for MVP and pending to be used for VERSION 2 */}
                <Grid item xs={12}><Divider className={classes.divider}/></Grid>
            </Grid>
        )
        
        // let allAuthors = [];
        // for (let i=0; i <= this.state.authorCount; i++) {
        //     let key = i;
        //     allAuthors.push(authorField(key))
        // }

        let allAuthors = (validateEmailOptional) => {
            let allAuthors = [];
            for (let i=0; i <= this.state.authorCount; i++) {
                let key = i;
                allAuthors.push(authorField(key, validateEmailOptional))
            }
            return allAuthors;
        }

        // let allPresenters = [];
        // for (let i=0; i <= this.state.authorCount; i++) {
        //     let key = i;
        //     allPresenters.push(presenterField(key))
        // }

        let allPresenters = (validateEmail, validateRequired) => {
            let allPresenters = [];
            for (let i=0; i <= this.state.presenterCount; i++) {
                let key = i;
                allPresenters.push(presenterField(key, validateEmail, validateRequired))
            }
            return allPresenters;
        }

        let bodyContent = (isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit) => {
            switch (this.state.tabValue) {
                case 0:
                    return (
                            <Grid className={classes.gridContainer} container >
                                <Grid item container xs={12} justify='center'>
                                    <Grid item xs={6}>
                                        <Divider className={classes.divider}/>
                                            <Typography  variant='h6' >
                                                Email
                                            </Typography>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                        <InfoField
                                            name='email'
                                            id='email'
                                            label='Email'
                                            type='text'
                                            placeholder='Your Email'
                                            validate={validateEmail} required
                                            autoFocus
                                        />
                                        {/* <Divider className={classes.divider}/> */}
                                        {this.state.emailNotRegistered ? 
                                        <Typography style={{color: 'red'}}>Email is not registered for this event.</Typography> : 
                                        <Typography style={{color: 'white'}}>-</Typography>
                                        }
                                </Grid>
                                <Grid item xs={12}><Divider className={classes.divider}/></Grid>
                                <Grid item xs={10} sm={12} >
                                    <Button
                                        type='button'
                                        className={classes.navButton}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        onClick={() => {
                                            if (!validateEmail(values.email)) {
                                                checkEmail(values)
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'>
                                            Next
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                    );        
                case 1: 
                    return (
                            <Grid className={classes.gridContainer} container >
                                <Grid item container xs={12} justify='center'>
                                    <Grid item xs={6}>
                                        <Divider className={classes.divider}/>
                                            <Typography  variant='h6' >
                                                Abstract Information
                                            </Typography>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={6}>
                                    {/* <SelectField
                                        name='type'
                                        id='type'
                                        label='Type'
                                        type='text'
                                        placeholder='Abstract Type'
                                        options={['Talk', 'Poster']}
                                        // value={[]}
                                        validate={validateRequired} required
                                    /> */}
                                    <CheckboxField
                                        name='type'
                                        id='type'
                                        label='Type'
                                        type='text'
                                        placeholder='Abstract Type'
                                        options={['Talk', 'Poster']}
                                        validate={validateRequired} required
                                    />
                                    <SelectField
                                        name='topic'
                                        id='topic'
                                        label='Topic'
                                        type='text'
                                        placeholder='Abstract Topic'
                                        variant='filled'
                                        style={{backgroundColor: '#dbdbdb', borderRadius: '4px 4px 0px 0px', paddingLeft: '8px',}}
                                        options={this.state.topicMap}
                                        validate={validateRequired} required
                                    />
                                    <InfoField
                                        name='title'
                                        id='title'
                                        label='Title'
                                        type='text'
                                        placeholder='Your Abstract Title'
                                        validate={validateRequired} required
                                    />
                                    <Typography align='left' variant='body2' >
                                        The maximum word count is 250 words. Content beyond that may be cut-off. Enter content with no markup.
                                        Separate paragraphs with two line-breaks (carriage returns).
                                    </Typography>
                                    <InfoField
                                        name='abstractContent'
                                        id='abstractContent'
                                        label='Content'
                                        type='text'
                                        placeholder='Your Abstract content'
                                        rows={5}
                                        rowsMax={Infinity}
                                        multiline
                                        validate={validateRequired} required
                                    />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography align='center' variant='body2' >
                                                Keyword Tags (Press Enter to add tags)
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FieldArray
                                                name='tagFields'
                                                render={() => {
                                                    return (
                                                        <>
                                                            <CompanyTagField
                                                                name='currentTag'
                                                                id='currentTag'
                                                                label='Input Your Abstract Tags'
                                                                values={values}
                                                                onFocus={this.tagFieldFocus}
                                                                onBlur={this.tagFieldBlur}
                                                            />
                                                            <CompanyChips
                                                                name='tags'
                                                                id='tags'
                                                                label='Tags'
                                                                values={values}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.header} align='center' style={{textAlign: 'center'}} variant='h6' >Upload Supporting Image</Typography>
                                        {this.state.abstractUrl ?
                                            // <div style={{display: 'block', height: 0, paddingBottom: "56.25%", position: 'relative', marginLeft: '25%', marginRight: '25%'}}>
                                                <Img
                                                src={this.state.abstractUrl}
                                                alt='abstract document'
                                                width="50%"
                                                // style={{position: 'absolute', maxHeight: '100%',}}
                                                />
                                            // </div>
                                            :
                                            <React.Fragment></React.Fragment>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileUploader 
                                        // handleFileUpload = {this.handleFileUpload.bind(this, setFieldValue)}
                                        handleFileUpload = {this.handleFileUpload.bind(this)}
                                        />
                                    </Grid>
                                    {/* the above is removed for MVP and pending to be used for VERSION 2 */}
                                </Grid>
                                <Grid item xs={12}><Divider className={classes.divider}/></Grid>
                                <Grid item xs={10} sm={12} >
                                    <Button
                                        type='button'
                                        className={classes.navButton}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        disabled={this.state.tabValue !== 1}
                                        onClick={()=>{
                                            this.handleTabChange(null, 0);
                                        }}
                                    >
                                        <Typography variant='h6'>
                                            Previous
                                        </Typography>
                                    </Button>
                                    <Button
                                        type='button'
                                        className={classes.navButton}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        disabled={this.state.tabValue !== 1}
                                        onClick={() => {
                                            // Check and validate all fields before proceeding
                                            let fields = ['topic', 'type', 'abstractContent', 'title']
                                            console.log('values', values)
                                            fields.forEach(element => {
                                                setFieldTouched(element, true)
                                            });
                                            if (values.topic !== '' && values.type !== '' && values.abstractContent !== '' && values.title !== '') {
                                                this.handleTabChange(null, 2);
                                            }
                                        }}
                                    >
                                        <Typography variant='h6'>
                                            Next
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                    );
                case 2: 
                    return (
                            <Grid className={classes.gridContainer} container >
                                <Grid item container xs={12} justify='center'>
                                    <Grid item xs={6}>
                                    <Divider className={classes.divider}/>
                                        <Grid item container xs={12} justify='flex-start' align='center' style={{marginTop: '16px', marginBottom: '16px',}}>
                                            <Grid item className={classes.toggleButton}>
                                                <ToggleButton 
                                                    value='check'
                                                    selected={this.state.submitterIsAuthor}
                                                    onChange={() => this.populateSubmitterAsAuthor(setFieldValue)}
                                                >
                                                    <CheckIcon />
                                                </ToggleButton>
                                            </Grid>
                                            <Grid item className={classes.toggleText}>
                                                <Typography align='left' variant='body1' >
                                                    <b>Are you the Author?</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.divider}/>
                                            <Typography  variant='h6' >
                                                Author Information
                                            </Typography>
                                            <Typography  variant='subtitle1' >
                                                Optional
                                            </Typography>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} justify='center'>
                                    <Grid item xs={6}>
                                        {allAuthors(validateEmailOptional)}
                                    </Grid>
                                </Grid>
                                    {/* <Grid item xs={12}>
                                        <Button
                                            // size='large'
                                            variant='text'
                                            onClick={()=>{
                                                console.log('ADD AUTHOR CLICKED')
                                                console.log(this.state.authorCount);
                                                this.setState({authorCount: this.state.authorCount+1});
                                                console.log(this.state.authorCount);
                                            }}
                                        >
                                            <Typography align='left' variant='h6' >
                                                + Add Additional Author
                                            </Typography>
                                        </Button>
                                    </Grid> */}
                                    {/* to be added back in VERSION 2 */}
                                    <Grid item xs={12}><Divider className={classes.divider}/></Grid>
                                <Grid item xs={10} sm={12} >
                                    <Button
                                        type="button"
                                        className={classes.navButton}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        disabled={this.state.tabValue !== 2}
                                        onClick={()=>{
                                            this.handleTabChange(null, 1);
                                        }}
                                    >
                                        <Typography variant='h6'>
                                            Previous
                                        </Typography>
                                    </Button>
                                    <Button
                                        type="button"
                                        className={classes.navButton}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        disabled={this.state.tabValue !== 2}
                                        onClick={() => {
                                            // Check and validate all fields before proceeding
                                            let fields = ['author[0].authorFirstName', 'author[0].authorLastName', 'author[0].authorEmail', 'author[0].authorCompany', 'author[0].authorJobTitle']
                                            fields.forEach(element => {
                                                setFieldTouched(element, true)
                                            });
                                            // if (values.topic !== '' && values.type !== '' && values.abstractContent !== '' && values.title !== '') {
                                                this.handleTabChange(null, 3);
                                            // }
                                        }}
                                    >
                                        <Typography variant='h6'>
                                            Next
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                    );
                case 3: 
                    return (
                            <Grid className={classes.gridContainer} container >
                                <Grid item container xs={12} justify='center'>
                                    <Grid item xs={6}>
                                    <Divider className={classes.divider}/>
                                        <Grid item container xs={12} justify='flex-start' align='center' style={{marginTop: '16px', marginBottom: '16px',}}>
                                            <Grid item className={classes.toggleButton}>
                                                <ToggleButton 
                                                    value='check'
                                                    selected={this.state.presenterUnknown}
                                                    onChange={() => this.handlePresenterUnknown(setFieldValue, setFieldTouched)}
                                                >
                                                    <CheckIcon />
                                                </ToggleButton>
                                            </Grid>
                                            <Grid item className={classes.toggleText}>
                                                <Typography align='left' variant='body1' >
                                                    <b>Presenter unknown?</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                            <Divider className={classes.divider}/>
                                        <Grid item container xs={12} justify='flex-start' align='center' style={{marginTop: '16px', marginBottom: '16px',}}>
                                            <Grid item className={classes.toggleButton}>
                                                <ToggleButton 
                                                    disabled={this.state.presenterUnknown ? true : false}
                                                    value='check'
                                                    selected={this.state.submitterIsPresenter}
                                                    onChange={() => this.populateSubmitterAsPresenter(setFieldValue, setFieldTouched)}
                                                >
                                                    <CheckIcon />
                                                </ToggleButton>
                                            </Grid>
                                            <Grid item className={classes.toggleText} >
                                                <Typography align='left' variant='body1' >
                                                    <b>Are you the Presenter?</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {this.state.presenterUnknown ? null : <>
                                        <Divider className={classes.divider}/>
                                            <Typography  variant='h6' >
                                                Presenter Information
                                            </Typography>
                                        <Divider className={classes.divider}/>
                                        </>
                                        }
                                    </Grid>
                                </Grid>
                                {this.state.presenterUnknown ? null : 
                                    <Grid item container xs={12} justify='center'>
                                        <Grid item xs={6}>
                                            {/* {allPresenters} */}
                                            {allPresenters(validateEmail, validateRequired)}
                                        </Grid>
                                    </Grid>
                                }           
                                {/* {this.state.presenterUnknown ? null : 
                                    <Grid item xs={12}>
                                            <Button
                                                // size='large'
                                                variant='text'
                                                onClick={()=>{
                                                    console.log('ADD PRESENTER CLICKED')
                                                    console.log(this.state.presenterCount);
                                                    this.setState({presenterCount: this.state.presenterCount+1});
                                                    console.log(this.state.presenterCount);
                                                }}
                                            >
                                                <Typography align='left' variant='h6' >
                                                    + Add Additional Presenter
                                                </Typography>
                                            </Button>
                                    </Grid>
                                } */}
                                {/* to be added back in VERSION 2 */}
                                <Grid item xs={12}><Divider className={classes.divider}/></Grid>
                                <Grid item xs={10} sm={12} >
                                    <Button
                                        type='button'
                                        className={classes.navButton}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        onClick={()=>{
                                            this.handleTabChange(null, 2);
                                        }}
                                    >
                                        <Typography variant='h6'>
                                            Previous
                                        </Typography>
                                    </Button>
                                    <Button
                                        type='button'
                                        className={classes.submitButton}
                                        // className={classes.navButton}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        onClick={handleSubmit}
                                    >
                                        <Typography variant='h6'>
                                            Submit
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                    );
                case 4: 
                    return (
                        <Grid container>
                            <Grid item xs={12} style={{textAlign: 'center', padding: '60px',}}>
                                <Typography variant='h4' justify='center' align='center' style={{marginBottom: '60px',}}> Thank you for submitting this Abstract</Typography>
                                <Button 
                                    type='text' 
                                    size='large' 
                                    color='primary' 
                                    variant='contained' 
                                    onClick={() => this.handleReset(values)}
                                    style={{fontSize: '1.3rem', padding: '16px',}}
                                >
                                    Submit Another
                                </Button>
                            </Grid>
                        </Grid>
                )
                default:
                    return null;
            }
        };
        
        let mainSection = () => {
            return (<Formik
                initialValues={baseValues}
                onSubmit={
                    (values, { setSubmitting, resetForm }, setFieldTouched) => {                        
                        formikSubmit(values, setSubmitting);
                        resetForm(baseValues);
                    }
                }
            >
                {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit }) => (
                    <Form onKeyDown={onKeyDown}>
                        {bodyContent(isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit)}
                    </Form>
                )}
            </Formik>)
        }
        return (
            <div className={classes.root}>
                <NavBar />
                <Grid container layout={"row"} spacing={0} style={{ marginTop: "80px" }} justify='center'>
                    <Grid item xs={12}>
                        <Typography variant='h5' align='center'>Abstract Submission</Typography>
                    </Grid>
                    <Grid container item xs={12} justify='center'>
                        {this.state.tabValue <= 3 ? tabRow : null}
                    </Grid>
                    <Grid item xs={12}>
                    {mainSection()}
                    </Grid>
                </Grid>
            </div>
        )
        
    }
}

function mapStateToProps(state) {
    return {
      user: state.user,
      event: state.event,
    }
  }

export default connect(mapStateToProps)(withStyles(useStyles)(AbstractSubmission));