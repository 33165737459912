
import { SET_DEBUG }  from '../Actions/types';

const initialState = {
    debug: false,
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_DEBUG:
            return {
                ...state,
                debug: action.payload
            };
        default:
            return state;
    }
}

export default reducer;