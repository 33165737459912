import { ADD_BRIEFCASE_ELEMENT } from './types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../../config';

export default function addBriefcaseElement(resourceType, resourceId, resourceTitle, resourceOwner, accountId) {
  return (dispatch, getState) => {
  
    const items = getState().debug;
    
      Auth.currentSession()
        .then(data => {
          axios({
            method: 'post',
            url: `/briefcase/${eventId}`,
            headers: { idtoken: data.idToken.jwtToken },
            data: {
                resource_type: resourceType,
                resource_id: resourceId,
                resource_title: resourceTitle,
                resource_owner: resourceOwner,
            }
          })
            .then((response) => {
              dispatch(addBriefcaseElementAsync(response.data[0]))
            })
            .catch((error) => {
              if (items.debug) {
                alert(`Error: Could not add to the briefcase. ${error}`)
              }
            });
        })
        .catch((error) => {
          if (items.debug) {
            alert(`Error: Could not update the briefcase. ${error}`)
          }
        });
    }
}

function addBriefcaseElementAsync(briefcaseElement){
  return {
    type: ADD_BRIEFCASE_ELEMENT,
    payload: briefcaseElement
  };
}