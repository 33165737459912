import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { fallbackImages } from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    maxHeight: '100%', 
    maxWidth: '100%',
    cursor: 'pointer',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    margin: '0% 0%',
  },
  title: {
    lineHeight: '120%',
  },
}));

export default function PosterCard(props) {
  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      onClick={(event) => props.toggleFullscreenHandlerOn(event, props.roomName)}
      raised={isHovering}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <CardMedia 
        className={classes.media} 
        image={
          props.roomPicture
            ? props.roomPicture
            : fallbackImages.agendaCard
        }
        />
      <CardContent>
        <Typography
          className={classes.title}
          variant='h6'
          color='textPrimary'
          align='center'
        >
        {props.roomName}
        </Typography>
      </CardContent>
    </Card>
  );
}