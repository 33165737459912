import React, { Component } from 'react';
import axios from 'axios';
import { withAuthenticator } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import PosterCard from './ActivitiesCard';
import PosterListItem from './ActivitiesListItem';
import PosterFull from './ActivitiesFull';
import Filter from './ActivitiesFilter';
import Dialog from '../../Components/DialogWrapper';
import { withRouter } from 'react-router';
import { eventId } from '../../config';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  filter: {
    margin: '0% 0% 1% 0%',
    '& #filterInput': {
      backgroundColor: 'white',
      borderRadius: '5%',
      boxShadow: '0px 1px 1px 0px rgba(120,120,120,0.75)',
      '&:hover': {},
    },
  },
  gridContainer: {
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class PosterSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posters: [],
      posterSelected: false,
      posterIndex: null,
      posterId: null,
      listFormat: false,
      filterString: '',
      filterCategory: 'presenter_first_name',
      filtering: false,
      pageCountStart: 1,
      perPage: 12,
      totalPages: 1,
      page: 1,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'poster_id') {
          this.setState({
            posterSelected: true,
            posterId: param[1],
          });
        }
      }
    }

    this.getPosters();
    this.props.setBriefcase();
  }

  getPosters() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/activities/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            search_columns: this.state.filterCategory,
            search_value: this.state.filterString,
          },
        })
          .then((response) => {

            let authorizedPosters = response.data

            this.setState({
              posters: authorizedPosters,
              topicMap: JSON.parse(this.props.event.event.topic_map),
              totalPages: Math.ceil(
                response.data.length / this.state.perPage
              ),
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Activities Hall. ${error}`)
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Activities Hall. ${error}`)
        }
      });
  }

  togglePosterFullscreenOn = (event, id) => {
    this.setState({
      posterSelected: true,
      posterId: id,
    });
  };

  togglePosterFullscreenOff = () => {
    this.setState({
      posterSelected: false,
      posterId: null,
    });
  };

  handleGridListToggle = () => {
    this.setState({ listFormat: !this.state.listFormat });
  };

  handleFilterClick = (string, category) => {
    this.setState({
      filterString: string,
      filterCategory: category,
      filtering: true,
      pageCountStart: 1,
      page: 1,
    });
    this.getPosters();
  };

  handleRemoveFilter = () => {
    this.setState({
      filterString: '',
      filterCategory: '',
      filtering: false,
      pageCountStart: 1,
      page: 1,
    });
    this.getPosters();
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
      pageCountStart: value * this.state.perPage - (this.state.perPage - 1),
    });

    this.getPosters();
  };

  shouldComponentUpdate(nextProps, nextState){

    if (this.props.timestamp === nextProps.timestamp){
      return true
    } else {
      this.setState({
        posterSelected: false,
        posterIndex: null,
        posterId: null,
      })
      return false
    }
  }

  handleClose() {
    this.setState({
      posterSelected: false,
      posterIndex: null,
      posterId: null,
    });
  }

  handleUpvote = (index) => {
    let items = [...this.state.posters]
    let item = {...items[index]};
    if (item.liked === true) {
      item.count = item.count-1;
      item.liked = false;
    } else {
      item.count = item.count+1;
      item.liked = true;
    }
    items[index] = item;
    this.setState({posters: items});
  }

  handleUpvotePosterFull = (posterId) => {
    let items = [...this.state.posters]
    items.forEach(item => {
      if (item.abstract_id === posterId) {
        if (item.liked === true) {
          item.count = item.count-1;
          item.liked = false;
        } else {
          item.count = item.count+1;
          item.liked = true;
        }
      }
    });
    this.setState({posters: items});
  }

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(this.props.event.event.available_routes).routes
    let pageTitle = ''
    allRoutes.forEach(route => {
      if (route.route === '/activitieshall') {
        pageTitle = route.title
      }
    })

    let sidebarOpen;
    if (this.props.sidebar.chatOpen || this.props.sidebar.open) {
      sidebarOpen = true;
    } else {
      sidebarOpen = false;
    }

    let pageBody;

      if (this.state.listFormat) {
        pageBody = (
          <Grid
            container
            className={classes.gridContainer}
            layout={'row'}
            spacing={2}
          >
            <Grid item xs={12}>
              <Paper>
                <Typography style={{textAlign: 'center', padding: '16px 0px 8px 0px'}} variant='h4' color='textPrimary'>
                  {pageTitle}
                </Typography>
              </Paper>
            </Grid>
            <Filter
              handleGridListToggle={this.handleGridListToggle.bind(this)}
              handleRemoveFilter={this.handleRemoveFilter.bind(this)}
              handleFilterClick={this.handleFilterClick.bind(this)}
              listFormat={this.state.listFormat}
              filterString={this.state.filterString}
              filterCategory={this.state.filterCategory}
              filtering={this.state.filtering}
            />
            <Grid item xs={12}>
              {this.state.posters.map((poster, index) => {
                return (
                  <PosterListItem
                    key={index}
                    index={index}
                    poster={poster}
                    toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(this)}
                    handleUpvote={() => this.handleUpvote(index)}
                  />
                );
              })}
            <Dialog
              open={this.state.posterSelected}
              handleClose={this.handleClose.bind(this)}
              scroll='body'
            >
            <PosterFull
              posterId={this.state.posterId}
              closeBooth={this.togglePosterFullscreenOff.bind(this)}
              // handleUpvote={() => this.handleUpvote(index)}
            />
            </Dialog>
            </Grid>
          </Grid>
        );
      } else {
        pageBody = (
          <Grid
            container
            className={classes.gridContainer}
            layout={'row'}
            spacing={2}
          >
            <Grid item xs={12}>
              <Paper>
                <Typography style={{textAlign: 'center', padding: '16px 0px 8px 0px'}} variant='h4' color='textPrimary'>
                  {pageTitle}
                </Typography>
              </Paper>
            </Grid>
            <Filter
              handleGridListToggle={this.handleGridListToggle.bind(this)}
              handleRemoveFilter={this.handleRemoveFilter.bind(this)}
              handleFilterClick={this.handleFilterClick.bind(this)}
              listFormat={this.state.listFormat}
              filterString={this.state.filterString}
              filterCategory={this.state.filterCategory}
              filtering={this.state.filtering}
            />
            {this.state.posters.map((poster, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} 
                md={sidebarOpen ? 6 : 4} 
                lg={sidebarOpen ? 4 : 3}>
                  <PosterCard
                    topicMap = {this.state.topicMap}
                    poster={poster}
                    index={index}
                    toggleFullscreenHandlerOn={this.togglePosterFullscreenOn.bind(this)}
                    handleUpvote={() => this.handleUpvote(index)}
                  />
                </Grid>
              );
            })}
            <Dialog
              open={this.state.posterSelected}
              handleClose={this.handleClose.bind(this)}
              scroll='body'
            >
            <PosterFull
              posterId={this.state.posterId}
              closeBooth={this.togglePosterFullscreenOff.bind(this)}
              handleUpvote={() => this.handleUpvotePosterFull(this.state.posterId)}
            />
            </Dialog>
          </Grid>
        );
      }

    return pageBody
  }
}

const MyTheme = {
  button: { backgroundColor: '#6f1a1d' },
};

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withStyles(useStyles)(
      withAuthenticator(PosterSessions, false, [], null, MyTheme)
    )
  )
);
