import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import AgendaRowItem from './AgendaRowItem';
import { Typography, Grid, Paper } from '@material-ui/core';
import Dialog from '../../Components/DialogWrapper';
import AgendaFull from './AgendaFull';
import moment from 'moment';
import momentTimezone from 'moment-timezone'; //eslint-disable-line
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda'
import { fallbackImages, eventId } from '../../config';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {
  },
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  button: {
    color: 'white',
    backgroundColor: '#6f1a1d',
  },
  card: {
    maxWidth: 345,
  },
  day: {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  }
});

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
      days: [],
      rooms: [],
      selectedDate: '',
      ETtoUTCoffset: '',
      topicMap: null,
      currentAgendaItem: null,
      agendaSelected: false,
    };
  }
  componentDidMount() {
    this.props.setBriefcase();

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
          },
        })
          .then((response) => {

            let abstracts = [];
            let days = [];
            let rooms = [];

            for (let x = 0; x < response.data.data.length; x++) {
              let abstract = response.data.data[x];
              abstract.date = moment(abstract.start_time).format('YYYY-MM-DD');

              if (abstract.start_time && abstract.accepted_type !== 'Workshop') {

                  if(abstract.cleared_for_public_view === "Yes - Approved"){
                    abstracts.push(abstract);
                  } else {
                  if(this.props.user.user.roles.includes('Attendee') || this.props.user.user.roles.includes('Pharma Attendee')){
                    abstracts.push(abstract);
                  }
                }
              }

              if (abstract.date && !days.includes(abstract.date) && abstract.start_time) {
                days.push(abstract.date);
              }

              if (abstract.room && !rooms.includes(abstract.room) && abstract.start_time) {
                 if(abstract.room === "FEATURED"){
                  rooms.unshift(abstract.room);
                 } else{
                    rooms.push(abstract.room);
                 }
              }
            }
            
            abstracts.sort((a,b) => {
              return moment.duration(a.start_time).asMinutes() - moment.duration(b.start_time).asMinutes()
            });

            days.sort();

            const currentDayIndex = days.findIndex((day) => day.substring(0, 10) === moment().format("YYYY-MM-DD"))
            let currentDay;
            if (currentDayIndex > -1){
              currentDay = days[currentDayIndex]
            } else {
              currentDay = days[0]
            }

            this.setState({
              abstracts,
              days,
              rooms,
              selectedDate: currentDay,
              topicMap: JSON.parse(this.props.event.event.topic_map),
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Agenda. ${error}`)
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Agenda. ${error}`)
        }
      });
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  toggleAgendaFullscreenOn = (event, id) => {
    this.setState({
      agendaSelected: true,
      currentAgendaItem: id,
    });
  };

  toggleAgendaFullscreenOff = () => {
    this.setState({
      agendaSelected: false,
      currentAgendaItem: null,
    });
  };

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(this.props.event.event.available_routes).routes
    let pageTitle = ''
    allRoutes.forEach(route => {
      if (route.route === '/agenda') {
        pageTitle = route.title
      }
    })

    return (
      <React.Fragment>
        <Grid
          container
          layout={'row'}
          spacing={2}
          justify='center'
          className={classes.header}
        >
          <Grid item xs={12}>
              <Paper>
                <Typography style={{textAlign: 'center', padding: '16px 0px 8px 0px'}} variant='h4' color='textPrimary'>
                  {pageTitle}
                </Typography>
              </Paper>
            </Grid>
          {this.state.days.map((day, index) => {
            if (day === this.state.selectedDate) {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper
                    className={classes.paper + " " + classes.day}
                    variant='elevation'
                    elevation={10}
                  >
                    <Typography variant='h5'>
                      {moment(day).format('dddd, MMMM Do')}
                    </Typography>
                  </Paper>
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper className={classes.paper}>
                    <Typography variant='h5'>
                      {moment(day).format('dddd, MMMM Do')}
                    </Typography>
                  </Paper>
                </Grid>
              );
            }
          })}
        </Grid>
        <Typography variant='h6' color='textSecondary' align='center' style={{padding: '16px 0px 0px 0px',}}>
          All times displayed are in your local time zone. ({moment.tz.guess()})
        </Typography>
        {this.state.abstracts.map((abstract, index) => {
          if (this.state.selectedDate === moment(abstract.start_time).format("YYYY-MM-DD")) {
            let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title']
            let calendarAbstract = prepareObject(abstract, requiredFields, 'presentation')

            let agendaListImage;
            if (abstract.supporting_file_url) {
              agendaListImage=abstract.supporting_file_url
            } else if (this.state.topicMap[abstract.room]) {
              agendaListImage= this.state.topicMap[abstract.room]
            } else if (this.state.topicMap[abstract.topic]) {
              agendaListImage=this.state.topicMap[abstract.topic]
            } else {
              agendaListImage=fallbackImages.agendaCard
            }


          return (
            <AgendaRowItem
              key={index}
              abstract={abstract}
              topicMap={this.state.topicMap}
              calendarAbstract={calendarAbstract} // object needed to create calendar .ics
              roomImage={agendaListImage}
              selectedDate={this.state.selectedDate}
              toggleFullscreenHandlerOn={this.toggleAgendaFullscreenOn.bind(this)}

            />
          );
        } else {
          return (null)
        }
        })}
        <Dialog
          open={this.state.agendaSelected}
          handleClose={this.toggleAgendaFullscreenOff.bind(this)}
          scroll='body'
        >
          <AgendaFull
            agenda={this.state.currentAgendaItem}
            closeAgenda={this.toggleAgendaFullscreenOff.bind(this)}
            // handleUpvote={() => this.handleUpvotePosterFull(this.state.agendaId)}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: '#E13E2F' },
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
      debug: state.debug,
      event: state.event,
      user: state.user,
  }
}

export default connect( mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withAuthenticator(Agenda, false, [], null, MyTheme)));
