import { SET_DEBUG } from './types';

export default function setSidebar(debugState) {
  return dispatch => {
    dispatch(setSidebarAsync(debugState));
  }
}

function setSidebarAsync(debugState){
  return {
    type: SET_DEBUG,
    payload: debugState
  };
}