import React, { Component } from 'react';

// Configure Router
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Lobby from './Containers/Lobby';
import LandingPage from './Containers/LandingPage';
import Signup from './Containers/Authentication/Signup';
import Signin from './Containers/Authentication/Signin';
import ConfirmationCode from './Containers/Authentication/ConfirmationCode';
import ForgotPassword from './Containers/Authentication/ForgotPassword';
import ExhibitHall from './Containers/Exhibits/ExhibitionHall';
import ExhibitionBooth from './Containers/Exhibits/ExhibitBooth';
import DiscussionForum from './Containers/DiscussionForum';
import PosterSessions from './Containers/Posters/PosterSessions';
import Poster from './Containers/Posters/PosterFull';
import Art from './Containers/Art/Art';
import Help from './Containers/Help';
import Agenda from './Containers/Agenda/Agenda';
import MyPersonalAgenda from './Containers/Agenda/MyPersonalAgenda';
import Briefcase from './Containers/Briefcase/Briefcase';
import ConferenceCenter from './Containers/ConferenceCenter/ConferenceCenter';
import ChatRoom from './Containers/Chat/Chat';
import Unregistered from './Containers/Unregistered';
import Layout from './Components/MiniSidebar';
import Settings from './Containers/Settings';
import Leads from './Containers/Leads/Leads';
import Reporting from './Containers/Reporting';
import DigitalLab from './Containers/Exhibits/DigitalLab';
import WhatsNew from './Containers/WhatsNew';
import Debug from './Containers/Debug';
import Registration from './Containers/Authentication/Registration';
import UnderConstruction from './Containers/UnderConstruction';
import AbstractSubmission from './Containers/Authentication/AbstractSubmission';
import AccountSettings from './Containers/AttendeeAccount/AccountSettings';
import Workshop from './Containers/Workshop/Workshop';
import arrayComparison from './Utilities/arrayComparison';
// import WhereBy from './Containers/WherebyDemo';
import WhereByGuest from './Containers/Whereby/Guest';
import WhereByHost from './Containers/Whereby/Host';
import ActivitiesHall from './Containers/Activities/ActivitiesHall';

// Helmet
import { Helmet } from "react-helmet";
import { title, faviconLocation } from './config';

// Matierial Theme
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from './config';

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuthenticatedUser from './Store/Actions/setUser';
import setEventElements from './Store/Actions/setEventElements';

class Router extends Component {

    components = {
        Lobby,
        LandingPage,
        Signup,
        Signin,
        ConfirmationCode,
        ForgotPassword,
        ExhibitHall,
        DigitalLab,
        Debug,
        ExhibitionBooth,
        DiscussionForum,
        PosterSessions,
        Poster,
        Art,
        WhatsNew,
        Help,
        Agenda,
        Briefcase,
        MyPersonalAgenda,
        ConferenceCenter,
        ChatRoom,
        Unregistered,
        Layout,
        Settings,
        Leads,
        Reporting,
        // Privacy,
        AbstractSubmission,
        AccountSettings,
        Workshop,
        ActivitiesHall,
    };

    async componentDidMount (){
        await this.props.setEventElements()
    }

    render() {

        const theme = createMuiTheme({
            palette: {
              primary: {
                main: this.props.event.event.main_color ? this.props.event.event.main_color : colors.main, //"#311B4C",
                light: this.props.event.event.light_color ? this.props.event.event.light_color : colors.light, //'#a380cf'
              },
               secondary: {
                main: "#2b377c",
              },
            },
          });

        // Available Attendee Routes
        if (!this.props.user.user){


            return (
            <ThemeProvider theme={theme}>
            <BrowserRouter>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.props.event ? this.props.event.event.title : title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="description" content="Virtual Environment Powered by PlanetConnect" />
                <link rel="icon" type="image/png" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} sizes="16x16" />
                <link rel="apple-touch-icon" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} />
            </Helmet>
            <Switch>
                <Route
                    path='/signin'
                    render={(props) => <Signin />}
                />
                <Route
                    path='/forgotpassword'
                    render={(props) => <ForgotPassword />}
                />
                <Route
                    path='/confirmationcode'
                    render={(props) => <ConfirmationCode />}
                />
                <Route
                    path='/signup'
                    render={(props) => <Signup />}
                />
                <Route
                    exact
                    path='/registration'
                    render={(props) => <Registration />}
                />
                <Route
                    path='/abstractsubmission'
                    render={(props) => <AbstractSubmission />}
                />
                <Route
                    exact
                    path='/'
                    render={(props) => <LandingPage />}
                />
            </Switch>
        </BrowserRouter>
        </ThemeProvider>
            )
        } else if (this.props.user.user === 'unregistered'){


            return (
            <ThemeProvider theme={theme}>
            <BrowserRouter>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.props.event ? this.props.event.event.title : title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="description" content="Virtual Environment Powered by PlanetConnect" />
                <link rel="icon" type="image/png" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} sizes="16x16" />
                <link rel="apple-touch-icon" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} />
            </Helmet>
            <Switch>
                <Route
                    path='/signin'
                    render={(props) => <Unregistered />}
                />
                <Route
                    path='/forgotpassword'
                    render={(props) => <ForgotPassword />}
                />
                <Route
                    path='/confirmationcode'
                    render={(props) => <ConfirmationCode />}
                />
                <Route
                    path='/signup'
                    render={(props) => <Signup />}
                />
                <Route
                    exact
                    path='/registration'
                    render={(props) => <Registration />}
                />
                <Route
                    exact
                    path='/'
                    render={(props) => <LandingPage />}
                />
                <Route
                    path='/abstractsubmission'
                    render={(props) => <AbstractSubmission />}
                />
                <Route
                    path='/signin'
                    render={(props) => <Unregistered />}
                />
                <Route
                    path='/'
                    render={(props) => <Unregistered />}
                />
            </Switch>
        </BrowserRouter>
        </ThemeProvider>
            )
        } else if (this.props.user.user.roles && this.props.event.event.under_construction){


            return (
                <ThemeProvider theme={theme}>
                <BrowserRouter>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.event ? this.props.event.event.title : title}</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="description" content="Virtual Environment Powered by PlanetConnect" />
                    <link rel="icon" type="image/png" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} sizes="16x16" />
                    <link rel="apple-touch-icon" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} />
                </Helmet>
                <Layout>
                <Switch>  
                    <Route
                        path='/'
                        render={(props) => <UnderConstruction />}
                    />
                    <Route
                        path='/abstractsubmission'
                        render={(props) => <AbstractSubmission />}
                    />
                </Switch>
                </Layout>
            </BrowserRouter>
            </ThemeProvider>
            )
        }  else if (this.props.user.user.roles && this.props.event.event.available_routes) {

              let allowedRoutes = JSON.parse(this.props.event.event.available_routes).routes.filter((route) => {
                
                const includesRole = arrayComparison(route.allowedRoles, this.props.user.user.roles.split(', '))

                if(includesRole){
                  return route
                } else {
                  return false
                }
              })

            return (
                <ThemeProvider theme={theme}>
                <BrowserRouter>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.event ? this.props.event.event.title : title}</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="description" content="Virtual Environment Powered by PlanetConnect" />
                    <link rel="icon" type="image/png" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} sizes="16x16" />
                    <link rel="apple-touch-icon" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} />
                </Helmet>
                <Layout>
                <Switch>  
                    {allowedRoutes.map((route) => {              

                    const TagName = this.components[route.container];

                    if (route.title === 'Divider'){
                        return null
                    }
       
                    return (
                        <Route
                            key={route.title}
                            path= {`${route.route}`}
                            render={(props) => <TagName timestamp={new Date().toString()} {...props}/>}
                        />
                        )
                    })
                    }
                    <Route
                        exact
                        path='/whereby/host'
                        render={(props) => <WhereByHost />}
                    />
                    <Route
                        exact
                        path='/whereby/guest'
                        render={(props) => <WhereByGuest />}
                    />
                    <Route
                        path='/abstractsubmission'
                        render={(props) => <AbstractSubmission />}
                    />
                    <Route
                        path='/'
                        render={(props) => <Lobby />}
                    />
                </Switch>
                </Layout>
            </BrowserRouter>
            </ThemeProvider>
            )
        } else {

            return (
                <ThemeProvider theme={theme}>
                <BrowserRouter>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.event ? this.props.event.event.title : title}</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="description" content="Virtual Environment Powered by PlanetConnect" />
                    <link rel="icon" type="image/png" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} sizes="16x16" />
                    <link rel="apple-touch-icon" href={this.props.event ? this.props.event.event.favicon_location : faviconLocation} />
                </Helmet>
                    <Switch>
                        <Route
                            path='/signin'
                            render={(props) => <Signin />}
                        />
                        <Route
                            path='/forgotpassword'
                            render={(props) => <ForgotPassword />}
                        />
                        <Route
                            path='/confirmationcode'
                            render={(props) => <ConfirmationCode />}
                        />
                        <Route
                            path='/signup'
                            render={(props) => <Signup />}
                        />
                        <Route
                            path='/registration'
                            render={(props) => <Registration />}
                        />
                        <Route
                            path='/abstractsubmission'
                            render={(props) => <AbstractSubmission />}
                        />
                        <Route
                            path='/'
                            render={(props) => <LandingPage />}
                        />
                    </Switch>
                </BrowserRouter>
                </ThemeProvider>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
      user: state.user,
      event: state.event,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      setAuthenticatedUser: setAuthenticatedUser,
      setEventElements: setEventElements,
    }, dispatch);
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Router);
