
import { SET_EVENT_ELEMENTS, SET_EVENT_DETAILS, REMOVE_EVENT_ELEMENTS }  from '../Actions/types';

const initialState = {
    event: {},
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_EVENT_ELEMENTS:
            return {
                ...state,
                event: action.payload
            };
        case SET_EVENT_DETAILS:
            return {
                ...state,
                event: action.payload
            };
        case REMOVE_EVENT_ELEMENTS:
            return {
                ...state,
                event: {
                    main_color: state.event.main_color,
                    light_color: state.event.light_color,
                    favicon_location: state.event.favicon_location,
                    banner_location: state.event.banner_location,
                    landing_page_content: state.event.landing_page_content,
                    title: state.event.title,
                    under_construction: state.event.under_construction,
                }
            };
        default:
            return state;
    }
}

export default reducer;