import React, { Component } from 'react';
import NavBar from '../Components/Navbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogWrapper from '../Components/DialogWrapper';
import Privacy from './Privacy'
import { landingPage } from '../config';
import { connect } from 'react-redux';

const useStyles = theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  headings: {
    padding: theme.spacing(3),
  },
  listElement: {
    paddingLeft: "20px"
  },
  image: {
    height: "100px",
    width: "100px",
    borderRadius: '50%'
  },
  button: {
    position: 'absolute',
    bottom: '16px',
    left: '16px',
    color: "white", 
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
      },
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '60%',
    marginLeft: '20%',
    marginRight: '20%',
    fontSize: '1rem',
  },
  footer: {
    height: '5vw',
    backgroundColor: theme.palette.primary.main,
  },
});

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyOpen: false,
    };
  }

  handleChange(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
}

  render() {
    const { classes } = this.props;
    
    return (
      <div className={classes.root}>
        <NavBar />
        <Grid container layout={"row"} spacing={0} style={{ marginTop: "65px" }}>
          <Grid item xs={12}>
            <img
              src={this.props.event ? this.props.event.event.banner_location : landingPage.banner}
              alt="Lobby"
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                position: 'relative',
                padding: 0,
                margin: 0,
                top: "-5px"
              }}
            />
            <Container>
              <br />
              <br />
              <Typography variant="h5" gutterBottom align='center'>
              Welcome to the {this.props.event.event.title}.
              <br/><br/>
              {this.props.event.event.landing_page_content}
              </Typography>
              <br />
              <br />
              <Typography variant="body1" align="center">
                <Button
                  variant="contained"
                  color="primary"
                  href="/registration"
                >
                  Enter the Virtual Environment
                  </Button>
                  <br/><br/>
                  {/* <a target="_blank" rel="noopener noreferrer" href={supportDocument} style={{textDecoration: 'none', color: 'white',}}>
                  <Button color="primary" >User Guide</Button>
                  </a> */}
              </Typography>
              <br />
              {/* <div class="iframe-container" style={{overflow: "hidden", paddingTop: "56.25%", position: "relative"}}>
                  <iframe allow="microphone; camera" style={{border: "0", height: "100%", left: "0", position: "absolute", top: "0", width: "100%"}} src="https://success.zoom.us/wc/join/82010189152?pwd=VGwybmZjdkNTQlh1M2JJSW5qc21KZz09" frameBorder="0"></iframe>
              </div> */}
              <br />
              <br />
            </Container>
          </Grid> 
          <Grid item xs={6} align='left'><Button className={classes.button} variant='contained' onClick={(e) => this.handleChange(true, 'privacyOpen')}>View Privacy Policy</Button></Grid>
          {/* <Grid item xs={12} align='right'><Typography variant='subtitle1' color='textSecondary'>v1.4.0</Typography></Grid> */}
        </Grid>
        <DialogWrapper open={this.state.privacyOpen} handleClose={(e) => this.setState({privacyOpen: false})}>
          <Privacy />
          <Button className={classes.closeButton} variant='contained' onClick={(e) => this.handleChange(false, 'privacyOpen')}>Close</Button>
        </DialogWrapper>
        <Button className={classes.button} variant='contained' onClick={(e) => this.handleChange(true, 'privacyOpen')}>View Privacy Policy</Button>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    event: state.event,
  }
}

export default connect(mapStateToProps, null)(withStyles(useStyles)(LandingPage));