import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import ImageMapper from 'react-image-mapper';
import Dialog from '../Components/DialogWrapper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { eventId, lobbyImage, lobbyImageMap } from '../config';

// Configure Redux
import { connect } from 'react-redux';

const useStyles = (theme) => ({
  gridContainer: {
    margin: '0px -24px 0px -24px',
  },
  imageMapper: {
    display: 'block',
  },
  header: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'absolute',
    zIndex: '2',
    cursor: 'pointer'
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  banner: {
    width: '15vw',
    height: '5vw',
  },
  sponsorLevel: {
    width: '100%',
    color: 'white',
    position: 'relative',
    textShadow: '0px 5px 20px #000000CC',
    borderRadius: '0px 0px 5px 5px',
},
button: {
  color: "white",
  backgroundColor: theme.palette.primary.main
},
reference: {
  textDecoration: "none",
  color: theme.palette.primary.main,
}
});

class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: window.innerWidth * 0.955,
      imageClicked: null,
      modalOpen: false,
      rotationPosition: 0,
      optOut: null,
      optOutModalOpen: false,
    };
  }

    // JSON.parse(this.props.event.eventrotating_sponsors).sponsors

  // MAP = JSON.parse(this.props.event.event.lobby_image_map)

  componentDidMount() {

    window.addEventListener('resize', this.updateCoordinates);
    this.startSponsorChange()

      Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/optout/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          }
        })
          .then((response) => {
            if(response.data.length){
              if(!response.data[0].opted_out){
                this.setState({
                  optOut: false
                })
              } else { 
                this.setState({
                  optOut: true
                })
              }
            } else {
              this.setState({
                optOut: false,
                optOutModalOpen: true,
              })
            }
          })
      }).catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not process submission. ${error}`)
        }
      });
  }

  changeSponsor = () => {
    if (this.state.rotationPosition < JSON.parse(this.props.event.event.rotating_sponsors).sponsors.length - 1){
      this.setState({
        rotationPosition: this.state.rotationPosition + 1
      })
    } else {
      this.setState({
        rotationPosition: 0
      })
    }
  }

  startSponsorChange = () => {
    this.intervalID = setInterval(this.changeSponsor, 2000)
  }

  stopSponsorChange = () => {
    clearInterval(this.intervalID)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCoordinates);
    this.stopSponsorChange()
  }

  updateCoordinates = () => {
    let width = window.innerWidth * 0.955;

    this.setState({
      imageWidth: width,
    });
  };

  imageClick(event, item) {

    if (event.name === 'welcomeVideo') {
      this.setState({
        modalOpen: true,
      });
    }
    if (event.name === 'planetConnect') {
      const newWindow = window.open('https://www.planetconnect.com/', '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
      console.log('clicked');
    }
    else {
      this.props.history.push(`/${event.name}`);
    }
  }

  welcomeClose(event) {
    this.setState({
      modalOpen: false,
    });
  }

  optOutClose(event) {
    this.setState({
      optOutModalOpen: false,
    });
  }

  selectOptOutStatus(){

    Auth.currentSession()
    .then((data) => {
      axios({
        method: 'post',
        url: `/optout/${eventId}`,
        headers: {
          idtoken: data.idToken.jwtToken,
        },
        data: {
          opted_out: this.state.optOut
        },
      })
        .then((response) => {
            this.setState({
              optOutModalOpen: false,
            })
        })
      })
    .catch((error) => {
      if (this.props.debug.debug) {
        alert(`Error: Could not process your submission. ${error}`)
      }
    });
  }

  handleChange = () => {
    this.setState({
      optOut: !this.state.optOut
    })

  }

  // handleSwitchChange = (optOut) => {
  //   if(optOut){
  //     this.setState({
  //       optOut: true
  //     })
  //   } else {
  //     this.setState({
  //       optOut: false
  //     })
  //   }
  // }

  render() {
    const { classes } = this.props;

    let color;
    let lightColor;
    let level;
    let lobbyRotatingSponsors = null;

    if (JSON.parse(this.props.event.event.rotating_sponsors).sponsors.length > 0) {
      switch(JSON.parse(this.props.event.event.rotating_sponsors).sponsors[this.state.rotationPosition].level){
        case "Platinum": 
          color = '#996ae6';
          lightColor = '#c2a7f0';
          level = 'Platinum Sponsor';
        break
        case "Gold": 
          color = '#f2cd05';
          lightColor = '#f7e372';
          level = 'Gold Sponsor';
        break
        default: 
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Silver Sponsor';
        break
      }

    lobbyRotatingSponsors = (
      <Grid item xs={12} align='left' className={classes.header} onClick={()=> this.imageClick(JSON.parse(this.props.event.event.rotating_sponsors).sponsors[this.state.rotationPosition])}>
        <Paper className={classes.paper + " " + classes.banner}>
          <img
            style={{maxWidth: '80%', maxHeight: '80%'}} 
            src={JSON.parse(this.props.event.event.rotating_sponsors).sponsors[this.state.rotationPosition].logo} 
            alt='logo'/>
          <Typography
              variant='h6'
              className={classes.sponsorLevel}
              align='center'
              color='textPrimary'
              style={{ backgroundImage: `linear-gradient(${color}, ${lightColor})` }}
            >
              {level}
          </Typography>
        </Paper>
      </Grid>
      );
    }

    let welcomeVideo = (
      <div style={{position: 'relative', height: '0', paddingBottom: '56.25%', overflow: 'hidden'}}>
        <iframe
          title='welcome video'
          width={this.state.imageWidth * .66}
          height={this.state.imageWidth * .66 / 16 * 9}
          src={this.props.event.event.welcome_video_location}
          frameBorder='0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    );

    let optOutComponent = (
      <Grid container layout={"row"} spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                    <Typography variant='h5' align='center' gutterBottom>
                      Welcome to the Digital Venue for the {this.props.event.event.title}. We're glad you're here!
                    </Typography>
                    <br/><br/>
                    <Typography variant='body1' align='justify'>
                    Exhibitors and Sponsors help us make this event successful!  Exhibitors will be provided with your lead information, if you show interest in that Company or their products/services. 
                    Please check the privacy page for more information. If you would like your contact information to not be disseminated to exhibitors, you may opt-out below.  You may change your status at any time by going to the Settings page.
                    </Typography>
                    <br/>
                    <Button onClick={() => this.handleChange()}>
                      <Typography variant='body1' align='justify'>Opt In</Typography>
                        <Switch
                          checked={this.state.optOut}
                          onChange={this.handleChange}
                          color = "primary"
                        />
                      <Typography variant='body1' align='justify'>Opt Out</Typography>
                    </Button>
                    <br/>
                    {this.state.optOut 
                    ? 
                    <Typography variant='body1' align='justify'>Your personal information will not be disseminated to exhibitors.</Typography>
                    :
                    <br/>  
                    }
                    <br/>
                    <Button variant="contained" className={classes.button} onClick={() => this.selectOptOutStatus()}>
                      Submit
                    </Button>
                </Grid>
            </Grid>
    );

  let pageWidth = !this.props.sidebar.open ? this.state.imageWidth : window.innerWidth - 240;
  
    return (
        <Grid
          className={classes.gridContainer}
          container
          layout={'row'}
          align='center'
          justify='center'
        >
          <Grid item className={classes.imageMapper} xs={12}>
            <Dialog
              open={this.state.modalOpen}
              handleClose={this.welcomeClose.bind(this)}
            >
              {welcomeVideo}
            </Dialog>
            <Dialog
              open={this.state.optOutModalOpen}
              handleClose={this.welcomeClose.bind(this)}
            >
              {optOutComponent}
            </Dialog>
            {lobbyRotatingSponsors}
            <ImageMapper
              width={pageWidth}
              imgWidth={2000}
              src={lobbyImage}
              map={lobbyImageMap}
              onClick={(event, item) => this.imageClick(event, item)}
            />
          </Grid>
        </Grid>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: '#6f1a1d' },
};

function mapStateToProps(state) {
  return {
    user: state.user,
    sidebar: state.sidebar,
    debug: state.debug,
    event: state.event,
  }
}

export default connect(mapStateToProps, null)(withRouter(
  withStyles(useStyles)(withAuthenticator(Lobby, false, [], null, MyTheme)))
);