import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PubNubReact from 'pubnub';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

// table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../Store/Actions/getChatMessages';
import addChatSubscription from '../Store/Actions/addChatSubscription';
import setChatMembership from '../Store/Actions/setChatMembership';
import selectActivePrivateChat from '../Store/Actions/selectActivePrivateChat';
import setChatbar from '../Store/Actions/setChatbar';
import viewPrivateChat from '../Store/Actions/viewPrivateChat';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            marginTop: theme.spacing(4),
        }
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
    },
});

class Leads extends Component {
    constructor(props) {
        super(props);
        this.pubnub = new PubNubReact({
            publishKey: this.props.event.event.publish_key,
            subscribeKey: this.props.event.event.subscribe_key,
            uuid: this.props.user.user.email,
            autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
            restore: true, // enable catchup on missed messages
        });
        this.state = {
            leadsArray: [],
            leadsArrayObjects: []
        };
    }

    componentDidMount() {
        Auth.currentSession()
            .then((data) => {
                axios({
                    method: 'get',
                    url: '/audit',
                    headers: {
                        idtoken: data.idToken.jwtToken,
                    },
                    params: {
                        order_by: 'time_visited',
                        order_dir: 'asc',
                      },
                })
                    .then((response) => {

                        this.setState({
                            leadsArray: response.data,
                        })
                        
                        let users = []

                        response.data.map((event) => {

                            let presence = users.map((user)=> user.email).indexOf(event.user_email)

                            if(presence === -1){
                                users.push({
                                    firstName: event.user_first_name,
                                    lastName: event.user_last_name,
                                    email: event.user_email,
                                    company: event.user_account_name,
                                    title: event.user_title,
                                    site: event.user_site,
                                    department: event.user_department,
                                    actions: [{
                                        visited: event.url_visited,
                                        type: event.resource_type,
                                        name: event.resource_name,
                                        id: event.resource_id,
                                        createdAt: event.time_visited,
                                    }]
                                })
                            } else {
                                users[presence].actions.push({
                                    visited: event.url_visited,
                                    type: event.resource_type,
                                    name: event.resource_name,
                                    id: event.resource_id,
                                    createdAt: event.time_visited,
                                })
                            }

                            return null
                        })

                        this.setState({
                            leadsArrayObjects: users
                        })

                    })
                    .catch((error) => {
                        if (this.props.debug.debug) {
                            alert(`Error: Could not process Leads Report. ${error}`)
                          }
                    });
            })
            .catch((error) => {
                if (this.props.debug.debug) {
                    alert(`Error: Could not process Leads Report. ${error}`)
                  }
            });
    }


    inviteUser = async (user) => {

        let emailArray = [this.props.user.user.email, user].sort()
        let emails = `${emailArray[0]}${emailArray[1]}${'planetconnect'}`

        const hashCode = s => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0)

        const hashedEmails = hashCode(emails)

        await this.pubnub.objects.setChannelMembers({
            channel: hashedEmails,
            uuids: [ 
                this.props.user.user.email, { 
                    id: this.props.user.user.email, 
                    custom: { trialPeriod: false } 
                },
                user, { 
                    id: user, 
                    custom: { trialPeriod: false } 
                }
            ],
          });
    
          await this.pubnub.publish(
            {
                channel: user,
                message: {
                    text: `${this.props.user.user.first_name} ${this.props.user.user.last_name} has invited you to chat!`,
                    from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
                    accountName: this.props.user.user.account_name,
                    department: this.props.user.user.department,
                    email: this.props.user.user.email,
                    first_name: this.props.user.user.first_name,
                    last_name: this.props.user.user.last_name,
                    roles: this.props.user.user.roles,
                    site: this.props.user.user.site,
                    title: this.props.user.user.title,
                    channel: hashedEmails,
                },
            });

            await this.pubnub.publish(
                {
                    channel: this.props.user.user.email,
                    message: {
                        text: `You're entering a new chat!`,
                        from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
                        accountName: this.props.user.user.account_name,
                        department: this.props.user.user.department,
                        email: this.props.user.user.email,
                        first_name: this.props.user.user.first_name,
                        last_name: this.props.user.user.last_name,
                        roles: this.props.user.user.roles,
                        site: this.props.user.user.site,
                        title: this.props.user.user.title,
                        channel: hashedEmails,
                    },
                });

        await this.props.setChatbar(true)
        await this.props.selectActivePrivateChat(hashedEmails, user)
                
    }

    async selectPrivateChat(member){
        await this.props.setChatbar(true)

        const newChannel = this.props.chat.users.find((user) => user.uuid.id === member)
        
        await this.props.selectActivePrivateChat(newChannel.channel, member)
    }


    render() {

        const { classes } = this.props;

        const allRoutes = JSON.parse(this.props.event.event.available_routes).routes
        let pageTitle = ''
        allRoutes.forEach(route => {
        if (route.route === '/reporting') {
            pageTitle = route.title
        }
        })

        return (
            <React.Fragment>
                <br/>
                <Paper>
                <Typography variant='h4' gutterBottom align='center' style={{padding: '12px'}}>
                   {pageTitle}
                </Typography>
                </Paper>
            {this.state.leadsArrayObjects.map((lead, index) =>{
                return (
            <Accordion
                key={index}
                className={classes.root}
              >
                <AccordionSummary
                  className={classes.expansionPanelMain}
                  expandIcon={<ExpandMoreIcon />}
                >
                <Typography variant="h6" component='div'>
                    {lead.firstName + " " + lead.lastName}
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction='row'
                    spacing={2}
                    alignItems='center'
                    justify='space-between'
                  >
                    <Grid item xs={6}>
                    <Typography variant="body1" align='left' >
                        {lead.email ? <React.Fragment><b>Email:</b> {lead.email}</React.Fragment>: <React.Fragment/>}
                    </Typography>
                    <Typography variant="body1" align='left' >
                        {lead.company ? <React.Fragment><b>Company:</b> {lead.company}</React.Fragment>: <React.Fragment/>}
                    </Typography>
                    <Typography variant="body1" align='left' >
                        {lead.title ? <React.Fragment><b>Title:</b> {lead.title}</React.Fragment>: <React.Fragment/>}
                    </Typography>
                    <Typography variant="body1" align='left' >
                        {lead.site ? <React.Fragment><b>Site:</b> {lead.site}</React.Fragment>: <React.Fragment/>}
                    </Typography>
                    <Typography variant="body1" align='left' >
                        {lead.department ? <React.Fragment><b>Department:</b> {lead.department}</React.Fragment>: <React.Fragment/>}
                    </Typography>
                    </Grid>
                    {this.props.user.user.email !== lead.email ?
                    <Grid item xs={6} >
                    {!this.props.chat.users.find((user) => user.uuid.id === lead.email) ?  //|| this.props.chat.indexOf(lead.email)
                    <FormControl className={classes.formControlButton}>
                        <Button variant="contained" className={classes.button} onClick={() => this.inviteUser(lead.email)} >
                            Start New Chat
                        </Button>
                    </FormControl>
                    :
                    <FormControl className={classes.formControlButton}>
                        <Button variant="contained" className={classes.button} onClick={() => this.selectPrivateChat(lead.email)} >
                            View Private Chat
                        </Button>
                    </FormControl>
                    }
                    </Grid>
                    :
                    <React.Fragment></React.Fragment>
                }
                    <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>Name</TableCell>
                                <TableCell align='center'>Type</TableCell>
                                <TableCell align='center'>Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lead.actions.map((companyLeads, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell align='center'>
                                                    {companyLeads.name}
                                                </TableCell>
                                                <TableCell align='center' >
                                                    {companyLeads.type}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {`${moment(companyLeads.createdAt).format('MMMM Do, hh:mm a')} EDT`}

                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              )
            })}
            </React.Fragment>
        );

    }
}


const MyTheme = {
    button: { backgroundColor: "#E13E2F" },
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getChatMessages: getChatMessages,
        addChatSubscription: addChatSubscription,
        setChatMembership: setChatMembership,
        selectActivePrivateChat: selectActivePrivateChat,
        setChatbar: setChatbar,
        viewPrivateChat: viewPrivateChat,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
        chat: state.chat,
        debug: state.debug,
        event: state.event,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withAuthenticator(Leads, false, [], null, MyTheme)));