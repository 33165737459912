import { endpoint } from '../config';
import moment from 'moment';


let siteURL = endpoint.slice(0, -3)
let posterHallURL = siteURL + 'posterhall'

const prepareData = (obj, arr, type) => {
    let data = {};    
    let startTime = moment(obj.start_time).format('YYYYMMDDTHHmmss')
    let endTime = moment(obj.end_time).format('YYYYMMDDTHHmmss')
    // let startTime = moment(obj['start_time']).format('YYYYMMDD') + 'T' + moment(obj['start_time'], 'HH:mm:ss').format('HHmmss')
    // let endTime = moment(obj['start_time']).format('YYYYMMDD') + 'T' + moment(obj['end_time'], 'HH:mm:ss').format('HHmmss')
    
    let a = moment(startTime)
    let b = moment(endTime)
    let duration = b.diff(a, 'hours', true); 
    let timezone = moment.tz.guess();
    
    for (let field of arr) {
        
        if (field in obj) {
            data[field] = obj[field];
        } else if (!(field in obj)) {
            
            switch (field) {
                case ('location'):
                    if (obj['presentation_link'] === null) {
                        data[field] = posterHallURL
                    } else {
                        data[field] = obj['presentation_link']
                    }
                    break;
                case ('description'):
                    data[field] = obj['topic']
                    break;
                case ('startDatetime'):
                    data[field] = startTime;
                    break;
                case ('endDatetime'):
                    data[field] = endTime;
                    break;
                case ('duration'):
                    data[field] = duration;
                    break;
                case ('timezone'):
                    data[field] = timezone;
                    break;
                default:
                    data[field] = null;

                }
        } 
    }
    return data;
}

export default prepareData;
