import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import AgendaRowItem from './AgendaRowItem';
import { Typography, Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import momentTimezone from 'moment-timezone'; //eslint-disable-line
import { fallbackImages, eventId } from '../../config';
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda'

// Configure Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setBriefcase from '../../Store/Actions/setBriefcase';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  button: {
    color: 'white',
    backgroundColor: '#6f1a1d',
  },
  day: {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  }
});

class MyPersonalAgenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
      days: [],
      rooms: [],
      positionsAvailable: 5,
      selectedDate: '',
      ETtoUTCoffset: '',
      briefcaseElements: [],
    };
  }
  componentDidMount() {
    this.props.setBriefcase();

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
          },
        })
          .then((response) => {

            let abstracts = [];
            let days = [];
            let rooms = [];

            for (let x = 0; x < response.data.data.length; x++) {
              let abstract = response.data.data[x];
              abstract.date = moment(abstract.start_time).format('YYYY-MM-DD');

              
              if (abstract.start_time && abstract.accepted_type !== 'Workshop') {

                abstracts.push(abstract);
              }

              if (abstract.date && !days.includes(abstract.date) && abstract.start_time) {
                days.push(abstract.date);
              }

              if (abstract.room && !rooms.includes(abstract.room) && abstract.start_time) {
                 if(abstract.room === "FEATURED"){
                  rooms.unshift(abstract.room);
                 } else{
                    rooms.push(abstract.room);
                 }
              }
            }
            
            abstracts.sort((a,b) => {
              return moment.duration(a.start_time).asMinutes() - moment.duration(b.start_time).asMinutes()
          });

              let idArray = this.props.briefcase.briefcase.filter((item) => {
                if (item.resource_type === 'Presentation') {
                  return true;
                } else {
                  return false;
                }
              }).map((item) => item.resource_id)

              let briefcaseElements = abstracts.filter((abstract) => {
                
                let briefcaseElement = null;
                idArray.map((id) => {
                  if (id === abstract.abstract_id) {
                    briefcaseElement = abstract
                    return abstract;
                  } 
                  return abstract;
                })
                if (briefcaseElement) {
                  return true
                } else {
                  return false
                }
              })

              days.sort();

              days = days.filter(day => {
                return briefcaseElements.some(e => day === e.date)})

              
            const currentDayIndex = days.findIndex((day) => day.substring(0, 10) === moment().format("YYYY-MM-DD"))

            let currentDay;
            if (currentDayIndex > -1){
              currentDay = days[currentDayIndex]
            } else {
              currentDay = days[0]
            }

              this.setState({
              abstracts,
              days,
              rooms,
              selectedDate: currentDay,
              briefcaseElements,
              topicMap: JSON.parse(this.props.event.event.topic_map),
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display My Personal Agenda. ${error}`)
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display My Personal Agenda. ${error}`)
        }
      });
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(this.props.event.event.available_routes).routes
    let pageTitle = ''
    allRoutes.forEach(route => {
      if (route.route === '/mypersonalagenda') {
        pageTitle = route.title
      }
    })

    return (
      <>
        <Grid
          container
          layout={'row'}
          spacing={2}
          justify='center'
          >
            <Grid item xs={12}>
              <Paper>
                <Typography style={{textAlign: 'center', padding: '16px 0px 8px 0px'}} variant='h4' color='textPrimary'>
                  Welcome to {pageTitle}
                </Typography>
                <Typography style={{textAlign: 'center', padding: '0px 0px 16px 0px'}} variant='h6' color='textPrimary'>
                  Presentations added to your briefcase will display here in chronological order.
                </Typography>
              </Paper>
            </Grid>
          {this.state.days.map((day, index) => {
            if (day === this.state.selectedDate) {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper
                    className={classes.paper + " " + classes.day}
                    variant='elevation'
                    elevation={10}
                  >
                    <Typography variant='h5'>
                      {moment(day).format('dddd, MMMM Do')}
                    </Typography>
                  </Paper>
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={index}
                  onClick={() => this.handleChange(day, 'selectedDate')}
                  style={{ cursor: 'pointer' }}
                >
                  <Paper className={classes.paper}>
                    <Typography variant='h5'>
                      {moment(day).format('dddd, MMMM Do')}
                    </Typography>
                  </Paper>
                </Grid>
              );
            }
          })}
        </Grid>
        <Typography variant='h6' color='textSecondary' align='center' style={{padding: '16px 0px 0px 0px',}}>
          All times displayed are in your local time zone. ({moment.tz.guess()})
        </Typography>
        {this.state.briefcaseElements.map((abstract, index) => {
          if (this.state.selectedDate === abstract.date) {
            let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title']
            let calendarAbstract = prepareObject(abstract, requiredFields, 'presentation')

            let agendaListImage;
            if (abstract.supporting_file_url) {
              agendaListImage=abstract.supporting_file_url
            } else if (this.state.topicMap[abstract.room]) {
              agendaListImage= this.state.topicMap[abstract.room]
            } else if (this.state.topicMap[abstract.topic]) {
              agendaListImage=this.state.topicMap[abstract.topic]
            } else {
              agendaListImage=fallbackImages.agendaCard
            }

            return (
              <AgendaRowItem
                key={index}
                abstract={abstract}
                calendarAbstract={calendarAbstract}
                roomImage={agendaListImage}
                selectedDate={this.state.selectedDate}
              />
            );
        } else {
          return (null)
        }})}
      </>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: '#E13E2F' },
};

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    debug: state.debug,
    event: state.event
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBriefcase: setBriefcase,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withAuthenticator(MyPersonalAgenda, false, [], null, MyTheme)));
