import { SET_BRIEFCASE } from './types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { eventId } from '../../config';

export default function setBriefcase() {
  return (dispatch, getState) => {
  
  const items = getState().debug;

      Auth.currentSession()
        .then(data => {
          axios({
            method: 'get',
            url: `/briefcase/${eventId}`,
            headers: { idtoken: data.idToken.jwtToken },
          })
            .then((response) => {
              dispatch(setBriefcaseAsync(response.data))
            })
            .catch((error) => {
              if (items.debug) {
                alert(`Error: Could not get the briefcase information. ${error}`)
              }
            });
          })
          .catch((error) => {
            if (items.debug) {
              alert(`Error: Could not update the briefcase information. ${error}`)
            }
        });
    }
}

function setBriefcaseAsync(briefcase){
  return {
    type: SET_BRIEFCASE,
    payload: briefcase
  };
}