import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NavBar from '../../Components/Navbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '80%'
    },
    formControlButton: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '50%'
    },
    button: {
        color: "white", 
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
          },
    }
});

class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            email: '',
            signedUp: false,
            confirmationCode: '',
            confirmationCodeError: false,
            confirmationCodeErrorMessage: '',
            userConfirmed: false,
            usernameError: false,
            usernameErrorMessage: '',
            parameterError: false,
            parameterErrorMessage: '',
            emailError: false,
            emailErrorMessage: '',
            formSubmitted: false
        }
    }

    handleChange(event, inputIdentifier) {
        this.setState({ [inputIdentifier]: event.target.value });
    }


    handleSubmit = () => {

        if (this.state.email.indexOf(" ") > 0){
            this.setState({
                emailError: true,
                emailErrorMessage: 'Email cannot contain spaces',
            })
        } else {

        this.setState({
            formSubmitted: true,
            emailError: false,
            emailErrorMessage: '',
        })

        Auth.signUp({
            username: this.state.email,
            password: this.state.password,
            attributes: {
                email: this.state.email,
            }
        }).then((response) => {
            this.setState({
                signedUp: true,
                formSubmitted: false
            })
        }).catch((error) => {
            console.log(error)
            switch(error.code){
                case "UsernameExistsException":
                    this.setState({
                        emailError: true,
                        emailErrorMessage: 'User already exists. Please go to the login page or contact support for help.',
                        formSubmitted: false
                    })
                    break
                case "InvalidParameterException":
                    this.setState({
                        emailError: true,
                        emailErrorMessage: 'Please ensure you are using a valid email',
                        formSubmitted: false
                    })
                    break
                default:
                    this.setState({
                        parameterError: true,
                        parameterErrorMessage: 'Password must be at least 8 characters long.',
                        emailError: true,
                        emailErrorMessage: 'Please ensure you are using a valid email',
                        formSubmitted: false
                    })
                }
            })

        }
    }

    handleValidation = () => {

        // this.setState({
        //     formSubmitted: true
        // })

        // Auth.confirmSignUp(
        //     this.state.email,
        //     this.state.confirmationCode
        // ).then((response) => {
            this.setState({
                userConfirmed: true,
                formSubmitted: false
            })
        // }).catch((error) => {
        //     this.setState({
        //         confirmationCodeError: true,
        //         confirmationCodeErrorMessage: 'Incorrect Confirmation Code',
        //         formSubmitted: false
        //     })
        // })
    }

    render() {
        const { classes } = this.props;

        let form;
        if (this.state.userConfirmed){
            form = (
                <Paper className={classes.paper}>
                    <Typography variant="h5">
                        Success!
                    </Typography>
                    <FormControl className={classes.formControlButton}>
                    <Button variant="contained" component={Link} to={'/signin'} className={classes.button}>
                            Log In
                    </Button>
                    </FormControl>
                </Paper>
            )
        } else if (this.state.signedUp) {
            form = (
                <Paper className={classes.paper}>
                    <Typography variant="h5">
                        Confirm Your Email Address
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                    Check your inbox for an email from eregistration@planetconnect.com for your verification link, then return here to sign in. 
                    The verification link will only be valid for 1 hour.
                    </Typography>
                    {/* <FormControl className={classes.formControl}>
                        <TextField
                            onChange={(event) => this.handleChange(event, "email")}
                            value={this.state.email}
                            label="Email"
                            disabled
                        />
                    </FormControl> */}
                    <FormControl className={classes.formControl}>
                        <Button variant="contained" component={Link} to={'/signin'} className={classes.button}>
                        I've Confirmed my Email
                        </Button>
                    </FormControl>
                </Paper>
            )
        } else {
            form = (
                <Paper className={classes.paper}>
                    <Typography variant="h5">
                        Sign Up
                    </Typography>
                    {/* <FormControl className={classes.formControl}>
                        <TextField
                            onChange={(event) => this.handleChange(event, "username")}
                            value={this.state.username}
                            label="Username"
                            fullWidth
                            error = {this.state.usernameError}
                            helperText={this.state.usernameError ? this.state.usernameErrorMessage : "Username cannot contain spaces"}
                        />
                    </FormControl> */}
                    <FormControl className={classes.formControl}>
                        <TextField
                            onChange={(event) => this.handleChange(event, "email")}
                            value={this.state.email}
                            label="Email"
                            error = {this.state.emailError}
                            helperText={this.state.emailErrorMessage}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            type="password"
                            onChange={(event) => this.handleChange(event, "password")}
                            value={this.state.password}
                            label="Password"
                            error = {this.state.parameterError}
                            helperText={this.state.parameterErrorMessage}
                        />
                        </FormControl>
                    <FormControl className={classes.formControlButton}>
                        <Button variant="contained" onClick={this.handleSubmit} className={classes.button}>
                        {this.state.formSubmitted ? <CircularProgress color="inherit"/> : <div>Sign Up</div>}
                        </Button>
                    </FormControl>
                    {/* <Button color="inherit" component={Link} to={'/confirmationcode'}>Confirmation Code</Button> */}
                </Paper>
            )
        }
        return (
            <div>
                <NavBar />
                <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        {form}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(useStyles)(SignUpForm);