const prepareData = (obj, arr, type) => {
    let data = {};
    for (let field of arr)
        if (field in obj) {
            data[field] = obj[field];
            if (field.includes('checkWord')) {
                let newField = field.replace('checkWord', 'replaceWord')
                Object.defineProperty(data, newField,
                    Object.getOwnPropertyDescriptor(data, field));
                delete data[field];
            }
        } else if (!(field in obj)) {
            switch (field) {
                case ('event_id'):
                    data[field] = obj['show_id']
                    break;
                case ('resource_id'):
                    data[field] = obj['abstract_id']
                    break;
                case ('resource_type'):
                    data[field] = type;
                    break;
                case ('banner_id'):
                    let newId = obj['abstract_id'].slice(2)
                    data[field] = parseInt(newId)
                    break;
                case ('manual_override'):
                    data[field] = false
                    break;
                case ('text_body'):
                    data[field] = `Your saved presentation is happening now: ${obj.title}`;
                    break;
                default:
                    data[field] = null;

                }
        } 
    return data;
}

export default prepareData;