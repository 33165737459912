import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import IconButton from '@material-ui/core/IconButton';
import Brightness1Icon from '@material-ui/icons/Brightness1';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0.5),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  panelExpanded: {
    '& .MuiGrid-item': {
    },
  },
  icon: {
    minWidth: '10em',
    maxWidth: '30em',
    maxHeight: '30em',
    width: '100%',
  },

  buttonLearn: {
    minWidth: '80%',
    fontSize: theme.typography.pxToRem(15),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  textBody: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: 'center',
    textAlign: 'left',
  },
});

class ExhibitListItem extends Component {
  render() {
    const { classes } = this.props;

    let favorited = false;

    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.props.exhibit.exhibition_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    let level;
    let color;

    switch (this.props.exhibit.booth_type) {
      case '1':
        color = '#0600b1';
        level = 'Signature';
        break;
      case '2':
          color = '#996ae6';
          level = 'Platinum ';
        break;
      case '3':
        color = '#ffda14';
        level = 'Gold';
        break;
      case '4':
        color = '#c0c0c0';
        level = 'Silver';
        break;
      case '5':
        color = '#3287a8';
        level = 'Booth';
        break;
      case '7':
        color = '#9e9e9e';
        level = 'Media Partner';
        break;
      case '8':
        color = '#f0a82b';
        level = 'Event Manager';
        break;
      case '9':
        color = '#990000';
        level = 'University';
        break;
      case '10':
        color = '#9e9e9e';
        level = 'Information';
        break;
      case '11':
        color = '#9e9e9e';
        level = 'Host';
        break;
      default:
        color = '#ffffff00';
        level = '';
        break;
    }

    return (
      <Accordion
        key={`ExhibitListItem${this.props.exhibit.index}`}
        className={classes.root}
      >
        <AccordionSummary
          className={classes.expansionPanelMain}
          expandIcon={<ExpandMoreIcon />}
          id={this.props.exhibit.exhibition_id}
        >
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems='center'
            justify='space-between'
          >
            <Grid item xs={1}>
              {favorited ? (
                <IconButton
                  aria-label='remove from briefcase'
                  onClick={() =>
                    this.props.deleteBriefcaseElement(
                      this.props.exhibit.exhibition_id
                    )
                  }
                >
                  <WorkIcon />
                </IconButton>
              ) : (
                  <IconButton
                    aria-label='add to briefcase'
                    onClick={() =>
                      this.props.addBriefcaseElement(
                        'Exhibit',
                        this.props.exhibit.exhibition_id,
                        this.props.exhibit.account_name
                      )
                    }
                  >
                    <WorkOutlineIcon />
                  </IconButton>
                )}
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant='body1'
                color='textSecondary'
                component='div'
                align='left'
              >
                <Brightness1Icon
                  style={{
                    fill: color,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                />{' '}
                <p style={{ paddingLeft: '5px', display: 'inline-block' }}>
                  {level}
                </p>
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7} lg={6}>
              <Typography className={classes.heading}>
                {this.props.exhibit.account_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography className={classes.secondaryHeading}>
                {this.props.exhibit.website}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            className={classes.panelExpanded}
            container
            layout={'row'}
            spacing={2}
            alignItems='center'
            justify='space-between'
          >
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <img
                className={classes.icon}
                src={this.props.exhibit.logo_address}
                alt=''
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8}>
              <Typography className={classes.textBody}>
                {this.props.exhibit.main_text}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} align='right'>
              <Button
                className={classes.buttonLearn}
                variant='contained'
                size='medium'
                onClick={(event) =>
                  this.props.toggleFullscreenHandlerOn(
                    event,
                    this.props.exhibit.exhibition_id,
                    this.props.exhibit.account_name
                  )
                }
              >
                Visit Booth
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ExhibitListItem));
