import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import IconButton from '@material-ui/core/IconButton';
import { fallbackImages } from '../../config';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  card: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    minHeight: '100%',
    paddingTop: '10px',
    zIndex: 1,
  },
  media: {
    height: '0',
    paddingTop: '60%',
    maxWidth: '90%',
    backgroundSize: 'contain',
    marginLeft: '5%',
  },
  header: {
    '& .MuiCardHeader-avatar': {
      marginRight: '0',
      '& .MuiCardActions-root': {
        padding: '8px 0px 8px 0px',
      },
    },
  },
  sponsorLevel: {
    width: '100%',
    color: 'white',
    position: 'relative',
    textShadow: '0px 5px 20px #000000CC',
    fontSize: '150%',
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
  },
  bottomhalf: {
    height: '50%',
    maxHeight: '50%',
    flex: '0 1 auto',
    alignSelf: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.short,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class ExhibitCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      isHovering: false,
      briefcaseHover: false,
    };
  }

  handleExpandClick() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  render() {
    const { classes } = this.props;

    let favorited = false;

    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.props.exhibit.exhibition_id
        ) {
          favorited = true;
          break;
        }
      }
    }
    let level;
    let color;
    let lightColor;
    if (this.props.exhibit.type !== 'Internal') {
      switch (this.props.exhibit.booth_type) {
        case '1':
          color = '#0600b1';
          lightColor = '#554fff';
          level = 'Signature Sponsor';
          break;
        case '2':
          color = '#996ae6';
          lightColor = '#c2a7f0';
          level = 'Platinum Sponsor';
          break;
        case '3':
          color = '#ff9a0d';
          lightColor = '#fcb044';
          level = 'Gold Sponsor';
          break;
        case '4':
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Silver Sponsor';
          break;
        case '7':
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Media Partner';
          break;
        case '8':
          color = '#f0a82b';
          lightColor = '#edb85c';
          level = 'Event Manager';
          break;
        case '9':
          color = '#990000';
          lightColor = '#ab2b2b';
          level = 'University';
          break;
        case '10':
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Information';
          break;
        case '11':
          color = '#9e9e9e';
          lightColor = '#c0c0c0';
          level = 'Host';
          break;
        default:
          color = '#ffffff00';
          lightColor = '#ffffff00';
          level = '';
          break;
      }
    }

    return (
      <Tooltip 
        title={this.props.exhibit.main_text ? this.props.exhibit.main_text : ''} 
        placement='bottom'>
        <Card
          raised={this.state.isHovering}
          onMouseEnter={() => this.handleChange(true, 'isHovering')}
          onMouseLeave={() => this.handleChange(false, 'isHovering')}
          className={classes.card}
          onClick={this.state.briefcaseHover ? null : (event) =>
            this.props.toggleFullscreenHandlerOn(
              event,
              this.props.exhibit.exhibition_id,
              this.props.exhibit.account_name
            )
          }
        >
          <CardMedia
            className={classes.media}
            image={this.props.exhibit.logo_address ? this.props.exhibit.logo_address : fallbackImages.exhibitCard}
            title='exhibitor logo'
          />
          <div className={classes.bottomHalf}>
            <CardHeader
              className={classes.header}
              avatar={
                <CardActions disableSpacing>
                  {favorited ? (
                    <IconButton
                      aria-label='remove from briefcase'
                      onClick={() =>
                        this.props.deleteBriefcaseElement(
                          this.props.exhibit.exhibition_id
                        )
                      }
                      onMouseEnter={() =>
                        this.handleChange(true, 'briefcaseHover')
                      }
                      onMouseLeave={() =>
                        this.handleChange(false, 'briefcaseHover')
                      }
                    >
                      <WorkIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label='add to briefcase'
                      onClick={() =>
                        this.props.addBriefcaseElement(
                          'Exhibit',
                          this.props.exhibit.exhibition_id,
                          this.props.exhibit.account_name
                        )
                      }
                      style={{zIndex: 2,}}
                      onMouseEnter={() =>
                        this.handleChange(true, 'briefcaseHover')
                      }
                      onMouseLeave={() =>
                        this.handleChange(false, 'briefcaseHover')
                      }
                    >
                      <WorkOutlineIcon />
                    </IconButton>
                  )}
                </CardActions>
              }
              title={this.props.exhibit.account_name}
              style={{ padding: '0', paddingRight: '8px', }}
            />

            <Typography
              variant='h5'
              className={classes.sponsorLevel}
              align='center'
              color='textPrimary'
              style={{
                backgroundImage: `linear-gradient(${color}, ${lightColor})`,
              }}
            >
              {level}
            </Typography>
          </div>
        </Card>
      </Tooltip>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ExhibitCard));
