import { SET_SNACKBAR } from './types';

export default function setSnackbar(snackBarState) {
  return dispatch => {
    dispatch(setSnackbarAsync(snackBarState));
  }
}

function setSnackbarAsync(snackBarState){
  return {
    type: SET_SNACKBAR,
    payload: snackBarState
  };
}