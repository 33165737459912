import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { withAuthenticator } from 'aws-amplify-react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

//Other
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setSidebar from '../Store/Actions/setSidebar';
import setChatbar from '../Store/Actions/setChatbar';
import setChatMembership from '../Store/Actions/setChatMembership';
import removeAuthenticatedUser from '../Store/Actions/removeUser';
import removeEventElements from '../Store/Actions/removeEventElements';
import removeChat from '../Store/Actions/removeChat';
import Snackbar from './Snackbar';
import PrivateChat from '../Containers/Chat/PrivateChat';
import SelectChat from '../Containers/Chat/SelectChat';
import ActivePrivateChat from '../Containers/Chat/ActivePrivateChat';

// Icons
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import BusinessIcon from '@material-ui/icons/Business';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PanoramaIcon from '@material-ui/icons/Panorama';
import ForumIcon from '@material-ui/icons/Forum';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WorkIcon from '@material-ui/icons/Work';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import SettingsIcon from '@material-ui/icons/Settings';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import MyleadsIcon from '@material-ui/icons/HowToReg';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import BarChartIcon from '@material-ui/icons/BarChart';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PersonIcon from "@material-ui/icons/Person";
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import WidgetsIcon from '@material-ui/icons/Widgets';

import { topNavigation, drawerWidth, rightDrawerWidth } from '../config';  

const useStyles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#ebebeb',
    minHeight: '100vh'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftRight: {
    marginRight: rightDrawerWidth,
    width: `calc(100% - ${rightDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftBoth: {
    marginRight: rightDrawerWidth,
    width: `calc(100% - ${drawerWidth + rightDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  rightMenuButton: {
    marginLeft: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rightDrawer: {
    width: rightDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  rightDrawerOpen: {
    width: rightDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  rightDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0) + 1,
    },
    // width: '0px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  title: {
    flexGrow: 11,
    textAlign: 'center',
  },
  agendaPdf: {
    flexGrow: 1,
    textAlign: 'center',
  }
});

class MiniDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      allowedRoutes: [],
    };
  }

  components = {
  IconButton,
  MenuIcon,
  OndemandVideoIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  BusinessIcon,
  HelpIcon,
  ExitToAppIcon,
  HomeIcon,
  FilterNoneIcon,
  PanoramaIcon,
  ForumIcon,
  AccessTimeIcon,
  WorkIcon,
  FeaturedPlayListIcon,
  BubbleChartIcon,
  SettingsIcon,
  GpsFixedIcon,
  MyleadsIcon,
  LocalCafeIcon,
  BarChartIcon,
  FiberNewIcon,
  EventAvailableIcon,
  PersonIcon,
  CastForEducationIcon,
  WidgetsIcon,
};

  componentDidMount() {
        
   let allowedRoutes 
   if(!this.props.event.event.under_construction){

    allowedRoutes = JSON.parse(this.props.event.event.available_routes).routes.filter((route) => {

      const allowed = route.allowedRoles.filter((role) => this.props.user.user.roles.includes(role))

      if(allowed.length){
        
        return route

      } else {
       
        return null

      }
    })

    this.setState({
      allowedRoutes
    })
  }

    this.props.setChatMembership(this.props.user.user, 'setChat')
    document.getElementById('chat').scrollTop = 9999999;    
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps === this.props){
      return false
    }
    
    return true
  }

  signOut = async () => {

    await this.props.removeAuthenticatedUser()
    await this.props.removeEventElements()
    await this.props.removeChat()

    await Auth.signOut({ global: true })

  }

  componentWillUnmount() {
    this.props.setChatMembership(this.props.user.user, 'leave')
    this.props.history.push('/')
  }

  render() {
    const { classes } = this.props;

    let navigationIcons

    if(!this.props.event.event.under_construction){

    navigationIcons = this.state.allowedRoutes.map((navigation, index)=>{

      const TagName = this.components[navigation.icon];

      if (navigation.title === 'Divider'){
        
        return <Divider key={`divider${index}`}/>
    }
      if (navigation.title === 'Debug'){
        
        return null
    }

      return(
        <React.Fragment key={navigation.title}>
        <Tooltip title={navigation.title} placement="right">
        <ListItem button  component={Link} to={navigation.route}>
          <ListItemIcon><TagName /></ListItemIcon>
          <ListItemText primary={navigation.title} />
        </ListItem>
      </Tooltip>
      </React.Fragment>
      )
    })    
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.props.sidebar.open,
            [classes.appBarShiftRight]: this.props.sidebar.chatOpen,
            [classes.appBarShiftBoth]: this.props.sidebar.chatOpen && this.props.sidebar.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.props.setSidebar(true)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.props.sidebar.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            {this.props.event.event.icon_location ?  
            <img src={this.props.event.event.icon_location} alt='Event Logo' style={{ height: '60px',marginRight: '50px' }} />
            : null}
            <Typography variant="button" className={classes.agendaPdf} >
              <a target="_blank" rel="noopener noreferrer" href={this.props.event.event.agenda_public_location ? this.props.event.event.agenda_public_location : topNavigation.agendaPublic} style={{textDecoration: 'none', color: 'white',}}>
                AGENDA PDF
              </a>
              </Typography>
            <Hidden xsDown>
              <Typography variant="h6" className={classes.title} align="left" >
                Welcome, {this.props.user.user.first_name} from {this.props.user.user.account_name}!
              </Typography>
            </Hidden>
            {this.props.chat.memberships.length ? <SelectChat /> : <React.Fragment></React.Fragment>}
            {this.props.chat.activePrivateChat ?
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => this.props.setChatbar(true)}
                edge="start"
                className={clsx(classes.rightMenuButton, {
                  [classes.hide]: this.props.sidebar.chatOpen,
                })}
              >
                <ChevronLeftIcon />
              </IconButton>
              :
              <React.Fragment></React.Fragment>
            }
          </Toolbar>
        </AppBar>
        <Hidden smUp>
        <Drawer 
          anchor='left' 
          open={this.props.sidebar.open} 
          onClose= {() => this.props.setSidebar(false)}
          >
          <List>
            {navigationIcons}
            {this.props.user.user.roles.includes('Booth Admin') ? 
              <Tooltip title="To Admin Portal" placement="right">
                <a rel='noopener noreferrer' href='https://admin.planetconnect.com/' target='_blank' alt='admin panels' style={{textDecoration: 'none', color: 'inherit'}}>
              <ListItem button key={"To Admin Portal"} >
                <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                <ListItemText primary={"To Admin Portal"} />
              </ListItem>
              </a>
            </Tooltip>
          : null}
            <Tooltip title="Sign Out" placement="right">
              <ListItem onClick={this.signOut} button key={"Sign Out"} component={Link} to={'/'}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={"Sign Out"} />
              </ListItem>
            </Tooltip>
          </List>
          </Drawer>
          </Hidden>

          <Hidden xsDown>
        <Drawer
          variant="permanent"
          anchor='left'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.props.sidebar.open,
            [classes.drawerClose]: !this.props.sidebar.open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.props.sidebar.open,
              [classes.drawerClose]: !this.props.sidebar.open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={() => this.props.setSidebar(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {navigationIcons}
            {this.props.user.user.roles.includes('Booth Admin') ? 
            <a rel='noopener noreferrer' href='https://admin.planetconnect.com/' target='_blank' alt='admin panels' style={{textDecoration: 'none', color: 'inherit'}}>
            <Tooltip title="To Admin Portal" placement="right">
              <ListItem button key={"To Admin Portal"} >
                <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                <ListItemText primary={"To Admin Portal"} />
              </ListItem>
            </Tooltip>
            </a>
          : null}
            <Tooltip title="Sign Out" placement="right">
              <ListItem onClick={this.signOut} button key={"Sign Out"} component={Link} to={'/'}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={"Sign Out"} />
              </ListItem>
            </Tooltip>
          </List>
        </Drawer>
        </Hidden>
        <main className={classes.content}>
          {this.props.children}
          <Snackbar />
        </main>
        <Drawer
        id='chat'
          variant="persistent"
          anchor="right"
          className={clsx(classes.rightDrawer, {
            [classes.rightDrawerOpen]: this.props.sidebar.chatOpen,
            [classes.rightDrawerClose]: !this.props.sidebar.chatOpen,
          })}
          classes={{
            paper: clsx({
              [classes.rightDrawerOpen]: this.props.sidebar.chatOpen,
              [classes.rightDrawerClose]: !this.props.sidebar.chatOpen,
            }),
          }}
          open={this.props.sidebar.chatOpen}
        >
          <div className={classes.toolbar} style={{position: 'fixed', backgroundColor: 'white', zIndex: '1000', width: rightDrawerWidth}}>
            <ActivePrivateChat/>
            <IconButton onClick={()=>this.props.setChatbar(false)}>
              <ChevronRightIcon />
            </IconButton>
            <Divider />
          </div>
          <PrivateChat/>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar,
    user: state.user,
    chat: state.chat,
    event: state.event,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setSidebar: setSidebar,
    setChatbar: setChatbar,
    removeAuthenticatedUser: removeAuthenticatedUser,
    removeEventElements: removeEventElements,
    setChatMembership: setChatMembership,
    removeChat: removeChat,
  }, dispatch);
}

const MyTheme = {
  button: { backgroundColor: '#6f1a1d' },
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(withAuthenticator(MiniDrawer, false, [], null, MyTheme))));