import React from 'react';
import ReactNotifications from 'react-browser-notifications';
import icon from '../Assets/Logos/pci-icon.png';
 
export default class Notifcations extends React.Component {
  constructor() {
    super();
    this.showNotifications = this.showNotifications.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
 
  componentDidMount(){
    this.showNotifications()
  }
  showNotifications() {
    // If the Notifications API is supported by the browser then show the notification
    if(this.n.supported()) this.n.show();
  }
 
  handleClick(event) {
    // Do something here such as
    // console.log("Notification Clicked") OR
    // window.focus() OR
    // window.open("http://www.google.com")
 
    // Lastly, Close the notification
    this.n.close(event.target.tag);
  }
 
  render() {
    return (
      <div>
        <ReactNotifications
          onRef={ref => (this.n = ref)} // Required
          title={this.props.title ? this.props.title : "Hello from Planet Connect"} // Required
          body={this.props.body? this.props.body: "Hello from Planet Connect"}
          icon={icon}
          tag="abcdef"
          timeout="5000"
          onClick={event => this.handleClick(event)}
        />
      </div>
    )
  }
}