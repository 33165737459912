import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Img } from 'react-image';

import Dropzone from 'react-dropzone-uploader';

const useStyles = makeStyles((theme) => ({
    dropZone: {
        overflow: 'auto',
        padding: 10,
        border: '1px dotted grey'
    },
}));

export function FileUploader(props) {
    const classes = useStyles();

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    // const handleSubmit = (files, allFiles) => {
    //     console.log(files.map(f => f.meta))
    //     allFiles.forEach(f => f.remove())
    // }

    const handleChangeStatus = ({ meta, file }, status) => { 
        if(status === 'done'){
            props.handleFileUpload(file, meta, status)
        }
    }


    // const handleChangeStatus = (event) => { 
    //     props.handleFileUpload(event.target.files[0])
    // }

    return (
        <div>
            {/* <input type="file" name="file" onChange={handleChangeStatus}/> */}
            <Dropzone
                accept="image/*, .pdf"
                addClassNames={{ dropzone: classes.dropZone }}
                getUploadParams={getUploadParams}
                maxFiles={1}
                // onSubmit={handleSubmit}
                multiple={false}
                onChangeStatus={handleChangeStatus}
                PreviewComponent={Preview}
                inputContent="Drag and Drop File"
                // submitButtonContent='Upload'
                disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
            />
        </div>
    );
}

const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta

    return (
        <React.Fragment>
            <Typography gutterBottom variant="body1" component="h3">
                {name}
            </Typography>
            <Typography gutterBottom variant="body1" component="h3">
                {Math.round(percent)}% - {status}
            </Typography>
            <Img
                src={[previewUrl]}
                width="100%"
            />
        </React.Fragment>
    )
}