import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import WorkIcon from "@material-ui/icons/Work";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import trackAction from "../../Utilities/tracker";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import {SHARE_SITES} from 'react-add-to-calendar-hoc';
import DialogWrapperAgenda from '../../Components/DialogWrapperAgenda';
import ButtonWrapper from '../../Components/ButtonWrapper';
import getValidUrl from '../../Utilities/getValidUrl'
import { posterSessionTopicImages } from '../../config';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import addBriefcaseElement from "../../Store/Actions/addBriefcaseElement";
import deleteBriefcaseElement from "../../Store/Actions/deleteBriefcaseElement";

const useStyles = (theme) => ({
  rowEventRoot: {
    cursor: "pointer",
    display: "flex",
    marginTop: theme.spacing(2),
  },
  media: {
    maxWidth: "80%",
    padding: theme.spacing(2),
  },
  centerColumn: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  time: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      0
    )}px ${theme.spacing(0)}px`,
    fontWeight: "bold",
  },
  titleTrack: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    fontSize: 10,
  },
  track: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    borderRadius: "5px 0px 15px 0px",
    opacity: 0.85,
  },
  linePhoto: {
    borderRadius: "50%",
    width: "5rem",
  },
  lineTrack: {
    fontSize: "0.8rem",
    fontWeight: "italic",
    textAlign: "left",
    paddingTop: theme.spacing(2),
  },
  lineTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "left",
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: "1rem",
    textAlign: "left",
  },
  lineSpeakerName: {
    fontWeight: "bold",
    textAlign: "left",
  },
  lineSpeakerTitle: {
    fontSize: "1rem",
    textAlign: "left",
  },
  lineSpeakerCompany: {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "left",
  },
  linkStyles: {
    textDecoration: "none",
    display: "block",
    textAlign: "center",
    padding: theme.spacing(1),
    color: "black",
    fontSize: "1.25em",
  },
  calendarContainer: {
    height: "100%",
  },
  calendarButton: {
    height: "100%",
    borderRadius: "15px 0px 5px 0px",
  },
  access: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
  aboutMeBox: {
    textAlign: "center",
    "& .MuiTypography-root": {
      textAlign: "center",
    },
  },
});

class AgendaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      buttonHover: false,
      itemDialogOpen: false,
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  navigate() {
    switch (this.props.abstract.accepted_type) {
      case "Discussion Forum":
        this.props.history.push(
          `/discussionforum?presentation_id=${this.props.abstract.abstract_id}`
        );
        break;
      case "ExhibitHall":
        this.props.history.push(`/exhibithall`);
        break;
      case "PosterHall":
        this.props.history.push(`/posterhall`);
        break;
      default:
        if (this.props.abstract.archive_link) {
          const event = {
            action: "Clicked",
            resource_type: "Archive",
            resource_id: this.props.abstract.abstract_id,
            url_visited: "/agenda",
            // user_account_id: this.props.user.user.account_id,
            // new fields
            resource_account_id: this.props.abstract.account_id,
            resource_account_name: this.props.abstract.account_name,
            resource_name: this.props.abstract.title,
            //old fields
            // user_account_name: this.props.user.user.account_name,
            // user_email: this.props.user.user.email,
            // user_first_name: this.props.user.user.first_name,
            // user_last_name: this.props.user.user.last_name,
            // user_title: this.props.user.user.title,
            // user_department: this.props.user.user.department,
            // user_site: this.props.user.user.site,
          };

          trackAction(event);

          window.open(this.props.abstract.archive_link, "_blank");
        } else {
          if (this.props.abstract.presentation_link) {
            const event = {
              action: "Clicked",
              resource_type: "Presentation",
              resource_id: this.props.abstract.abstract_id,
              url_visited: "/agenda",
              // user_account_id: this.props.user.user.account_id,
              // new fields
              resource_account_id: this.props.abstract.account_id,
              resource_account_name: this.props.abstract.account_name,
              resource_name: this.props.abstract.title,
              //old fields
              // user_account_name: this.props.user.user.account_name,
              // user_email: this.props.user.user.email,
              // user_first_name: this.props.user.user.first_name,
              // user_last_name: this.props.user.user.last_name,
              // user_title: this.props.user.user.title,
              // user_department: this.props.user.user.department,
              // user_site: this.props.user.user.site,
            };

            trackAction(event);

            window.open(this.props.abstract.presentation_link, "_blank");
          }
        }
    }
  }

  navigateAbstract(id) {
    window.open(
      `https://www.companiondiagnosticsforum.com/abstract-view/?abstract_id=${id}`,
      "_blank"
    );
  }

  navigateBio(site) {
    window.open(`${site}`, "_blank");
  }

  render() {
    const { classes } = this.props;

    let AddToCalendarButton = AddToCalendarHOC(
      ButtonWrapper,
      DialogWrapperAgenda
    );

    let favorited = false;

    let fallbackProfileImage = (
      <PersonIcon
        className={classes.linePhoto}
        style={{ width: "3rem", height: "3rem", background: "#CCCCCC" }}
      />
    );

    let characterCount = 0;
    let splitTitle = this.props.abstract.content.split(" ");
    let modifiedContent = "";

    splitTitle.map((string) => {
      characterCount = characterCount + string.length;

      if (characterCount <= 120) {
        modifiedContent = modifiedContent + " " + string;
      }

      return string;
    });

    if (characterCount > 65) {
      modifiedContent = modifiedContent + "...";
    }

    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.props.abstract.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }
    return (
      <Paper elevation={this.state.isHovering ? 8 : 1}>
        <Grid
          container
          className={classes.rowEventRoot}
          onMouseEnter={() => {
            this.handleChange(true, "isHovering");
            this.handleChange(false, "buttonHover");
          }}
          onMouseLeave={() => this.handleChange(false, "isHovering")}
          onClick={this.state.buttonHover ? null : () => this.navigate()} //DISABLENAVIGATE
        >
          <Grid item xs={2} align="center">
            <CardMedia
              className={classes.cardMedia}
              image={
                this.props.roomImage
                  ? this.props.roomImage
                  : posterSessionTopicImages["Default"]
              }
            />
            <Typography className={classes.access}>
              {this.props.abstract.cleared_for_public_view === "Yes - Approved"
                ? "Access: Public"
                : "Access: Private"}
            </Typography>
            {this.props.abstract.presenter_linked_in_url ? 
            <Button color="primary" 
            onClick={()=> this.navigateBio(getValidUrl(this.props.abstract.presenter_linked_in_url))}
            startIcon={<LinkedInIcon />}
            onMouseEnter={() => this.handleChange(true, "buttonHover")}
            onMouseLeave={() => this.handleChange(false, "buttonHover")}
            >
                </Button> : 
            <React.Fragment/>}
            {favorited ? (
              <IconButton
                aria-label="remove from briefcase"
                onClick={() =>
                  this.props.deleteBriefcaseElement(
                    this.props.abstract.abstract_id
                  )
                }
                onMouseEnter={() => this.handleChange(true, "buttonHover")}
                onMouseLeave={() => this.handleChange(false, "buttonHover")}
              >
                <WorkIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="add to briefcase"
                onClick={() =>
                  this.props.addBriefcaseElement(
                    "Presentation",
                    this.props.abstract.abstract_id,
                    this.props.abstract.title
                  )
                }
                onMouseEnter={() => this.handleChange(true, "buttonHover")}
                onMouseLeave={() => this.handleChange(false, "buttonHover")}
              >
                <WorkOutlineIcon />
              </IconButton>
            )}
            <br />
          </Grid>

          <Grid container item xs={8} className={classes.centerColumn}>
            <Grid item xs={12} style={{ padding: "16px 0px 0px 0px" }}>
              {this.props.abstract.track ? (
                <Typography className={classes.lineTrack}>
                  TRACK: {this.props.abstract.track}
                </Typography>
              ) : (
                <React.Fragment />
              )}
              {this.props.abstract.title ? (
                <Typography className={classes.lineTitle}>
                  {this.props.abstract.title}
                </Typography>
              ) : (
                <React.Fragment />
              )}
            </Grid>

            <Grid className={classes.aboutMeBox} item xs={3}>
            {this.props.abstract.contacts.map((presenter, index)=>{
              

              if (presenter.role.toLowerCase() === 'presenter'){

                  return (
                    <React.Fragment key={index}>
                          {presenter.photo_url ? (
                            <img
                              className={classes.linePhoto}
                              src={getValidUrl(presenter.photo_url)}
                              alt={"Bio"}
                            />
                          ) : (
                            fallbackProfileImage
                          )}
                      <Typography className={classes.lineSpeakerName} variant='h6'>
                        {`${presenter.first_name} ${presenter.last_name}`}
                      </Typography>
                        <Typography className={classes.lineSpeakerTitle}>
                          {presenter.title ? presenter.title : ''}
                        </Typography>
                        <Typography className={classes.lineSpeakerCompany}>
                          {presenter.company ? presenter.company : ''}
                        </Typography>
                    </React.Fragment>
                  )
                }

                return null

                })
              }
               </Grid>

{/* 
            <Grid className={classes.aboutMeBox} item xs={3}>
              {this.props.abstract.presenter_photo_url ? (
                <img
                  className={classes.linePhoto}
                  src={getValidUrl(this.props.abstract.presenter_photo_url)}
                  alt={"Bio"}
                />
              ) : (
                fallbackProfileImage
              )}
              <Typography className={classes.lineSpeakerName} variant="h6">
                {this.props.abstract.presenter_first_name &&
                this.props.abstract.presenter_last_name
                  ? this.props.abstract.presenter_first_name +
                    " " +
                    this.props.abstract.presenter_last_name
                  : ""}
              </Typography>
              <Typography className={classes.lineSpeakerTitle}>
                {this.props.abstract.presenter_title
                  ? this.props.abstract.presenter_title
                  : ""}
              </Typography>
              <Typography className={classes.lineSpeakerName} variant="h6">
                {this.props.abstract.additional_presenters
                  ? "& " + this.props.abstract.additional_presenters
                  : ""}
              </Typography>
              <Typography className={classes.lineSpeakerCompany}>
                {this.props.abstract.presenter_company
                  ? this.props.abstract.presenter_company
                  : ""}
              </Typography>
            </Grid> */}






            <Grid item xs={9} style={{ padding: "0px 0px 0px 32px" }}>
              <Typography className={classes.lineSpeakerCompany}>
                Topic:{" "}
                {this.props.abstract.topic ? this.props.abstract.topic : ""}
              </Typography>
              <Typography className={classes.lineSpeakerTitle}>
                {this.props.abstract.content ? modifiedContent : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              direction="column"
              justify="flex-end"
              style={{ height: "100%" }}
            >
              <Grid item style={{ flexGrow: 10 }}>
                <Typography
                  className={classes.time}
                  variant="h5"
                  align="right"
                  color="textPrimary"
                >
                  {moment(this.props.abstract.start_time, "HH:mm:ss").format(
                    "h:mm a"
                  )}
                </Typography>
                <Typography
                  className={classes.time}
                  variant="h6"
                  align="right"
                  color="textSecondary"
                  style={{ paddingTop: "0px" }}
                >
                  -{" "}
                  {moment(this.props.abstract.end_time, "HH:mm:ss").format(
                    "h:mm a"
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                align="right"
                style={{ flexGrow: 1 }}
                onMouseEnter={() => this.handleChange(true, "buttonHover")}
                onMouseLeave={() => this.handleChange(false, "buttonHover")}
              >
                <AddToCalendarButton
                  className={classes.calendarContainer}
                  event={this.props.calendarAbstract}
                  items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.OUTLOOK]}
                  dropdownProps={{
                    onMouseLeave: () => this.handleChange(false, "buttonHover"),
                    header: (
                      <Typography
                        className={classes.linkStyles}
                        variant="h6"
                        color="textPrimary"
                      >
                        Select an option
                      </Typography>
                    ),
                    footer: (
                      <Button variant="contained" color="secondary" fullWidth>
                        Close
                      </Button>
                    ),
                  }}
                  buttonProps={{
                    variant: "contained",
                    size: "large",
                    color: "primary",
                    className: classes.calendarButton,
                    startIcon: <AddIcon />,
                  }}
                  linkProps={{ className: classes.linkStyles }}
                ></AddToCalendarButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(AgendaCard)));
