import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import NavBar from '../../Components/Navbar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import setAuthenticatedUser from '../../Store/Actions/setUser'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import enterListener from '../../Utilities/listeners';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '80%'
    },
    formControlButton: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '50%'
    },
    button: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
          },
    }
});


class SignInForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            newPassword: '',
            signedIn: false,
            error: false,
            errorMessage: '',
            newPasswordRequired: false,
            formSubmitted: false
        }
    }

    handleChange(event, inputIdentifier) {
        this.setState({ [inputIdentifier]: event.target.value });
    }

    listener = event => {
        enterListener(event, this.signIn);
      };

    componentDidMount() {
        document.addEventListener("keydown", this.listener);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.listener);
    }
    
    signIn = () => {

        this.setState({
            formSubmitted: true
        })

        Auth.signIn(
            this.state.username,
            this.state.password
        ).then((response) => {
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.setState({
                    newPasswordRequired: true,
                    formSubmitted: false
                })
            } else {
                    this.props.setAuthenticatedUser()
                    this.props.history.push(`/lobby`);
            }
        }).catch((error) => {
            console.log(error)

            if(error.code === 'UserNotConfirmedException'){
                this.setState({
                    error: true,
                    errorMessage: 'Please check your email for a confirmation link. If you have not recieved one, please check spam or contact support.',
                    formSubmitted: false
                })
            } else {
                this.setState({
                    error: true,
                    errorMessage: 'Incorrect password. If you forgot your pasword, you may reset it below be clicking on the "Reset Password" button.',
                    formSubmitted: false
                })
            }

        })
    }

    setPermanentPassword = () => {

        this.setState({
            formSubmitted: true
        })

        Auth.signIn(this.state.username, this.state.password)
            .then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    Auth.completeNewPassword(
                        user,
                        this.state.newPassword,
                    ).then(user => {
                        this.setState({
                            formSubmitted: false
                        })
                        this.props.setAuthenticatedUser()
                        this.props.history.push(`/lobby`);
                    }).catch(e => {
                        this.setState({
                            error: true,
                            errorMessage: 'Incorrect Username and Password',
                            formSubmitted: false
                        })
                    });
                } else {
                    this.props.setAuthenticatedUser()
                    this.props.history.push(`/lobby`);
                }
            }).catch(e => {
                this.setState({
                    error: true,
                    errorMessage: 'Incorrect Username and Password',
                    formSubmitted: false
                })
            });
    }

    render() {
        const { classes } = this.props;

        if (this.state.newPasswordRequired) {
            return (
                <div>
                    <NavBar />
                    <div className={classes.grid} >
                        <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h5">
                                        Create Permanent Password
                            </Typography>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField onChange={(event) => this.handleChange(event, "username")}
                                            value={this.state.username}
                                            label="Username"
                                            error={this.state.error} />
                                    </FormControl>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField onChange={(event) => this.handleChange(event, "newPassword")}
                                            type='password'
                                            value={this.state.newPassword}
                                            label="Password"
                                            error={this.state.error}
                                            helperText={this.state.errorMessage} />
                                    </FormControl>
                                    <FormControl className={classes.formControlButton}>
                                        <Button variant="contained" onClick={this.setPermanentPassword} className={classes.button}>
                                            {this.state.formSubmitted ? <CircularProgress color="inherit" /> : <div>Submit</div>}
                                        </Button>
                                    </FormControl>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }

        if (this.state.signedIn) {
            return <Redirect to={'lobby'} />
        }

        return (
            <div>
                <NavBar />
                <div className={classes.grid}>
                    <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Paper className={classes.paper}>
                                <Typography variant="h5">
                                    Log In
                            </Typography>
                                <FormControl className={classes.formControl} fullWidth>
                                    <TextField onChange={(event) => this.handleChange(event, "username")}
                                        value={this.state.username}
                                        label="Email"
                                        error={this.state.error} />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth>
                                    <TextField onChange={(event) => this.handleChange(event, "password")}
                                        type='password'
                                        value={this.state.password}
                                        label="Password"
                                        error={this.state.error}
                                        helperText={this.state.errorMessage} />
                                </FormControl>
                                <FormControl className={classes.formControlButton}>
                                    <Button variant="contained" onClick={this.signIn} className={classes.button}>
                                        {this.state.formSubmitted ? <CircularProgress color="inherit" /> : <div>Submit</div>}
                                    </Button>
                                </FormControl>
                                <FormControl className={classes.formControlButton} >
                                    <Button color="inherit" component={Link} to={'/forgotpassword'}>Reset Password</Button>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setAuthenticatedUser: setAuthenticatedUser,
    }, dispatch);
  }

export default connect(null, mapDispatchToProps)(withRouter(withStyles(useStyles)(SignInForm)));