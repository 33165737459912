import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { withAuthenticator } from 'aws-amplify-react';
import { withRouter } from 'react-router';
import IndividualChat from './IndividualChat';
import List from '@material-ui/core/List';
import Badge from '@material-ui/core/Badge';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SelectChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
        anchor: null
    };
}

  handleClick = (event) => {
      this.setState({
          anchor:event.currentTarget
      })
  };

  handleClose = () => {
    this.setState({
        anchor: null
    })
  };

  render(){

  const unreadMessages = this.props.chat.memberships.map((e)=> e.newMessage).reduce((a, b) => a + b )

  let chatLabel = 'Messages'

  return (
    <div>
      {unreadMessages ?
      <Badge badgeContent={unreadMessages} color="secondary">
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=> this.handleClick(e)} style={{color: 'white', marginLeft: "36px"}}>
          {chatLabel}
        </Button>
      </Badge>
      :
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=> this.handleClick(e)} style={{color: 'white', marginLeft: "36px"}}>
        {chatLabel}
      </Button>}
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchor}
        keepMounted
        open={Boolean(this.state.anchor)}
        onClose={(e)=>this.handleClose(e)}
      >
            <List onClick={(e)=>this.handleClose(e)}>        
            {this.props.chat.memberships.map((membership) => {
                return (
                <IndividualChat
                    key = {membership.channel.id}
                    membership = {membership}
                />
                )
            })}
            </List>
      </Menu>
    </div>
  );
}
}

const MyTheme = {
    button: { backgroundColor: '#6f1a1d' },
};
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
  }

function mapStateToProps(state) {
    return {
        user: state.user,
        sidebar: state.sidebar,
        chat: state.chat,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withAuthenticator(SelectChat, false, [], null, MyTheme)));