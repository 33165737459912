import React, { Component } from 'react';
import axios from 'axios';
import { withAuthenticator } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Auth } from 'aws-amplify';
import ImageMapper from 'react-image-mapper';
import { connect } from 'react-redux';
import AuditoriumImage from '../../Assets/OPRS-Atlantic_v2.jpg';
import Dialog from '../../Components/DialogWrapper';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import trackAction from '../../Utilities/tracker';
import moment from 'moment';

// table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

// config
import { drawerWidth, eventId } from '../../config';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  imageMapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  gridContainer: {
    margin: '0px -24px -24px -24px',
  },
  table: {
    // minWidth: 650,
  },
  header: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'fixed',
    zIndex: '2',
    paddingRight: theme.spacing(5),
  },
  title: {
    marginTop: theme.spacing(-1),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    transform: 'translate(-68%, 0)',
    marginLeft: '50%',
    position: 'fixed',
    zIndex: '2',
    backgroundColor: '#efefef',
    borderRadius: '8px'
  },
  backButton: {
    position: 'relative',
    padding: theme.spacing(1),
  },
  preSelectedRow: {
    background: '#6f1a1d33',
  },
});

class Auditorium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webinars: [],
      imageWidth: window.innerWidth * 0.955,
      modalOpen: false,
      upcomingWebinar: false,
      archivedWebinar: false,
      roomName: null,
      preSelectedPresentation: null,
    };
  }

  MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'ArchivedWebinars',
        shape: 'poly',
        coords: [
          1300, 686, //bottom left
          1300, 514, //top left
          1015, 490, //top right
          1015, 690, //bottom right
        ],
        strokeColor: '',
        lineWidth: '4',
        href: '/auditorium',
      },
      {
        name: 'UpcomingWebinars',
        shape: 'poly',
        coords: [
          630, 690, //bottom right
          630, 466, //top right
          115, 425, //top left
          115, 694, //bottom left
        ],
        strokeColor: '',
        lineWidth: '6',
        href: '/auditorium',
      },
    ],
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateCoordinates);

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
            filter_column: 'room',
            filter_value: this.props.roomName,
          },
        }).then((response) => {
          if (this.props.location.search) {
            const query = new URLSearchParams(this.props.location.search);
            for (let param of query.entries()) {
              if (param[0] === 'presentation_id') {
                this.setState({
                  preSelectedPresentation: param[1],
                });
              }
            }
          }
          
          let authorizedPresentations = [];
          
          for (let x = 0; x < response.data.data.length; x++) {
            let presentation = response.data.data[x];
            presentation.date = moment(presentation.start_time).format("YYYY-MM-DD");

              if (presentation.start_time && presentation.accepted_type !== 'Workshop') {
                authorizedPresentations.push(presentation);
              }

          }


          // Sort by month, sort by day, sort by time
          authorizedPresentations = authorizedPresentations.sort((a, b) => moment(a.date).format('MM') - moment(b.date).format('MM')) // by month
          .sort((a, b) => moment(a.date).format('DD') - moment(b.date).format('DD')) // by day
          .sort((a, b) => {
            if (a.date === b.date) {
              return moment.duration(a.start_time).asMinutes() - moment.duration(b.start_time).asMinutes()
            } else {
              return 1
            }
          }); // by time
          for (let presentation of authorizedPresentations) {
            if (presentation.abstract_id === this.state.preSelectedPresentation) {
              let date = presentation.date.split("T")[0];
              let time = presentation.end_time;
              let timeNow = moment().format('HH:mm:ss');
              let dateNow = moment().format("YYYY-MM-DD");


              switch (true) {
                case (date < dateNow): 
                // date is in the past
                  this.setState({
                    modalOpen: true,
                    upcomingWebinar: false,
                    archivedWebinar: true,
                  });
                  break;
                case (date === dateNow):
                  // date is today
                  if (time < timeNow) {
                    // time is in the past
                    this.setState({
                      modalOpen: true,
                      upcomingWebinar: false,
                      archivedWebinar: true,
                    });
                  } else {
                    // time is in the future
                    this.setState({
                      modalOpen: true,
                      upcomingWebinar: true,
                      archivedWebinar: false,
                    });
                  }
                  break;
                default:
                  // time is in the future
                  this.setState({
                    modalOpen: true,
                    upcomingWebinar: true,
                    archivedWebinar: false,
                  });
                  break;
              }

            }
          }
          // Authorization

          if (!this.props.user.user.roles.includes("Pharma Attendee") && !this.props.user.user.roles.includes("Attendee")){
            authorizedPresentations = authorizedPresentations.filter((presentation) => presentation.account_id === this.props.user.user.account_id || presentation.cleared_for_public_view === "Yes - Approved")
          }

          this.setState({
            webinars: authorizedPresentations,
          });
        },
          (error) => {
            this.setState({
              webinars: [],
            });
            if (this.props.debug.debug) {
              alert(`Error: Could not display Auditorium. ${error}`)
            }
          }
        )
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Auditorium. ${error}`)
            }
          });
      })
      .catch((error) => {
        this.setState({
          webinars: [],
        });
        if (this.props.debug.debug) {
          alert(`Error: Could not display Auditorium. ${error}`)
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
          if (param[1] !== prevState.preSelectedPresentation) {
          if (param[0] === 'presentation_id') {
            this.setState({
              preSelectedPresentation: param[1],
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCoordinates);
  }

  updateCoordinates = () => {
    let width = window.innerWidth * 0.955;

    this.setState({
      imageWidth: width,
    });
  };

  imageClick(event) {
    if (event.name === 'UpcomingWebinars') {
      this.setState({
        modalOpen: true,
        upcomingWebinar: true,
      });
    } else {
      this.setState({
        modalOpen: true,
        archivedWebinar: true,
      });
    }
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      upcomingWebinar: false,
      archivedWebinar: false,
      preSelectedPresentation: null,
    });
  }

  

  viewPresentation(webinar) {

    const event = {
      action: "Clicked",
      resource_type: "Presentation",
      resource_id: webinar.abstract_id,
      url_visited: '/auditorium',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: webinar.account_id,
      resource_account_name: webinar.account_name,
      resource_name: webinar.title,
      //old fields
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    }

      trackAction(
        event
        )

    if (webinar.presentation_link) {
    window.open(webinar.presentation_link, '_blank');
  }
  }


  viewArchive(webinar) {

    const event = {
      action: "Clicked",
      resource_type: "Archive",
      resource_id: webinar.abstract_id,
      url_visited: '/auditorium',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: webinar.account_id,
      resource_account_name: webinar.account_name,
      resource_name: webinar.title,
      //old fields
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    }

    trackAction(
      event
      )
      
  if (webinar.archive_link) {
  window.open(webinar.archive_link, '_blank');
}
}

  render() {
    const { classes } = this.props;

    let pageWidth = !this.props.sidebar.open ? this.state.imageWidth : window.innerWidth - drawerWidth;

    let webinars;
    if (this.state.modalOpen) {
      if (this.state.archivedWebinar) {
        webinars = (
            <React.Fragment>
              <Typography variant='h4' gutterBottom align='center'>
                Recorded Presentations
              </Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Title</TableCell>
                      <TableCell align='center'>Time</TableCell>
                      <TableCell align='center'>Date</TableCell>
                      <TableCell align='center'>Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.webinars
                      .filter((webinar) => webinar.room === this.props.roomName)
                      .map((webinar, index) => {
                        let date = webinar.date.split("T")[0];
                        let time = webinar.end_time;
                        let timeNow = moment().format('HH:mm:ss');
                        let dateNow = moment().format("YYYY-MM-DD");
                        switch (true) {
                          case (date > dateNow):
                            return null;
                          case (date === dateNow && timeNow <= time):
                            return null;
                          default:
                            return (
                              <React.Fragment key={index}>
                                <TableRow className={this.state.preSelectedPresentation === webinar.abstract_id ? classes.preSelectedRow : ''}>
                                  <TableCell align='left' style={{ maxWidth: '40vw' }}>
                                    <b>Title: </b>
                                    {webinar.title}
                                    <br /><br />
                                    {webinar.presenter_first_name ? <React.Fragment><b>Presenter: </b>{webinar.presenter_first_name + ' ' + webinar.presenter_last_name + ' - ' + webinar.presenter_title}</React.Fragment> : <React.Fragment></React.Fragment>}
                                    <br />
                                    {webinar.account_name ? <React.Fragment><b>Company: </b>{webinar.account_name} </React.Fragment> : <React.Fragment></React.Fragment>}
                                    <br />
                                    {webinar.cleared_for_public_view === "Yes - Approved" ? <React.Fragment><b>Visibility: </b>Public</React.Fragment> : <React.Fragment><b>Visibility: </b>Private</React.Fragment>}
                                  </TableCell>
                                  <TableCell align='center'>
                                    {webinar.start_time ? moment(webinar.start_time).format('h:mm a') : <React.Fragment></React.Fragment>}
                                  </TableCell>
                                  <TableCell align='center'>
                                    {webinar.date ? moment(webinar.date).format('dddd, MMMM Do') : <React.Fragment></React.Fragment>}
                                  </TableCell>
                                  <TableCell align='center'>
                                    {webinar.archive_link ?
                                      <Button
                                        variant='outlined'
                                        color='primary'
                                        target='#blank'
                                        onClick={() =>
                                          this.viewArchive(webinar)
                                        }
                                      >
                                        View Replay
                                </Button>
                                      :
                                      <Button
                                        variant='outlined'
                                        color='primary'
                                        disabled
                                      >
                                        Coming Soon
                              </Button>
                                    }
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                        }
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
        );
      } else {
        webinars = (
          <React.Fragment>
            <Typography variant='h4' gutterBottom align='center'>
              Upcoming Presentations
            </Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Title</TableCell>
                    <TableCell align='center'>Time</TableCell>
                    <TableCell align='center'>Date</TableCell>
                    <TableCell align='center'>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.webinars
                    .filter((webinar) => webinar.room === this.props.roomName)
                    .map((webinar, index) => {
                      let date = webinar.date.split("T")[0];
                      let time = webinar.end_time;
                      let timeNow = moment().format('HH:mm:ss');
                      let dateNow = moment().format("YYYY-MM-DD");
                      // let dateNow = '2020-10-25'; // testing purposes 
                      switch (true) {
                        case (date < dateNow):
                          return null;
                        // break;
                        case (date === dateNow && timeNow >= time):
                          return null;
                        // break;
                        default:
                          return (
                            <React.Fragment key={index}>
                              <TableRow className={this.state.preSelectedPresentation === webinar.abstract_id ? classes.preSelectedRow : ''}>
                                <TableCell align='left' style={{ maxWidth: '40vw' }}>
                                  <b>Title: </b>
                                  {webinar.title}
                                  <br /><br />
                                  {webinar.presenter_first_name ? <React.Fragment><b>Presenter: </b>{webinar.presenter_first_name + ' ' + webinar.presenter_last_name + ' - ' + webinar.presenter_title}</React.Fragment> : <React.Fragment></React.Fragment>}
                                  <br />
                                  {webinar.account_name ? <React.Fragment><b>Company: </b>{webinar.account_name} </React.Fragment> : <React.Fragment></React.Fragment>}
                                  <br />
                                  {webinar.cleared_for_public_view === "Yes - Approved" ? <React.Fragment><b>Visibility: </b>Public</React.Fragment> : <React.Fragment><b>Visibility: </b>Private</React.Fragment>}
                                </TableCell>
                                <TableCell align='center'>
                                  {webinar.start_time ? moment(webinar.start_time).format('h:mm a') : <React.Fragment></React.Fragment>}
                                </TableCell>
                                <TableCell align='center'>
                                  {webinar.date ? moment(webinar.date).format('dddd, MMMM Do') : <React.Fragment></React.Fragment>}
                                </TableCell>

                                <TableCell align='center'>
                                  <Button
                                    variant='outlined'
                                    color='primary'
                                    target='#blank'
                                    onClick={() =>
                                      this.viewPresentation(webinar)
                                    }
                                  >
                                    Launch
                              </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        );
      }
    }

    let auditoriumHeader = (
      // <React.Fragment>
      <Grid item container xs={12} >
        <Grid item xs={2} align='left' className={classes.header}>
          <Button
            variant='contained'
            color='default'
            onClick={() => this.props.closeAuditorium()}
            className={classes.backButton}
          >
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid item xs={8} className={classes.title}>
          <Typography variant='h4'>
            {this.props.roomName}
          </Typography>
        </Grid>
        <Grid item xs={2} />
        </Grid>
      // </React.Fragment>
    );
    return (
        <Grid
          container
          className={classes.gridContainer}
          align='center'
          justify='center'
        >
            {auditoriumHeader}
          <Grid item className={classes.imageMapper} xs={12}>
            <Dialog
              open={this.state.modalOpen}
              handleClose={this.handleClose.bind(this)}
              scroll='body'
            >
              {webinars}
            </Dialog>
            <ImageMapper
              width={pageWidth}
              imgWidth={2000}
              src={AuditoriumImage}
              map={this.MAP}
              onClick={(event) => this.imageClick(event)}
            />
          </Grid>
        </Grid>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: '#6f1a1d' },
};

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar,
    user: state.user,
    debug: state.debug,
  };
}

export default connect(mapStateToProps,null)(withStyles(useStyles)(withRouter(withAuthenticator(Auditorium, false, [], null, MyTheme))));
