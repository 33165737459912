import { SET_BRIEFCASE, REMOVE_BRIEFCASE, ADD_BRIEFCASE_ELEMENT, DELETE_BRIEFCASE_ELEMENT } from '../Actions/types';

const initialState = {
  briefcase: []
};

export default function(state=initialState, action) {
  switch (action.type) {
    case SET_BRIEFCASE:
        return {
          ...state,
          briefcase: action.payload
      };
    case ADD_BRIEFCASE_ELEMENT:
    return {
      ...state,
      briefcase: [
        ...state.briefcase,
        action.payload
      ]
    }
    case DELETE_BRIEFCASE_ELEMENT:
      return{
        ...state,
        briefcase: state.briefcase.filter(element => {
          return element.resource_id !== action.payload}),
      }
    case REMOVE_BRIEFCASE:
      return{
        ...state,
        briefcase: []
      }
    default:
      return state;
  }
}