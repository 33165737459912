import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import trackAction from '../../Utilities/tracker';

import { connect } from 'react-redux';

const useStyles = (theme) => ({
  presenterImage: {
    padding: theme.spacing(6),
    display: 'block',
    borderRadius: '35px',
    maxHeight: '240px',
    margin: 'auto',
  },
  exitTab: {
    color: '#b30000CC',
  },
  paperTabs: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  underTabsContainer: {
    height: '100%',
    padding: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  iFrameContainer: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    objectFit: 'contain',
  },
  image: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    height: '85vh',
  },
  description: {
    paddingTop: theme.spacing(2),
  },
  body: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  presenterBio: {
    overflow: 'hidden',
    positon: 'relative',
  },
});

class ArtFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      art: null,
    };
  }
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  recordEvent = (data) => {

    const event = {
      action: "Clicked",
      resource_type: "Art",
      resource_id: data.abstract_id,
      url_visited: '/art',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: data.account.account_id,
      resource_account_name: data.account.name,
      resource_name: data.title,
      //old fields
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    }

    trackAction(
      event
      )
  }

  componentDidMount() {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: '/arts/' + this.props.abstractId,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
        })
          .then((response) => {
            this.setState({
              art: response.data
            })

            this.recordEvent(response.data)

          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display this image from Science as Art. ${error}`)
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display this image from Science as Art. ${error}`)
        }
      });
  }

  shouldComponentUpdate(nextProps, nextState){

    if (this.props.timestamp === nextProps.timestamp){
      return true
    } else {
      this.setState({
        art: null,
      })
      return false
    }
  }

  render() {
    const { classes } = this.props;

    let artContent;

    if (this.state.art){
    switch (this.state.tabValue) {
      case 0:
        artContent = (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography className={classes.title} variant='h5' color='textPrimary'>
                {this.state.art.title}
              </Typography>
              <Divider variant="fullWidth" />
            </Grid>
            <Grid item className={classes.iFrameContainer} xs={12}>
                <img className={classes.image} src = {this.state.art.supporting_file_url} alt="art"/>
            </Grid>
            <Grid item xs={12}>
            <Divider variant="fullWidth" />
            <Typography className={classes.description} variant='subtitle1' color='textPrimary'>
               {this.state.art.content}
              </Typography>
            </Grid>
          </React.Fragment>
        );
        break;
      case 1:
        artContent = (
          <React.Fragment>
              <Grid item xs={12}>
                {this.state.art.presenters.map((presenter, index)=>{
                  return (
                    <React.Fragment key={index}>
                      <Typography className={classes.title} variant='h4' color='textPrimary'>
                      {`${presenter.first_name} ${presenter.last_name}`}
                      </Typography>
                      <Divider className={classes.divider} variant="fullWidth" />
                      <Typography variant='h6'>
                        {`${presenter.title} at ${presenter.company}`}
                      </Typography>
                      <Divider className={classes.divider} variant="fullWidth" />
                      <Typography variant='body1'>
                        <b>Email:</b> {presenter.email}
                      </Typography>
                      <Typography variant='body1'>
                        {presenter.bio ? 
                        <React.Fragment><Divider className={classes.divider} variant="fullWidth" /><b>Bio:</b> {presenter.bio}</React.Fragment> : 
                        <React.Fragment></React.Fragment>}
                      </Typography>
                    </React.Fragment>
                    )
                  })}
              </Grid>
          </React.Fragment>
        );
        break;
      default:
        artContent = <div>No Content</div>;
        break;
    }
  } else {
    artContent = <div></div>;
  }

    return (
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        spacing={2}
      >
        <Grid item xs={12}>
          <Paper className={classes.paperTabs}>
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              <Tab label={'Art Installation'} />
              <Tab label={'Artist'} />
              <Tab className={classes.exitTab} label='Exit' onClick={() => this.props.toggleImageFullscreenOff()} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid
              className={classes.underTabsContainer}
              container
              justify='center'
              alignItems='center'
            >
              {artContent}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    debug: state.debug,
  }
}

export default connect(mapStateToProps, null)(withStyles(useStyles)(ArtFull));