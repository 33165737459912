import React, { Component } from 'react';
import PubNubReact from 'pubnub';
import { withAuthenticator } from 'aws-amplify-react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Auth } from 'aws-amplify';

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import selectActivePrivateChat from '../../Store/Actions/selectActivePrivateChat';
import setChatbar from '../../Store/Actions/setChatbar';
import viewPrivateChat from '../../Store/Actions/viewPrivateChat';
import { eventId } from '../../config';



const useStyles = theme => ({
    root: {
        width: '100%',
        '& .MuiTypography-root': {
            alignSelf: 'center',
        },
        '& .MuiChip-root	': {
            alignSelf: 'center',
        },
        '& .MuiAccordionSummary-content': {
            margin: theme.spacing(0.5),
        },
    },
    paper: {
        // padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '80%'
    },
    formControlButton: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '50%'
    },
    button: {
        color: "white",
        backgroundColor: theme.palette.primary.main
    },
    own: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        padding: '5px',
        marginBottom: '3px',
        borderRadius: '15px',
        maxWidth: '100%',
    },
    other: {
    },
    expansionPanelMain: { padding: '0px 8px 0px 8px' },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '60%',
        flexShrink: 0,
    },
    icon: {
        minWidth: '10em',
        maxWidth: '30em',
        maxHeight: '30em',
        width: '100%',
    },
    merck: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    }
});

class PrivateChat extends Component {
    constructor(props) {
        super(props);
        this.pubnub = new PubNubReact({
            publishKey: this.props.event.event.publish_key,
            subscribeKey: this.props.event.event.subscribe_key,
            uuid: this.props.user.user.email,
        });
        this.state = {
            member: '',
        };
    }

    componentDidMount = async () => {

        try {

            const members = await this.pubnub.objects.getChannelMembers({
                channel: this.props.membership.channel.id
            });

            const otherMember = members.data.find(member => member.uuid.id !== this.props.user.user.email)

            const currentUser = await Auth.currentSession()


            const chatMember = await axios({
                method: 'get',
                url: `/show-attendees/${eventId}/${otherMember.uuid.id}`,
                headers: { idtoken: currentUser.idToken.jwtToken },
            })

            await this.setState({
                member: chatMember.data[0]
            })

        } catch (error) {
            console.log(error)
        }
    }


    async handleClick(){
        await this.props.setChatbar(true)
        await this.props.viewPrivateChat(this.props.membership.channel.id)
        await this.props.selectActivePrivateChat(this.props.membership.channel.id, this.state.member.email)
    }

    render() {

        if(!this.state.member){
            return null
        }

        let avatar;

        if(this.state.member.roles === "Pharma Attendee"){
            if(this.state.member.photo_url){
                avatar = (
                    <ListItemIcon>
                        <Badge 
                        // badgeContent={"Merck"} 
                        color="primary"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                        <Avatar alt="private chat avatar" src={this.state.member.photo_url}>
                        </Avatar>
                        </Badge>
                    </ListItemIcon>
                )
            } else{
                avatar = (
                    <ListItemIcon>
                        <Badge 
                        // badgeContent={"Merck"} 
                        color="primary"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                        <Avatar>
                        {this.state.member.first_name.charAt(0) + this.state.member.last_name.charAt(0)}
                         </Avatar>
                         </Badge>
                    </ListItemIcon>
                )
            }
        } else{
            if(this.state.member.photo_url){
                avatar = (
                    <ListItemIcon>
                        <Avatar alt="private chat avatar" src={this.state.member.photo_url}>
                        </Avatar>
                    </ListItemIcon>
                )
            } else{
                avatar = (
                    <ListItemIcon>
                    <Avatar>
                    {this.state.member.first_name.charAt(0) + this.state.member.last_name.charAt(0)}
                     </Avatar>
                </ListItemIcon>
                )
            }
        }

        let tooltipTitle = ''


        if (this.state.member.title){
            tooltipTitle += this.state.member.title
        }

        if (this.state.member.account_name){
            tooltipTitle += ` at ${this.state.member.account_name}`
        }

        if (this.state.member.site){
            tooltipTitle += ` in ${this.state.member.site}`
        }

        return (
            <Tooltip title={tooltipTitle} placement="right">
            <ListItem button key={this.state.member.first_name} onClick={()=> this.handleClick()}>
                {avatar}
                {this.props.membership.newMessage ? 
                <Badge badgeContent={this.props.membership.newMessage} color="primary">
                    <ListItemText primary={this.state.member.first_name + " " + this.state.member.last_name} />
                </Badge>
                : <ListItemText primary={this.state.member.first_name + " " + this.state.member.last_name} />
                }
            </ListItem>
            </Tooltip>
        );
    }
}

const MyTheme = {
    button: { backgroundColor: '#6f1a1d' },
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectActivePrivateChat: selectActivePrivateChat,
        setChatbar: setChatbar,
        viewPrivateChat: viewPrivateChat,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
        chat: state.chat,
        event: state.event,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(withAuthenticator(PrivateChat, false, [], null, MyTheme))));