import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Divider, Button } from '@material-ui/core';
// import PersonIcon from "@material-ui/icons/Person";
import TextField from '@material-ui/core/TextField';
// import { eventId } from '../../config';
import { FileUploader } from '../../Utilities/fileUploader';
import Dialog from '../../Components/DialogWrapper';

// Formik
import { Formik, Form, Field, useField } from 'formik';

// redux
import { connect } from 'react-redux';


const useStyles = theme => ({
  infoField: {
    '&.MuiTextField-root': {
      margin: 0,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
  },
  error: {
    color: 'red',
    fontFamily: 'Roboto',
  },
  gridContainer: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  divider: {
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.5),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
});

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userContact: null,
      newPhoto: false,
      success: false,
    };
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentSession()
      let contact = await axios({
        method: 'get',
        url: `/contacts/id/${this.props.user.user.contact_id}`,
        headers: {
          idtoken: user.idToken.jwtToken,
        },
      })
      contact = contact.data[0]
      let baseValues = {
        department: contact.department,
        expertise_area: contact.expertise_area,
        first_name: contact.first_name,
        last_name: contact.last_name,
        linked_in_url: contact.linked_in_url,
        photo_url: contact.photo_url,
        title: contact.title,
        site: contact.site,
        bio: contact.bio,
        // values below not to be edited
        account_id: contact.account_id,
        contact_id: contact.contact_id,
        email: contact.email,
        event_administrator: contact.event_administrator,
        receive_leads: contact.receive_leads,
        mailing_city: contact.mailing_city,
        mailing_country: contact.mailing_country,
        mailing_state: contact.mailing_state,
        mailing_street: contact.mailing_street,
        mailing_zip: contact.mailing_zip,

      }
      this.setState({ userContact: contact, baseValues: baseValues })
    } catch (error) {
      console.log(error)
    }
  }

  handleChange(value, event) {
    this.setState({ value: event });
  }

  handleFileUpload = (file, meta, status) => {
    this.setState({ newPhoto: file })
    // setFieldValue('userContact.photo_url', 'test')
  }

  handleEditClose() {
    this.setState({success: false})
  }

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(this.props.event.event.available_routes).routes
    let pageTitle = ''
    allRoutes.forEach(route => {
      if (route.route === '/accountsettings') {
        pageTitle = route.title
      }
    })

    const InfoField = ({ ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <Field> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      if (!field.value) {
        field.value = '';
      }

      return (
        <>
          <Field
            // onChange={() => this.handleChange(field.name, field.value)}
            className={classes.infoField}
            component={TextField}
            margin='dense'
            variant='outlined'
            fullWidth
            // placeholder=''
            {...field} {...props}
          />
            {meta.touched && meta.error ? (
              <div className={classes.error}>{meta.error}</div>
             ) : null}
        </>
      );
    }

    let formikSubmit = async (values, setSubmitting, setFieldTouched) => {
      setSubmitting(true);

      try {

      const user = await Auth.currentSession()

      if(this.state.newPhoto){
        let formData = new FormData();
        formData.append('file', this.state.newPhoto);
        const uploadFile = await axios({
            method: "POST",
            url: '/upload',
            headers: { 
                'Content-Type': 'multipart/form-data',
                idtoken: user.idToken.jwtToken 
            },
            data: formData
        });

        values.photo_url = uploadFile.data.Location;

      }

      values.id = this.props.user.user.contact_id

      const response = await axios({
        method: 'PUT',
        url: `/zoho/contacts/id/${this.props.user.user.contact_id}`,
        headers: { idtoken: user.idToken.jwtToken },
        data: { values },
      });
      
      if(response.data === 'SUCCESS') {
        this.setState({success: true})
      } else {
        alert("Your account could not be updated")
      }

      } catch(error) {
        alert("Your account could not be updated")
        console.log(error)
      }

      setSubmitting(false);
    }

    function onKeyDown(keyEvent) {
      if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
      }
    }

    let validateURL = (url) => {
      let error;
      const urlExpression = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$' //eslint-disable-line
      let regex = new RegExp(urlExpression);
      if (url !== '') {
        if (url.match(regex)) {
          error = ''
        } else {
          error = 'Invalid URL'
        }
      }
      return error
    }

    return (
      <Grid container layout={"row"} spacing={2} >
        <Grid item xs={12}>
          <Paper>
            <Typography style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }} variant='h4' align='center' color='textPrimary'>
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {this.state.userContact ? 
          <Formik
            initialValues={this.state.baseValues}
            onSubmit={
              (values, { setSubmitting, setFieldTouched }) => {
                formikSubmit(values, setSubmitting, setFieldTouched);
                // resetForm(baseValues);
              }
            }
          >
            {({ isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit }) => (
              <Form onKeyDown={onKeyDown}>
                <Grid className={classes.gridContainer} container spacing={2}>
                    <Grid item container xs={6} justify='center' >
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          {/* <Divider className={classes.divider} /> */}
                          <Typography variant='h6' >
                            About
                          </Typography>
                          <Divider className={classes.divider} />
                          <InfoField
                            name='first_name' id='first_name'
                            label='First Name' placeholder='First Name'
                            type='text'
                          />
                          <InfoField
                            name='last_name' id='last_name'
                            label='Last Name' placeholder='Last Name'
                            type='text'
                          />
                          <InfoField
                            name='title' id='title'
                            label='Title' placeholder='Title'
                            type='text'
                          />
                          <InfoField
                            name='department' id='department'
                            label='Department' placeholder='Department'
                            type='text'
                          />
                          <InfoField
                            name='expertise_area' id='expertise_area'
                            label='Expertise Area' placeholder='Expertise Area'
                            type='text'
                          />
                          <InfoField
                            name='linked_in_url' id='linked_in_url'
                            label='LinkedIn URL' placeholder='LinkedIn URL'
                            type='text'
                            validate={validateURL}
                          />
                          <InfoField
                            name='site' id='site'
                            label='Site' placeholder='Site'
                            type='text'
                          />
                          <InfoField
                            name='bio' id='bio'
                            label='Bio' placeholder='Insert your Biography here'
                            type='text'
                            rows={5}
                            rowsMax={100}
                            multiline
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  <Grid item xs={6}>
                    {/* <Paper className={classes.paper}>
                      <Typography variant='h6' >
                          Address
                      </Typography>
                      <Divider className={classes.divider} />
                      <InfoField
                        name='mailing_street' id='mailing_street'
                        label='Street' placeholder='Street'
                        type='text'
                      />
                      <InfoField
                        name='mailing_city' id='mailing_city'
                        label='City' placeholder='City'
                        type='text'
                      />
                      <InfoField
                        name='mailing_state' id='mailing_state'
                        label='State' placeholder='State'
                        type='text'
                      />
                      <InfoField
                        name='mailing_country' id='mailing_country'
                        label='Country' placeholder='Country'
                        type='text'
                      />
                      <InfoField
                        name='mailing_zip' id='mailing_zip'
                        label='ZIP' placeholder='ZIP'
                        type='text'
                        // validate={validateEmail}
                      />
                    </Paper> */}
                    <Paper className={classes.paper} >
                      <Typography variant='h6' >
                          Current Image
                      </Typography>
                      <Divider className={classes.divider} />
                      {this.state.userContact.photo_url ?
                          <img
                          src={this.state.userContact.photo_url}
                          alt='user'
                          width="50%"
                      />
                      :
                          <React.Fragment></React.Fragment>
                      }
                      <Divider className={classes.divider} />
                      <Typography variant='subtitle1' >
                          Update Photo:
                      </Typography>
                      <FileUploader 
                        handleFileUpload = {this.handleFileUpload.bind(this)}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}><Divider className={classes.divider} /></Grid>
                  <Grid item xs={10} sm={12} >
                    <Button
                      type='submit'
                      className={classes.navButton}
                      color='primary'
                      variant='contained'
                      size='large'
                      // onClick={() => {
                      //   // if (!validateEmail(values.email)) {
                      //   //   checkEmail(values)
                      //   // }
                      // }}
                    >
                      <Typography variant='h6'>
                        Submit
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          : null
          }
        </Grid>
        <Dialog open={this.state.success} handleClose={()=> this.handleEditClose()}>
            <Typography>
                    Success!
                </Typography>
                <Button onClick={()=> this.handleEditClose()}>
                    Awesome
                </Button>
            </Dialog>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    debug: state.debug,
    event: state.event,
  }
}

const MyTheme = {
  button: { backgroundColor: "#E13E2F" },
};

export default connect(mapStateToProps, null)(withStyles(useStyles)(withAuthenticator(Settings, false, [], null, MyTheme)));

// bio
// department
// expertise_area
// first_name
// last_name
// linked_in_url
// mailing_city
// mailing_country
// mailing_state
// mailing_street
// mailing_zip
// photo_url
// title
