import { SELECT_ACTIVE_PRIVATE_CHAT } from './types';

export default function setChatbar(channel, member) {
  return dispatch => {
    dispatch(setChatbarAsync(channel, member));
  }
}

function setChatbarAsync(channel, member){
  return {
    type: SELECT_ACTIVE_PRIVATE_CHAT,
    payload: {
      channel,
      member
    }
  };
}