import React, { Component } from 'react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = theme => ({
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '80vh',
    }
})

class Account extends Component{

    render(){
      const { classes } = this.props;

        return(
            <div className={classes.center}>
                <Typography variant='h5' align='center'>
                    The event has not started yet. 
                </Typography>
            </div>
        )
    }
}

export default withStyles(useStyles)(Account);