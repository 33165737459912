import moment from 'moment';

const getTimeDifference = () => {
    let utcMoment = moment.utc();
    let utcDate = new Date( utcMoment.format() );
    // check if daylight savings is true
    let accountForDaylightSavings = moment(utcDate).isDST()
    // find offset between attendee timezone and UTC
    let currentZoneToUTCoffset = new Date().getTimezoneOffset();
    // set offset to match the timezone modifier such as -5:00 or +3:00. the negative is required.
    currentZoneToUTCoffset = -currentZoneToUTCoffset / 60
    // calculate difference between EST and attendee current timezone
    let timeDifference = -currentZoneToUTCoffset;
    if (accountForDaylightSavings) {
        // currently don't need to account for this. may need to look into it when we revert back. 
        // timeDifference = timeDifference +1;
    }
    return timeDifference
}

export default getTimeDifference