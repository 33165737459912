import React, { Component } from 'react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  headings: {
    padding: theme.spacing(3),
  },
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  buttonGridItem: {
    textAlign: 'center',
  },
  button: {
    width: '100%',
    height: '100%',
  },
});

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container layout={'row'} spacing={2} style={{ marginTop: '16px', marginBottom: '32px', }}>
        <Grid item xs={12} md={12} lg={12}>
          <Card className={classes.root}>
            <CardContent>
              <Container>
                <br />
                <Typography variant='h4' align='center' gutterBottom>
                  Our Commitment To Privacy
                </Typography>
                <Typography variant='body1' align='justify'>
                  PlanetConnect (“PCI”) respects the privacy of its website users and has created this privacy policy statement in order to demonstrate our commitment to your privacy. The following privacy policy discloses our information gathering, use and dissemination practices solely for the website located at www.planetconnect.com (the “Website”).

                  By using or accessing the Website, you agree to the terms of this Privacy Policy and you consent to the processing of information about you as described below. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY YOU MAY NOT ACCESS OR OTHERWISE USE THE WEBSITE.

                  </Typography>
                <br /><br />
                <Typography variant='h4' align='center' gutterBottom>
                  Personal and Non-Personally Identifiable Information
                </Typography>
                <Typography variant='body1' align='justify'>
                  When using the Website, you may choose to provide PCI
                  with certain personally identifiable information about yourself
                  (“Personally Identifiable Information”). Personally Identifiable Information can include your name and email address. In addition, we may also collect non-personally identifiable information, such as IP host address, pages viewed, statistics, browser type, traffic to and from the Website, standard web log information and other data. PCI may aggregate any such information collected in a manner which does not identify you or any individual (“Aggregate Information”).
                </Typography>

                <br /><br />
                <Typography variant='h4' align='center' gutterBottom>
                  Information Usage
                </Typography>
                <Typography variant='body1' align='justify'>
                  Unless disclosed otherwise at the time of collection, we do not share any Personally Identifiable Information you submit through the Website with any unaffiliated third party, unless it is necessary to respond to your inquiry, or to comply with applicable law. We may use Aggregate Information to (i) improve and enhance your experience on the Website, (ii) customize, measure and further develop our Website and/or services, (iii) use for our research activities, and (iv) tell you about our services and/or service updates. For example, we may share Aggregate Information with unaffiliated third parties, such as business partners, in an aggregate, anonymous form, which means that the shared information will not contain nor be linked to any Personally Identifiable Information.

                  We will not sell or share your Personally Identifiable Information to any third party not affiliated with PCI without your consent, except we may do so without your consent in connection with the sale or merger of PCI or the division responsible for such services.

                  Please note that we reserve the right to access and/or disclose Personally Identifiable Information and non-personal information we gather to respond to legal requirements imposed by courts or administrative agencies (for example, by court order or subpoena), and to the extent required to permit us to investigate suspected fraud, harassment or other violations of any law, rule or regulation, the Website rules or policies, the rights of third parties, or to investigate any suspected conduct which we deem improper.
                </Typography>
                <br /><br />
                <Typography variant='h4' align='center' gutterBottom>
                  Security
                </Typography>
                <Typography variant='body1' align='justify'>
                  The security of your Personally Identifiable Information is very important to us. We have put in place reasonable physical, electronic, and managerial procedures to safeguard the information we collect. The information we collect is stored on our servers located in the United States. While we treat Personally Identifiable Information as data that must be protected, and use tools (for example, encryption, passwords, physical security, etc) to safeguard such Personally Identifiable Information, we cannot state with absolute certainty that communications between you and PCI or information stored on the Website or our servers will be completely free from unauthorized access by third parties such as hackers. Please be aware that the possibility exists that third parties may unlawfully intercept or access transmissions or Personally Identifiable Information. Therefore, by using the Website, you acknowledge that the potential for unauthorized access exists, that you agree to assume this risk.
                </Typography>
                <br /><br />
                <Typography variant='h4' align='center' gutterBottom>
                  Protection for Children
                </Typography>
                <Typography variant='body1' align='justify'>
                  We have no intention of collecting Personally Identifiable Information from children under the age of 13. When we become aware that Personally Identifiable Information from a child under 13 has been collected without such child’s parent or guardian’s consent, we will use all reasonable efforts to delete such information from our database.
                </Typography>
                <br /><br />
                <Typography variant='h4' align='center' gutterBottom>
                  Changes to policy; Opt-Out Process
                </Typography>
                <Typography variant='body1' align='justify'>
                  We reserve the right, at our discretion, to change, modify, add, or remove portions from this policy at any time. However, if at any time in the future we plan to use Personally Identifiable Information in a way that differs from this policy, we will post such changes here and provide you the opportunity to opt-out of such differing uses. Your continued use of the Website following the posting of any changes to this policy means you accept such changes. We give you the option to remove your Personally Identifiable Information from any lists that we maintain of users. All unsubscribe or opt-out requests should be sent to us at privacy@planetconnect.com and we will process your request within a reasonable time after receipt. We are not responsible for removing your Personally Identifiable Information from the lists of any third party who has previously been provided your information in accordance with this policy.
                </Typography>
                <br /><br />
                <Typography variant='h4' align='center' gutterBottom>
                  Contact
                </Typography>
                <Typography variant='body1' align='justify'>

                  Contact: For questions or concerns relating to privacy, we can be contacted at: privacy@planetconnect.com
                </Typography>
              </Container>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Account);