import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import { withAuthenticator } from 'aws-amplify-react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import getTimeDifference from '../../Utilities/getTimeDifference';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import IconButton from '@material-ui/core/IconButton';
import { eventId } from '../../config';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = theme => ({
    root: {
        width: '100%',
        '& .MuiTypography-root': {
          alignSelf: 'center',
        },
        '& .MuiChip-root	': {
          alignSelf: 'center',
        },
        '& .MuiAccordionSummary-content': {
          margin: theme.spacing(0.5),
        },
      },
    grid: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    formControlButton: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '50%'
    },
    button: {
        color: "white",
        backgroundColor: theme.palette.primary.main
    },
    own: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        padding: '5px',
        marginBottom: '3px',
        borderRadius: '15px',
        maxWidth: '100%',
    },
    img:{
        borderRadius: '50%',
        maxWidth: '100%',
    },
    other: {
    },
      expansionPanelMain: {padding: '0px 8px 0px 8px'},
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '60%',
        flexShrink: 0,
      },
      icon: {
        minWidth: '10em',
        maxWidth: '30em',
        maxHeight: '30em',
        width: '100%',
      },
     merck: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
     },
     divider: {
        marginTop: theme.spacing(.5),
        marginBottom: theme.spacing(.5),
     },

lineTitle: {
  fontSize: "1.5rem",
  fontWeight: "bold",
  textAlign: "left",
  paddingBottom: theme.spacing(1),
},
lineAbstract: {
  fontSize: "1rem",
  textAlign: "left",
},
lineSpeakerName: {
  fontWeight: "bold",
  textAlign: "left",
},
lineSpeakerTitle: {
  fontSize: "1rem",
  textAlign: "left",
},
lineSpeakerCompany: {
  fontSize: "1rem",
  fontWeight: "bold",
  textAlign: "left",
},
});

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            abstracts: [],
        };
    }

    componentDidMount = async () => {

        Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `abstracts/${eventId}/${this.props.exhibit.account_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
        })
          .then((response) => {

            let timeDifference = getTimeDifference();
            
            let abstracts = [];

            for (let x = 0; x < response.data.data.length; x++) {
                let abstract = response.data.data[x];
                abstract.date = moment(abstract.date, "YYYY/MM/DD").format();
                if (abstract.start_time) {
                  let startTimeEST = abstract.start_time
                  abstract.start_time = moment(abstract.start_time, "HH:mm:ss").add(timeDifference, 'hours').format("HH:mm:ss")
                  abstract.end_time = moment(abstract.end_time, "HH:mm:ss").add(timeDifference, 'hours').format("HH:mm:ss")
                  if (parseInt(moment(startTimeEST, "HH:mm:ss").format("HH")) + timeDifference >= 24) {
                    abstract.date = moment(abstract.date, "YYYY/MM/DD").add(1, 'days').format()
                  }
                  if (parseInt(moment(startTimeEST, "HH:mm:ss").format("HH")) + timeDifference < 0) {
  
                    abstract.date = moment(abstract.date, "MM/DD/YYYY").add(-1, 'days').format()
                  }
                }
                abstracts.push(abstract);
            }

            this.setState({
                abstracts: [...this.state.abstracts, ...abstracts]
            })
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Booth Abstracts. ${error}`)
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Booth Abstracts. ${error}`)
        }
      });

    }

    navigate(route, type, resourceId){
        this.props.history.push(`/${route}?${type}=${resourceId}`);
      }

    render() {
        const { classes } = this.props;

        const abstracts = this.state.abstracts.map((abstract, index) => {
            let favorited = false;

            if (this.props.briefcase.briefcase) {
              for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
                if (
                  this.props.briefcase.briefcase[n].resource_id === abstract.abstract_id
                ) {
                  favorited = true;
                  break;
                }
              }
            }



            let listAbstract;

            switch(abstract.accepted_type){
                case "Poster":
                listAbstract = (
                  <Grid item xs={12} key={index} className={classes.grid}>
                  <Accordion
                  key={index}
                  className={classes.root}
                >
                  <AccordionSummary
                    className={classes.expansionPanelMain}
                    expandIcon={<ExpandMoreIcon />}
                    id={index}
                  >
                    <Grid
                      className={classes.panelExpanded}
                      container
                      layout={'row'}
                      spacing={2}
                      alignItems='center'
                      justify='space-between'
                    >
                      <Grid item xs={1}>
                        {favorited ? (
                          <IconButton
                            aria-label='remove from briefcase'
                            onClick={() =>
                              this.props.deleteBriefcaseElement(
                                abstract.abstract_id,
                              )
                            }
                          >
                            <WorkIcon />
                          </IconButton>
                        ) : (
                            <IconButton
                              aria-label='add to briefcase'
                              onClick={() =>
                                this.props.addBriefcaseElement(
                                  'Poster',
                                  abstract.abstract_id,
                                  abstract.account_name,
                                )
                              }
                            >
                              <WorkOutlineIcon />
                            </IconButton>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Typography className={classes.secondaryHeading}>
                        {abstract.accepted_type}
                        </Typography>
                      </Grid>
                      <Grid item xs={11} sm={11} md={8} lg={8}>
                        <Typography className={classes.heading}>
                          {abstract.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      className={classes.panelExpanded}
                      container
                      layout={'row'}
                      spacing={2}
                      alignItems='center'
                      justify='space-between'
                    >
                <Grid item xs={12} className={classes.grid}>
                        <Typography variant="h6" align='center' color='textPrimary'>
                           {abstract.title}
                        </Typography>
                        <Divider className={classes.divider} variant="fullWidth" />
                        <br/>
                        <Typography className={classes.lineSpeakerName} variant='h6'>
                          {abstract.presenter_first_name ? <React.Fragment>{abstract.presenter_first_name + " " + abstract.presenter_last_name}</React.Fragment> : <React.Fragment></React.Fragment>}
                      </Typography>
                        <Typography className={classes.lineSpeakerTitle}>
                          {abstract.presenter_title ? abstract.presenter_title : ''}
                        </Typography>
                        <Typography className={classes.lineSpeakerCompany}>
                          {abstract.presenter_company ? abstract.presenter_company : ''}
                        </Typography>
                        <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_email ? <><b>Email:</b> {abstract.presenter_email}</> : ''}
                        </Typography>
                        {abstract.start_time && abstract.date ?
                        <Typography variant="body1" align='center'>
                            
                            {`Starts at ${moment(abstract.start_time, "HH:mm:ss").format("h:mm a")} on  ${moment(abstract.date.substring(0, 10)).format('dddd')}, ${moment(abstract.date.substring(0, 10)).format('MMMM')} ${moment(abstract.date.substring(0, 10)).format('Do')}`}
                        </Typography>
                        :
                        <React.Fragment></React.Fragment>}
                        <br/>
                        <FormControl className={classes.formControlButton}>
                        <Button variant="contained" className={classes.button} onClick={() => this.navigate('posterhall/',"poster_id",abstract.abstract_id)}>
                              Go To Poster
                          </Button>
                        </FormControl>
                </Grid>
                </Grid>
                  </AccordionDetails>
                </Accordion>
                </Grid>
                )
                break
                default:
                  listAbstract = (
                    <Grid item xs={12} key={index} className={classes.grid}>
                    <Accordion
                    key={index}
                    className={classes.root}
                  >
                    <AccordionSummary
                      className={classes.expansionPanelMain}
                      expandIcon={<ExpandMoreIcon />}
                      id={index}
                    >
                      <Grid
                        className={classes.panelExpanded}
                        container
                        layout={'row'}
                        spacing={2}
                        alignItems='center'
                        justify='space-between'
                      >
                        <Grid item xs={1}>
                          {favorited ? (
                            <IconButton
                              aria-label='remove from briefcase'
                              onClick={() =>
                                this.props.deleteBriefcaseElement(
                                  abstract.abstract_id,
                                )
                              }
                            >
                              <WorkIcon />
                            </IconButton>
                          ) : (
                              <IconButton
                                aria-label='add to briefcase'
                                onClick={() =>
                                  this.props.addBriefcaseElement(
                                    'Presentation',
                                    abstract.abstract_id,
                                    abstract.account_name,
                                  )
                                }
                              >
                                <WorkOutlineIcon />
                              </IconButton>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Typography className={classes.secondaryHeading}>
                          {abstract.accepted_type}
                          </Typography>
                        </Grid>
                        <Grid item xs={11} sm={11} md={8} lg={8}>
                          <Typography className={classes.heading}>
                            {abstract.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        className={classes.panelExpanded}
                        container
                        layout={'row'}
                        spacing={2}
                        alignItems='center'
                        justify='space-between'
                      >
                  <Grid item xs={12} className={classes.grid}>
                          <Typography variant="h6" align='center' color='textPrimary'>
                             {abstract.title}
                          </Typography>
                          <Divider className={classes.divider} variant="fullWidth" />
                          <br/>
                          <Typography className={classes.lineSpeakerName} variant='h6'>
                          {abstract.presenter_first_name ? <React.Fragment>{abstract.presenter_first_name + " " + abstract.presenter_last_name}</React.Fragment> : <React.Fragment></React.Fragment>}
                      </Typography>
                        <Typography className={classes.lineSpeakerTitle}>
                          {abstract.presenter_title ? abstract.presenter_title : ''}
                        </Typography>
                        <Typography className={classes.lineSpeakerCompany}>
                          {abstract.presenter_company ? abstract.presenter_company : ''}
                        </Typography>
                        <Typography className={classes.lineSpeakerTitle} >
                        {abstract.presenter_email ? <><b>Email:</b> {abstract.presenter_email}</> : ''}
                        </Typography>
                          <br/>
                          {abstract.start_time && abstract.date ?
                          <Typography variant="body1" align='center'>
                              
                              {`Starts at ${moment(abstract.start_time, "HH:mm:ss").format("h:mm a")} on  ${moment(abstract.date.substring(0, 10)).format('dddd')}, ${moment(abstract.date.substring(0, 10)).format('MMMM')} ${moment(abstract.date.substring(0, 10)).format('Do')}`}
                          </Typography>
                          :
                          <React.Fragment></React.Fragment>}
                          <br/>
                          <FormControl className={classes.formControlButton}>
                          <Button variant="contained" className={classes.button} onClick={() => this.navigate('auditorium/',"presentation_id",abstract.abstract_id)}>
                            Go to Presentation
                        </Button>
                          </FormControl>
                  </Grid>
                  </Grid>
                    </AccordionDetails>
                  </Accordion>
                  </Grid>
                  )
            }

            return (
              listAbstract
            )
            })

        return (
            <div className={classes.grid} >
                <Grid container layout={"row"} justify="center" spacing={0} >
                    <Grid item xs={12}>
                        <Typography variant="h5" component='div' align='center'>
                            Come See Us Throughout the Event!
                        </Typography>
                        <br/><br/>
                    </Grid>
                    {abstracts}
                </Grid>
            </div>
        );
    }
}

const MyTheme = {
    button: { backgroundColor: '#6f1a1d' },
};


function mapStateToProps(state) {
    return {
      user: state.user,
      briefcase: state.briefcase,
      debug: state.debug,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        addBriefcaseElement: addBriefcaseElement,
        deleteBriefcaseElement: deleteBriefcaseElement,
      },
      dispatch
    );
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(withAuthenticator(Chat, false, [], null, MyTheme))));