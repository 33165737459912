import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Monitor from '../../Assets/SVG/singleMonitor.svg';
import SingleStandingKiosk from '../../Assets/SVG/singleStandingKiosk.svg';

const useStyles = makeStyles((theme) => ({
  clickable: {
    cursor: 'pointer',
  },
}));

function ExhibitBooth(props) {
  const classes = useStyles();
  const [colorScheme, setColorScheme] = useState({
    primary_color: '',
    primary_colorDark: '',
    primary_colorLight: '',
    secondary_color: '',
  });

  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    var R = parseInt(col.substring(0, 2), 16);
    var G = parseInt(col.substring(2, 4), 16);
    var B = parseInt(col.substring(4, 6), 16);
    // to make the colour less bright than the input
    // change the following three "+" symbols to "-"
    R = R + amt;
    G = G + amt;
    B = B + amt;

    if (R > 255) R = 255;
    else if (R < 0) R = 0;

    if (G > 255) G = 255;
    else if (G < 0) G = 0;

    if (B > 255) B = 255;
    else if (B < 0) B = 0;

    var RR =
      R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    var GG =
      G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    var BB =
      B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

    return (usePound ? '#' : '') + RR + GG + BB;
  };
  useEffect(() => {

    let primary;
    let secondary;
    if (props.exhibit.primary_color) {
      primary = props.exhibit.primary_color;
    } else {
      primary = '#1b5ede';
    }
    if (props.exhibit.secondary_color) {
      secondary = props.exhibit.secondary_color;
    } else {
      secondary = '#641bde';
    }
    let dark = LightenDarkenColor(primary, -40);
    let light = LightenDarkenColor(primary, 40);
    setColorScheme({
      primary_color: primary,
      primary_colorDark: dark,
      primary_colorLight: light,
      secondary_color: secondary,
    });
  }, [props.exhibit.primary_color, props.exhibit.secondary_color]);

  let noSelected = 'url(#deselected)';
  let yesSelected = 'url(#selected)';

  let overview = (
    <Tooltip title='Overview' placement='bottom'>
      <g
        className={classes.clickable}
        onClick={(event) => props.handleTabChange(event, 0)}
        style={{ filter: props.tabValue === 1 ? yesSelected : noSelected }}
      >
        
          <path
            fill='url(#prefix__bo)'
            d='M1704.43 1182.63h222.52v245.78h-222.52z'
          />
          <g mask='url(#prefix__bp)'>
            <path
              opacity={0.3}
              fill='url(#prefix__bq)'
              style={{
                mixBlendMode: 'multiply',
              }}
              d='M1704.43 1182.63h222.52v245.78h-222.52z'
            />
          </g>
          <g mask='url(#prefix__br)'>
            <path
              d='M1413.67 1829.59c0 18.57 245.75 33.62 548.9 33.62s698.15-15 698.15-33.62-395-33.62-698.15-33.62-548.9 15.03-548.9 33.62z'
              transform='translate(-221.5 -50.04)'
              fill='url(#prefix__bs)'
              style={{
                mixBlendMode: 'multiply',
              }}
            />
          </g>
          <path
            d='M2237.09 1774.77c0 11-8.51 19.86-19 19.86h-804.78c-10.5 0-19-8.89-19-19.86 0-11 8.51-19.86 19-19.86h804.77c10.5 0 19 8.89 19 19.86z'
            fill='#979797'
          />
          <path
            d='M2237.09 1772.18c0 11-8.51 19.86-19 19.86h-804.78c-10.5 0-19-8.89-19-19.86 0-11 8.51-19.85 19-19.85h804.77c10.5 0 19 8.89 19 19.85z'
            fill='#e4e4e4'
          />
          <path
            d='M2481.59 1804c0 10.09-7.83 18.27-17.5 18.27H1615.8c-9.66 0-17.49-8.18-17.49-18.27v-411.17c0-10.1 7.83-18.28 17.49-18.28h848.29c9.67 0 17.5 8.18 17.5 18.28z'
            transform='translate(-221.5 -50.04)'
            fill='url(#prefix__bt)'
          />
          <g mask='url(#prefix__bu)'>
            <path
              opacity={0.6}
              fill='url(#prefix__bv)'
              style={{
                mixBlendMode: 'multiply',
              }}
              d='M1394.3 1772.19h842.79v15.59H1394.3z'
            />
          </g>
          <g clipPath='url(#prefix__bw)' opacity={0.38}>
            <path
              d='M1741.12 1398.61h454.79c22.22 0 77.78-15.83 77.84-38.44 0-5 1.34-14.82-3.08-18.74-15.66-13.93-57.12-16.17-73.37-19.7h-763.13c-16.25 3.53-57.94 5.1-73.6 19-4.42 3.92-2.87 14.44-2.85 19.41.06 22.61 55.61 38.44 77.84 38.44z'
              fill='#b1b1b1'
            />
          </g>
          <g opacity={0.44}>
            <path
              d='M1742.57 1376.54h454.8c22.22 0 77.78-11.63 77.84-28.24 0-3.65 1.33-10.88-3.08-13.77-15.66-10.23-57.12-11.87-73.37-14.47h-763.13c-16.26 2.6-58 3.75-73.61 14-4.41 2.88-2.86 10.61-2.85 14.26.07 16.61 55.62 28.24 77.85 28.24z'
              fill='#e4e4e4'
              style={{
                mixBlendMode: 'multiply',
              }}
            />
            <path
              d='M1742.57 1376.05h454.8c22.22 0 77.78-11.63 77.84-28.24 0-3.65 1.33-10.89-3.08-13.77-15.66-10.23-57.12-11.88-73.37-14.47h-763.13c-16.26 2.59-58 3.74-73.61 14-4.41 2.88-2.86 10.61-2.85 14.26.07 16.61 55.62 28.24 77.85 28.24z'
              fill='#b1b1b1'
            />
            <path
              d='M1742.57 1375.55h454.8c22.22 0 77.78-11.62 77.84-28.23 0-3.66 1.33-10.89-3.08-13.77-15.66-10.24-57.12-11.88-73.37-14.47h-763.13c-16.26 2.59-58 3.74-73.61 14-4.41 2.89-2.86 10.61-2.85 14.27.07 16.61 55.62 28.23 77.85 28.23z'
              fill='#979797'
            />
            <path
              d='M1742.57 1375.12h454.8c22.22 0 77.78-11.63 77.84-28.24 0-3.65 1.33-10.88-3.08-13.77-15.66-10.23-57.12-11.87-73.37-14.46h-763.13c-16.26 2.59-58 3.74-73.61 14-4.41 2.89-2.86 10.61-2.85 14.26.07 16.61 55.62 28.24 77.85 28.24z'
              fill='gray'
            />
            <path
              d='M1742.57 1374.7h454.8c22.22 0 77.78-11.63 77.84-28.24 0-3.65 1.33-10.88-3.08-13.77-15.66-10.23-57.12-11.87-73.37-14.46h-763.13c-16.26 2.59-58 3.74-73.61 14-4.41 2.88-2.86 10.61-2.85 14.26.07 16.61 55.62 28.24 77.85 28.24z'
              fill='#666'
            />
          </g>
          <path
            d='M1964.07 1424.34h454.8c22.22 0 77.78-11.62 77.84-28.23 0-3.66 1.33-10.89-3.08-13.77-15.66-10.24-57.12-11.88-73.37-14.47h-763.13c-16.26 2.59-58 3.74-73.61 14-4.41 2.89-2.86 10.61-2.85 14.27.07 16.61 55.62 28.23 77.85 28.23z'
            transform='translate(-221.5 -50.04)'
            fill='url(#prefix__bx)'
          />
          <path
            d='M1964.07 1414.56h454.8c22.22 0 77.78-11.63 77.84-28.24s-55.62-24.92-76.45-28.24h-763.13c-20.84 3.32-76.52 11.63-76.46 28.24s55.62 28.24 77.85 28.24z'
            transform='translate(-221.5 -50.04)'
            fill='url(#prefix__by)'
          />
        <path
          fill='#ffffff'
          d='M0 0h360v360H0z'
          transform='translate(1645 1395)'
        />
        <image
          xlinkHref={props.exhibit.logo_address}
          width='350'
          height='350'
          x='1650'
          y='1400'
        />
      </g>
    </Tooltip>
  );

  let resources = (
    // <Tooltip title='Resources' placement='bottom'>
      <g
        // className={classes.clickable}
        // onClick={(event) => props.handleTabChange(event, 1)}
        // style={{ filter: props.tabValue === 1 ? yesSelected : noSelected }}
      >
        <path
          fill='#666'
          d='M555.62 1754.07v4.86l-50 49.33H273.25v-11.13h232.37l50-56.9v13.84z'
        />
        <path
          fill='#acadad'
          d='M273.25 1409.49h232.37l50-47.75H323.25l-50 47.75z'
        />
        <path fill='#e4e4e4' d='M505.62 1804.98H273.25v-396.11h232.37z' />
        <path
          fill='#cdcecd'
          d='M505.62 1804.98l50-48.91v-396.11l-50 48.91v396.11z'
        />
        <path
          fill='#b1b1b1'
          opacity={0.4}
          d='M273.25 1326.39h232.37l50-47.75H323.25l-50 47.75z'
        />
        <path
          fill='#979797'
          opacity={0.1}
          d='M505.62 1409.49H273.25v-83.1h232.37z'
        />
        <path
          fill='#979797'
          opacity={0.2}
          d='M505.62 1408.87l50-48.91v-81.9l-50 48.91v81.9zM273.25 1408.87l50-48.91v-81.9l-50 48.91v81.9z'
        />
        <path
          fill='#979797'
          opacity={0.4}
          d='M273.25 1244.69h232.37l50-47.75H323.25l-50 47.75z'
        />
        <path
          fill='#979797'
          opacity={0.1}
          d='M505.62 1327.79H273.25v-83.1h232.37z'
        />
        <path
          fill='#979797'
          opacity={0.2}
          d='M505.62 1327.17l50-48.91v-81.9l-50 48.92v81.89zM273.25 1327.17l50-48.91v-81.9l-50 48.92v81.89z'
        />
        <g mask='url(#prefix__bB)'>
          <path
            d='M539.55 1271.85c0 3.26 40.6 5.91 90.68 5.91s90.67-2.65 90.67-5.91-40.6-5.92-90.67-5.92-90.68 2.65-90.68 5.92z'
            transform='translate(-221.5 -50.04)'
            fill='url(#prefix__bC)'
            style={{
              mixBlendMode: 'multiply',
            }}
          />
        </g>
        <g mask='url(#prefix__bD)'>
          <ellipse
            cx={408.72}
            cy={1217.08}
            rx={90.67}
            ry={16.99}
            fill='url(#prefix__bE)'
            style={{
              mixBlendMode: 'multiply',
            }}
          />
        </g>
        <path fill='#e4e4e4' d='M469.19 1221.81H355.93v-132.52h113.26z' />
      </g>
    // </Tooltip>
  );

  let products = (
    <Tooltip title='Products' placement='bottom'>
      <g
        className={classes.clickable}
        onClick={(event) => props.handleTabChange(event, 1)}
        style={{ filter: props.tabValue === 2 ? yesSelected : noSelected }}
      >
        <path
          // fill='red'
          fill='#dcdcdc00'
          d='M0 0h300v550H0z'
          transform='translate(2910 1275)'
        />
        <g mask='url(#prefix__ba)' clipPath='url(#prefix__aZ)'>
          <ellipse
            cx={3376.19}
            cy={1832.17}
            rx={92.08}
            ry={24.25}
            transform='rotate(-8.57 2932.69 3285.818)'
            fill='url(#prefix__aw)'
          />
        </g>
        <path
          fill={colorScheme.secondary_color}
          d='M2967.9 1291.02h220.12v40.12H2967.9z'
        />
        <path
          fill='url(#prefix__bb)'
          d='M3151.94 1792.26h-237.51l40.79-24.95h237.51l-40.79 24.95z'
        />
        <path
          d='M2967.9 1771.03a2.09 2.09 0 01-2.08-2.09v-477.92a2.09 2.09 0 114.17 0v477.94a2.1 2.1 0 01-2.09 2.07zM3188.8 1769.96a2.08 2.08 0 01-2.08-2.08v-476.86a2.09 2.09 0 114.17 0v476.88a2.09 2.09 0 01-2.09 2.06z'
          fill='#4d4d4d'
        />
        <path
          fill='#333'
          d='M3192.73 1778.03l-40.99 25.85v-10.4l40.99-25.89v10.44z'
        />
        <path fill='gray' d='M2914.95 1793.48h236.79v10.39h-236.79z' />
        <path fill='#bebfc1' d='M2914.43 1792.26h236.79v10.39h-236.79z' />
        <path
          fill='#9a9d9d'
          d='M3192.73 1776.89l-41.52 25.77v-10.4l41.52-24.95v9.58z'
        />
        <path
          d='M3185.34 1454.4h-199.58c-.76 0-1.37-.35-1.37-.78s.61-.78 1.37-.78h199.58c.76 0 1.38.35 1.38.78s-.62.78-1.38.78zM3185.34 1557.07h-199.58c-.76 0-1.37-.35-1.37-.78s.61-.78 1.37-.78h199.58c.76 0 1.38.34 1.38.78s-.62.78-1.38.78zM3185.34 1659.74h-199.58c-.76 0-1.37-.35-1.37-.79s.61-.78 1.37-.78h199.58c.76 0 1.38.35 1.38.78s-.62.79-1.38.79zM2945.14 1774.8a.78.78 0 01-.78-.78v-332.75a.78.78 0 011.56 0v332.75a.78.78 0 01-.78.78z'
          fill='gray'
        />
        <path
          d='M3185.34 1447.23h-239.6c-.76 0-1.38-.35-1.38-.78s.62-.78 1.38-.78h239.6c.76 0 1.38.35 1.38.78s-.62.78-1.38.78zM3185.34 1549.18h-239.6c-.76 0-1.38-.35-1.38-.79s.62-.78 1.38-.78h239.6c.76 0 1.38.35 1.38.78s-.62.79-1.38.79zM3185.34 1651.74h-239.6c-.76 0-1.38-.35-1.38-.78s.62-.78 1.38-.78h239.6c.76 0 1.38.35 1.38.78s-.62.78-1.38.78zM3185.34 1752.17h-238.42c-.76 0-1.38-.35-1.38-.78s.62-.79 1.38-.79h238.42c.76 0 1.38.35 1.38.79s-.62.78-1.38.78z'
          fill='gray'
        />
        <path
          fill='url(#prefix__Y)'
          d='M3035.46 1552.29h-91.89l35.4-98.66h91.89l-35.4 98.66z'
        />
        <path
          fill='url(#prefix__Z)'
          d='M3035.46 1654.96h-91.89l35.4-98.67h91.89l-35.4 98.67z'
        />
        <path
          fill='url(#prefix__aa)'
          d='M3035.46 1757.62h-91.89l35.4-98.66h91.89l-35.4 98.66z'
        />
        <path
          fill='url(#prefix__bc)'
          d='M3035.46 1449.63h-91.89l35.4-98.67h91.89l-35.4 98.67z'
        />
        <path
          d='M3050 1774.8a.78.78 0 01-.78-.78v-332.75a1.09 1.09 0 010-.26l31-89.27H2967.9a.78.78 0 01-.78-.78.79.79 0 01.78-.79h113.48a.78.78 0 01.74 1l-31.33 90.19v332.62a.78.78 0 01-.79.82z'
          fill='gray'
        />
        <path
          fill='url(#prefix__bd)'
          d='M3144.19 1552.29h-91.89l35.4-98.66h91.89l-35.4 98.66z'
        />
        <path
          fill='url(#prefix__be)'
          d='M3144.19 1654.96h-91.89l35.4-98.67h91.89l-35.4 98.67z'
        />
        <path
          fill='url(#prefix__bf)'
          d='M3144.19 1757.62h-91.89l35.4-98.66h91.89l-35.4 98.66z'
        />
        <path
          fill='url(#prefix__bg)'
          d='M3144.19 1449.63h-91.89l35.4-98.67h91.89l-35.4 98.67z'
        />
        <path
          d='M3158.73 1774.8a.78.78 0 01-.78-.78v-332.75a.75.75 0 010-.26l31-89.27h-112.32a.79.79 0 01-.79-.78.8.8 0 01.79-.79h113.48a.78.78 0 01.63.33.75.75 0 01.1.71l-31.34 90.19v332.62a.78.78 0 01-.77.78z'
          fill='gray'
        />
      </g>
    </Tooltip>
  );

  let staff = (
      <image xlinkHref={SingleStandingKiosk} height='800' width='300' x='2400' y='900' transform='scale(1 1)' />
    // <Tooltip title='Staff' placement='bottom'>
    //   <g
    //     className={classes.clickable}
    //     onClick={(event) => props.handleTabChange(event, 3)}
    //     style={{ filter: props.tabValue === 3 ? yesSelected : noSelected }}
    //   >
    //     <g data-name='boothRep'>
    //     </g>
    //   </g>
    // </Tooltip>
  );

  let eventActivities = (
    <Tooltip title='Event Activities' placement='bottom'>
      <g
        className={classes.clickable}
        onClick={(event) => props.handleTabChange(event, 2)}
        style={{ filter: props.tabValue === 3 ? yesSelected : noSelected }}
      >
        <image xlinkHref={Monitor} height='360' width='600' x='700' y='918' />
        {/* <image xlinkHref={MerckLogo} height='300' width='533' x='730' y='944' /> */}
      </g>
    </Tooltip>
  );

  return (
    <svg viewBox='0 0 3560 1910'>
      <defs>
        <filter id='selected'>
          <feDropShadow dx='0' dy='0' stdDeviation='18' floodColor='#a8c0ff'>
            <animate
              attributeName='stdDeviation'
              values='18;18;0;18;18'
              dur='4s'
              repeatCount='indefinite'
            />
          </feDropShadow>

          <feComponentTransfer>
            <feFuncA type='linear' slope='3' />
            {/* <!-- slope is the opacity of the shadow --> */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            {/* <!-- this contains the offset blurred image --> */}
            <feMergeNode in='SourceGraphic' />
            {/* <!-- this contains the element that the filter is applied to --> */}
          </feMerge>
        </filter>
        <filter id='deselected'>
          <feDropShadow dx='0' dy='0' stdDeviation='8' floodColor='#efefef'>
            <animate
              attributeName='stdDeviation'
              values='0;8;0;0;0;8;0'
              dur='10s'
              repeatCount='indefinite'
            />
          </feDropShadow>
          <feDropShadow dx='0' dy='0' stdDeviation='10' floodColor='#3f3f3f'>
            <animate
              attributeName='stdDeviation'
              values='0;10;0;0;0;10;0'
              dur='10s'
              repeatCount='indefinite'
            />
          </feDropShadow>

          <feComponentTransfer>
            <feFuncA type='linear' slope='2' />
            {/* <!-- slope is the opacity of the shadow --> */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            {/* <!-- this contains the offset blurred image --> */}
            <feMergeNode in='SourceGraphic' />
            {/* <!-- this contains the element that the filter is applied to --> */}
          </feMerge>
        </filter>
        <linearGradient
          id='prefix__O'
          x1={2769.57}
          y1={616.25}
          x2={2769.57}
          y2={922.95}
          xlinkHref='#prefix__c'
        />
        <linearGradient
          id='prefix__Q'
          x1={2769.57}
          y1={936.48}
          x2={2769.57}
          y2={1243.18}
          xlinkHref='#prefix__c'
        />
        <linearGradient
          id='prefix__S'
          x1={2769.57}
          y1={1157.11}
          x2={2769.57}
          y2={1463.81}
          xlinkHref='#prefix__c'
        />
        <linearGradient
          id='prefix__V'
          x1={676.43}
          y1={2222.14}
          x2={1297.02}
          y2={1601.54}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.08} stopColor='#f6f6f6' />
          <stop offset={0.23} stopColor='#dcdcdc' />
          <stop offset={0.41} stopColor='#b2b2b2' />
          <stop offset={0.62} stopColor='#787878' />
          <stop offset={0.86} stopColor='#2f2f2f' />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id='prefix__ad'
          x1={6609.29}
          y1={302.15}
          x2={6609.29}
          y2={353.42}
          gradientTransform='matrix(-1 0 0 1 7215.02 0)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.11} stopColor='#ececec' />
          <stop offset={0.32} stopColor='#bababa' />
          <stop offset={0.63} stopColor='#696969' />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id='prefix__ag'
          x1={389.25}
          y1={295.01}
          x2={389.25}
          y2={887.64}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.05} stopColor='#ebebeb' />
          <stop offset={0.2} stopColor='#adadad' />
          <stop offset={0.35} stopColor='#787878' />
          <stop offset={0.5} stopColor='#4d4d4d' />
          <stop offset={0.64} stopColor='#2c2c2c' />
          <stop offset={0.77} stopColor='#141414' />
          <stop offset={0.9} stopColor='#050505' />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id='prefix__aj'
          x1={1923.22}
          y1={1355.56}
          x2={2141.35}
          y2={1355.56}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0} stopColor='#f6f6f6' />
          <stop offset={0.03} stopColor='#c8c8c8' />
          <stop offset={0.05} stopColor='#9d9d9d' />
          <stop offset={0.08} stopColor='#777' />
          <stop offset={0.11} stopColor='#575757' />
          <stop offset={0.14} stopColor='#3b3b3b' />
          <stop offset={0.18} stopColor='#252525' />
          <stop offset={0.22} stopColor='#141414' />
          <stop offset={0.27} stopColor='#090909' />
          <stop offset={0.35} stopColor='#020202' />
          <stop offset={0.55} />
          <stop offset={0.72} stopColor='#020202' />
          <stop offset={0.78} stopColor='#090909' />
          <stop offset={0.82} stopColor='#141414' />
          <stop offset={0.86} stopColor='#252525' />
          <stop offset={0.89} stopColor='#3b3b3b' />
          <stop offset={0.91} stopColor='#575757' />
          <stop offset={0.94} stopColor='#777' />
          <stop offset={0.96} stopColor='#9d9d9d' />
          <stop offset={0.98} stopColor='#c8c8c8' />
          <stop offset={1} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <linearGradient
          id='prefix__ap'
          x1={3014.26}
          y1={1821.91}
          x2={3014.26}
          y2={1832.95}
          gradientTransform='translate(-977.07)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.1} stopColor='#ddd' />
          <stop offset={0.35} stopColor='#909090' />
          <stop offset={0.58} stopColor='#525252' />
          <stop offset={0.77} stopColor='#262626' />
          <stop offset={0.91} stopColor='#0a0a0a' />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id='prefix__A'
          x1={1258.39}
          y1={616.25}
          x2={1258.39}
          y2={922.95}
          xlinkHref='#prefix__c'
        />
        <linearGradient
          id='prefix__C'
          x1={1258.39}
          y1={936.48}
          x2={1258.39}
          y2={1243.18}
          xlinkHref='#prefix__c'
        />
        <linearGradient
          id='prefix__E'
          x1={1258.39}
          y1={1157.11}
          x2={1258.39}
          y2={1463.81}
          xlinkHref='#prefix__c'
        />
        <linearGradient
          id='prefix__az'
          x1={149.21}
          y1={274.39}
          x2={588.8}
          y2={274.39}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor={colorScheme.primary_colorDark} />
          <stop offset={1} stopColor={colorScheme.primary_color} />
        </linearGradient>
        <linearGradient
          id='prefix__r'
          x1={1780.3}
          y1={1641.59}
          x2={1780.3}
          y2={398.58}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#c5c5c6' />
          <stop offset={1} stopColor='#e6e6e6' />
        </linearGradient>
        <linearGradient
          id='prefix__aA'
          x1={588.8}
          y1={417.62}
          x2={2971.8}
          y2={417.62}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor={colorScheme.primary_color} />
          <stop offset={1} stopColor={colorScheme.primary_color} />
        </linearGradient>
        <linearGradient
          id='prefix__aB'
          x1={0}
          y1={1738.71}
          x2={3560.59}
          y2={1738.71}
          xlinkHref='#prefix__r'
        />
        <linearGradient
          id='prefix__aC'
          x1={621.86}
          y1={560.69}
          x2={2908.64}
          y2={560.69}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#b6b6b7' />
          <stop offset={1} stopColor='#cacaca' />
        </linearGradient>
        <linearGradient
          id='prefix__F'
          x1={1258.39}
          y1={616.98}
          x2={1258.39}
          y2={607.84}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.13} stopColor='#6d6d6d' />
          <stop offset={0.3} stopColor='#7f7f7f' />
          <stop offset={0.51} stopColor='#9e9e9e' />
          <stop offset={0.75} stopColor='#c8c8c8' />
          <stop offset={1} stopColor='#fefefe' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <linearGradient
          id='prefix__aH'
          x1={1036.88}
          y1={566.21}
          x2={1036.88}
          y2={872.91}
          xlinkHref='#prefix__x'
        />
        <linearGradient
          id='prefix__aJ'
          x1={1036.88}
          y1={886.44}
          x2={1036.88}
          y2={1193.14}
          xlinkHref='#prefix__x'
        />
        <linearGradient
          id='prefix__aL'
          x1={1036.88}
          y1={1107.07}
          x2={1036.88}
          y2={1413.77}
          xlinkHref='#prefix__x'
        />
        <linearGradient
          id='prefix__aM'
          x1={2769.57}
          y1={616.98}
          x2={2769.57}
          y2={607.84}
          xlinkHref='#prefix__F'
        />
        <linearGradient
          id='prefix__aS'
          x1={2548.06}
          y1={566.21}
          x2={2548.06}
          y2={872.91}
          xlinkHref='#prefix__x'
        />
        <linearGradient
          id='prefix__aU'
          x1={2548.06}
          y1={886.44}
          x2={2548.06}
          y2={1193.14}
          xlinkHref='#prefix__x'
        />
        <linearGradient
          id='prefix__aW'
          x1={2548.06}
          y1={1107.07}
          x2={2548.06}
          y2={1413.77}
          xlinkHref='#prefix__x'
        />
        <linearGradient
          id='prefix__aY'
          x1={454.92}
          y1={2172.1}
          x2={1075.52}
          y2={1551.5}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#9d9e9f' />
          <stop offset={0.2} stopColor='#a6a7a8' />
          <stop offset={0.52} stopColor='#c0c1c1' />
          <stop offset={0.94} stopColor='#e9e9e9' />
          <stop offset={1} stopColor='#f0f0f0' />
        </linearGradient>
        <linearGradient
          id='prefix__bb'
          x1={-6289.24}
          y1={1829.82}
          x2={-6010.93}
          y2={1829.82}
          gradientTransform='translate(9203.67 -50.04)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#dcdcdc' />
          <stop offset={1} stopColor='#f0f0f0' />
        </linearGradient>
        <linearGradient
          id='prefix__Y'
          x1={1151.47}
          y1={1553}
          x2={1278.75}
          y2={1553}
          gradientTransform='translate(1792.11 -50.04)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#eaeaeb' />
          <stop offset={1} stopColor='#d6d7d7' />
        </linearGradient>
        <linearGradient
          id='prefix__Z'
          x1={1151.47}
          y1={1655.66}
          x2={1278.75}
          y2={1655.66}
          gradientTransform='translate(1792.11 -50.04)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#eaeaeb' />
          <stop offset={1} stopColor='#ebeced' />
        </linearGradient>
        <linearGradient
          id='prefix__aa'
          x1={1151.47}
          y1={1758.33}
          x2={1278.75}
          y2={1758.33}
          gradientTransform='translate(1792.11 -50.04)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={1} stopColor='#dfe0e0' />
        </linearGradient>
        <linearGradient
          id='prefix__bc'
          x1={2595.74}
          y1={1501.16}
          x2={2555.25}
          y2={1388.68}
          gradientTransform='matrix(-1 0 0 1 5584.66 -50.04)'
          xlinkHref='#prefix__Y'
        />
        <linearGradient
          id='prefix__bd'
          x1={1260.19}
          y1={1553}
          x2={1387.48}
          y2={1553}
          xlinkHref='#prefix__Y'
        />
        <linearGradient
          id='prefix__be'
          x1={1260.19}
          y1={1655.66}
          x2={1387.48}
          y2={1655.66}
          xlinkHref='#prefix__Z'
        />
        <linearGradient
          id='prefix__bf'
          x1={1260.19}
          y1={1758.33}
          x2={1387.48}
          y2={1758.33}
          xlinkHref='#prefix__aa'
        />
        <linearGradient
          id='prefix__bg'
          x1={2487.02}
          y1={1501.16}
          x2={2446.52}
          y2={1388.68}
          gradientTransform='matrix(-1 0 0 1 5584.66 -50.04)'
          xlinkHref='#prefix__Y'
        />
        <linearGradient
          id='prefix__bi'
          x1={6609.29}
          y1={302.15}
          x2={6609.29}
          y2={353.42}
          gradientTransform='matrix(-1 0 0 1 6993.52 -50.04)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#6b6d6e' />
          <stop offset={0.19} stopColor='#7e8081' />
          <stop offset={0.57} stopColor='#b0b1b2' />
          <stop offset={1} stopColor='#f0f0f0' />
        </linearGradient>
        <linearGradient
          id='prefix__bj'
          x1={370.71}
          y1={953.28}
          x2={3703.68}
          y2={953.28}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor={colorScheme.primary_colorDark} />
          {/* <stop offset={0.25} stopColor='#a81f22' />
          <stop offset={0.61} stopColor='#ba1e22' /> */}
          <stop offset={1} stopColor={colorScheme.primary_color} />
        </linearGradient>
        <linearGradient
          id='prefix__bk'
          x1={149.21}
          y1={905.51}
          x2={186.27}
          y2={905.51}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#cacaca' />
          <stop offset={0.03} stopColor='#d0d0d0' />
          <stop offset={0.11} stopColor='#dbdbdb' />
          <stop offset={0.22} stopColor='#e2e2e2' />
          <stop offset={0.55} stopColor='#e4e4e4' />
          <stop offset={0.79} stopColor='#fff' />
          <stop offset={1} stopColor='#8c8e8f' />
        </linearGradient>
        <linearGradient
          id='prefix__bm'
          x1={167.74}
          y1={244.98}
          x2={167.74}
          y2={837.6}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#979797' />
          <stop offset={0.16} stopColor='#b2b2b2' />
          <stop offset={0.4} stopColor='#d4d4d4' />
          <stop offset={0.62} stopColor='#ececec' />
          <stop offset={0.83} stopColor='#fafafa' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <linearGradient
          id='prefix__bn'
          x1={3492.54}
          y1={1234.93}
          x2={3497.63}
          y2={1234.93}
          gradientTransform='rotate(-90 2845.015 2299.645)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#4f5052' />
          <stop offset={0.11} stopColor='#6f6f71' />
          <stop offset={0.41} stopColor='#c3c3c3' />
          <stop offset={0.55} stopColor='#e4e4e4' />
          <stop offset={0.79} stopColor='#fff' />
          <stop offset={1} stopColor='#cacaca' />
        </linearGradient>
        <linearGradient
          id='prefix__bo'
          x1={1815.69}
          y1={1180.28}
          x2={1815.69}
          y2={1314.12}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#989999' />
          <stop offset={0.04} stopColor='#929393' />
          <stop offset={0.31} stopColor='#6a6c6c' />
          <stop offset={0.52} stopColor='#515354' />
          <stop offset={0.65} stopColor='#484a4b' />
          <stop offset={0.72} stopColor='#4f5152' />
          <stop offset={0.82} stopColor='#636565' />
          <stop offset={0.94} stopColor='#838585' />
          <stop offset={1} stopColor='#959696' />
        </linearGradient>
        <linearGradient
          id='prefix__bq'
          x1={1701.72}
          y1={1305.52}
          x2={1919.84}
          y2={1305.52}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#444647' />
          <stop offset={0.08} stopColor='#444647' />
          <stop offset={0.55} stopColor='#444647' />
          <stop offset={0.94} stopColor='#444647' />
          <stop offset={1} stopColor='#444647' />
        </linearGradient>
        <linearGradient
          id='prefix__bt'
          x1={2575.37}
          y1={1598.39}
          x2={3458.66}
          y2={1598.39}
          gradientTransform='translate(-977.07)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#d2d3d3' />
          <stop offset={0.06} stopColor='#e8e8e8' />
          <stop offset={0.13} stopColor='#f9f9f9' />
          <stop offset={0.18} stopColor='#fff' />
          <stop offset={0.27} stopColor='#f8f8f8' />
          <stop offset={0.49} stopColor='#f6f6f6' />
          <stop offset={0.75} stopColor='#f3f3f4' />
          <stop offset={0.85} stopColor='#efeff0' />
          <stop offset={1} stopColor='#c4c6c6' />
        </linearGradient>
        <linearGradient
          id='prefix__bv'
          x1={3014.26}
          y1={1821.91}
          x2={3014.26}
          y2={1832.95}
          gradientTransform='translate(-1198.57 -50.04)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#979797' />
          <stop offset={0.21} stopColor='#a5a5a5' />
          <stop offset={0.71} stopColor='silver' />
          <stop offset={1} stopColor='#cacaca' />
        </linearGradient>
        <linearGradient
          id='prefix__bx'
          x1={1578.49}
          y1={1396.11}
          x2={2491.59}
          y2={1396.11}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#c2c4c6' />
          <stop offset={0.01} stopColor='#c6c7c9' />
          <stop offset={0.1} stopColor='#d8dadb' />
          <stop offset={0.19} stopColor='#e6e6e7' />
          <stop offset={0.32} stopColor='#eee' />
          <stop offset={0.55} stopColor='#f0f0f0' />
          <stop offset={0.7} stopColor='#ededed' />
          <stop offset={0.82} stopColor='#e3e4e4' />
          <stop offset={0.92} stopColor='#d3d4d6' />
          <stop offset={1} stopColor='#c2c4c6' />
        </linearGradient>
        <linearGradient
          id='prefix__by'
          x1={-525.07}
          y1={1411.82}
          x2={-525.07}
          y2={1348.42}
          gradientTransform='matrix(-1 0 0 1 1513.62 0)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#dfdfdf' />
          <stop offset={0.12} stopColor='#e4e4e4' />
          <stop offset={0.49} stopColor='#ededed' />
          <stop offset={1} stopColor='#f0f0f0' />
        </linearGradient>
        <filter
          id='prefix__a'
          x={1190.33}
          y={-9243.83}
          width={133.97}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__b'
          x={2701.51}
          y={-9243.83}
          width={133.97}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__d'
          x={2684.3}
          y={-9243.83}
          width={170.54}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__e'
          x={2637.44}
          y={-9243.83}
          width={264.26}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__f'
          x={1173.12}
          y={-9243.83}
          width={170.54}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__g'
          x={221.5}
          y={-9243.83}
          width={1776.62}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__h'
          x={3285.06}
          y={-9243.83}
          width={182.25}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__i'
          x={407.78}
          y={-9243.83}
          width={395.91}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__j'
          x={370.71}
          y={-9243.83}
          width={37.07}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__k'
          x={1925.93}
          y={-9243.83}
          width={222.52}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__l'
          x={1413.67}
          y={-9243.83}
          width={1247.06}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__m'
          x={1615.8}
          y={-9243.83}
          width={842.79}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__n'
          x={444}
          y={-9243.83}
          width={527.53}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__o'
          x={539.55}
          y={-9243.83}
          width={181.35}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__q'
          x={1126.26}
          y={-9243.83}
          width={264.26}
          height={32766}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__s'
          x={1190.33}
          y={598.21}
          width={133.97}
          height={28.89}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__u'
          x={1173.12}
          y={598.21}
          width={170.54}
          height={28.89}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__y'
          x={1126.26}
          y={617.94}
          width={264.26}
          height={311.98}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__B'
          x={1126.26}
          y={938.17}
          width={264.26}
          height={311.98}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__D'
          x={1126.26}
          y={1158.8}
          width={264.26}
          height={311.98}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__G'
          x={2701.51}
          y={598.21}
          width={133.97}
          height={28.89}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__J'
          x={2684.3}
          y={598.21}
          width={170.54}
          height={28.89}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__M'
          x={2637.44}
          y={617.94}
          width={264.26}
          height={311.98}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__P'
          x={2637.44}
          y={938.17}
          width={264.26}
          height={311.98}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__R'
          x={2637.44}
          y={1158.8}
          width={264.26}
          height={311.98}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__T'
          x={221.5}
          y={1699.61}
          width={1776.62}
          height={178.27}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__W'
          x={3285.06}
          y={1804.54}
          width={182.25}
          height={55.26}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__ab'
          x={407.78}
          y={302.21}
          width={395.91}
          height={50.62}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__ae'
          x={370.71}
          y={290.88}
          width={37.07}
          height={607.15}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__ah'
          x={1925.93}
          y={1232.67}
          width={222.52}
          height={245.78}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__ak'
          x={1413.67}
          y={1795.97}
          width={1247.06}
          height={67.24}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__an'
          x={1615.8}
          y={1822.22}
          width={842.79}
          height={15.59}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__aq'
          x={444}
          y={1683.05}
          width={527.53}
          height={210.44}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__at'
          x={539.55}
          y={1265.93}
          width={181.35}
          height={11.83}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <filter
          id='prefix__ax'
          x={539.55}
          y={1250.13}
          width={181.35}
          height={33.99}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodColor='#fff' result='bg' />
          <feBlend in='SourceGraphic' in2='bg' />
        </filter>
        <mask
          id='prefix__aD'
          x={968.82}
          y={548.17}
          width={133.97}
          height={28.89}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__s)'>
            <g mask='url(#prefix__t)' transform='translate(-221.5 -50.04)'>
              <path
                d='M1324.3 612.65c0 8-30 14.44-67 14.44s-67-6.46-67-14.44 30-14.44 67-14.44 67 6.46 67 14.44z'
                fill='url(#prefix__c)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aE'
          x={951.61}
          y={548.17}
          width={170.54}
          height={28.89}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__u)'>
            <g mask='url(#prefix__v)' transform='translate(-221.5 -50.04)'>
              <path
                d='M1343.66 612.65c0 8-38.18 14.44-85.28 14.44s-85.26-6.46-85.26-14.44 38.17-14.44 85.26-14.44 85.28 6.46 85.28 14.44z'
                fill='url(#prefix__w)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aG'
          x={904.75}
          y={567.91}
          width={264.26}
          height={311.98}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__y)'>
            <g mask='url(#prefix__z)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__A)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M1390.52 929.93h-264.26l104.16-311.98h55.93l104.17 311.98z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aI'
          x={904.75}
          y={888.13}
          width={264.26}
          height={311.98}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__B)'>
            <g mask='url(#prefix__z)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__C)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M1390.52 1250.15h-264.26l104.16-311.98h55.93l104.17 311.98z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aK'
          x={904.75}
          y={1108.76}
          width={264.26}
          height={311.98}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__D)'>
            <g mask='url(#prefix__z)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__E)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M1390.52 1470.78h-264.26l104.16-311.98h55.93l104.17 311.98z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aN'
          x={2480}
          y={548.17}
          width={133.97}
          height={28.89}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__G)'>
            <g mask='url(#prefix__H)' transform='translate(-221.5 -50.04)'>
              <path
                d='M2835.48 612.65c0 8-30 14.44-67 14.44s-67-6.46-67-14.44 30-14.44 67-14.44 67 6.46 67 14.44z'
                fill='url(#prefix__I)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aP'
          x={2462.8}
          y={548.17}
          width={170.54}
          height={28.89}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__J)'>
            <g mask='url(#prefix__K)' transform='translate(-221.5 -50.04)'>
              <path
                d='M2854.84 612.65c0 8-38.18 14.44-85.28 14.44s-85.26-6.46-85.26-14.44 38.17-14.44 85.26-14.44 85.28 6.46 85.28 14.44z'
                fill='url(#prefix__L)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aR'
          x={2415.93}
          y={567.91}
          width={264.26}
          height={311.98}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__M)'>
            <g mask='url(#prefix__N)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__O)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M2901.7 929.93h-264.26l104.17-311.98h55.92l104.17 311.98z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aT'
          x={2415.93}
          y={888.13}
          width={264.26}
          height={311.98}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__P)'>
            <g mask='url(#prefix__N)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__Q)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M2901.7 1250.15h-264.26l104.17-311.98h55.92l104.17 311.98z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aV'
          x={2415.93}
          y={1108.76}
          width={264.26}
          height={311.98}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__R)'>
            <g mask='url(#prefix__N)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__S)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M2901.7 1470.78h-264.26l104.17-311.98h55.92l104.17 311.98z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__aX'
          x={0}
          y={1649.57}
          width={1776.62}
          height={178.27}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__T)'>
            <g mask='url(#prefix__U)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__V)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M221.5 1877.88l1776.62-178.27H888.48L221.5 1877.88z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__ba'
          x={3063.56}
          y={1754.51}
          width={182.25}
          height={55.26}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__W)'>
            <g mask='url(#prefix__X)' transform='translate(-221.5 -50.04)'>
              <ellipse
                cx={3376.19}
                cy={1832.17}
                rx={92.08}
                ry={24.25}
                transform='rotate(-8.57 3377.369 1832.668)'
                fill='url(#prefix__p)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__bh'
          x={186.27}
          y={252.17}
          width={395.91}
          height={50.62}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__ab)'>
            <g mask='url(#prefix__ac)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__ad)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M407.78 315.64l56.02 37.19 339.89-2.08-74.45-48.54H407.78v13.43z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__bl'
          x={149.21}
          y={240.84}
          width={37.07}
          height={607.15}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__ae)'>
            <g mask='url(#prefix__af)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__ag)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M370.71 290.88h37.07v607.15h-37.07z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__bp'
          x={1704.43}
          y={1182.63}
          width={222.52}
          height={245.78}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__ah)'>
            <g mask='url(#prefix__ai)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__aj)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M1925.93 1232.67h222.52v245.78h-222.52z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__br'
          x={1192.16}
          y={1745.93}
          width={1247.06}
          height={67.24}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__ak)'>
            <g mask='url(#prefix__al)' transform='translate(-221.5 -50.04)'>
              <path
                d='M1413.67 1829.59c0 18.57 245.75 33.62 548.9 33.62s698.15-15 698.15-33.62-395-33.62-698.15-33.62-548.9 15.03-548.9 33.62z'
                fill='url(#prefix__am)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__bu'
          x={1394.3}
          y={1772.19}
          width={842.79}
          height={15.59}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__an)'>
            <g mask='url(#prefix__ao)' transform='translate(-221.5 -50.04)'>
              <path
                fill='url(#prefix__ap)'
                style={{
                  mixBlendMode: 'multiply',
                }}
                d='M1615.8 1822.22h842.79v15.59H1615.8z'
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__bz'
          x={222.49}
          y={1633.02}
          width={527.53}
          height={210.44}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__aq)'>
            <g mask='url(#prefix__ar)' transform='translate(-221.5 -50.04)'>
              <path
                d='M444.25 1812.86c5.92 56.39 114.6 91.2 242.74 77.74s290.3-76.71 284.38-133.1-177.69-84.58-305.83-71.12-227.21 70.08-221.29 126.48z'
                fill='url(#prefix__as)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__bB'
          x={318.05}
          y={1215.89}
          width={181.35}
          height={11.83}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__at)'>
            <g mask='url(#prefix__au)' transform='translate(-221.5 -50.04)'>
              <path
                d='M539.55 1271.85c0 3.26 40.6 5.91 90.68 5.91s90.67-2.65 90.67-5.91-40.6-5.92-90.67-5.92-90.68 2.65-90.68 5.92z'
                fill='url(#prefix__av)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <mask
          id='prefix__bD'
          x={318.05}
          y={1200.09}
          width={181.35}
          height={33.99}
          maskUnits='userSpaceOnUse'
        >
          <g filter='url(#prefix__ax)'>
            <g mask='url(#prefix__au)' transform='translate(-221.5 -50.04)'>
              <ellipse
                cx={630.23}
                cy={1267.12}
                rx={90.67}
                ry={16.99}
                fill='url(#prefix__ay)'
                style={{
                  mixBlendMode: 'multiply',
                }}
              />
            </g>
          </g>
        </mask>
        <radialGradient
          id='prefix__c'
          cx={1944.23}
          cy={452.74}
          r={12.72}
          gradientTransform='matrix(5.27 0 0 1.14 -8985.3 98.38)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.02} stopColor='#f8f8f8' />
          <stop offset={0.23} stopColor='#aeaeae' />
          <stop offset={0.42} stopColor='#707070' />
          <stop offset={0.6} stopColor='#404040' />
          <stop offset={0.75} stopColor='#1d1d1d' />
          <stop offset={0.88} stopColor='#080808' />
          <stop offset={0.96} />
        </radialGradient>
        <radialGradient
          id='prefix__I'
          cx={2231.08}
          cy={452.74}
          r={12.72}
          xlinkHref='#prefix__c'
        />
        <radialGradient
          id='prefix__L'
          cx={2246.82}
          cy={452.74}
          r={12.72}
          gradientTransform='matrix(6.71 0 0 1.14 -12297.18 98.38)'
          xlinkHref='#prefix__c'
        />
        <radialGradient
          id='prefix__w'
          cx={2021.47}
          cy={452.74}
          r={12.72}
          gradientTransform='matrix(6.71 0 0 1.14 -12297.18 98.38)'
          xlinkHref='#prefix__c'
        />
        <radialGradient
          id='prefix__p'
          cx={-2567.58}
          cy={-1732.64}
          r={31.65}
          gradientTransform='matrix(2.91 0 0 .77 10847.07 3159.75)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.1} stopColor='#ededed' />
          <stop offset={0.3} stopColor='silver' />
          <stop offset={0.59} stopColor='#767676' />
          <stop offset={0.94} stopColor='#111' />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id='prefix__am'
          cx={3384.19}
          cy={-5614.46}
          r={367.87}
          gradientTransform='matrix(-1.6 0 0 .1 7441.07 2378.68)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.17} stopColor='#fff' />
          <stop offset={0.25} stopColor='#ddd' />
          <stop offset={0.46} stopColor='#909090' />
          <stop offset={0.65} stopColor='#525252' />
          <stop offset={0.81} stopColor='#262626' />
          <stop offset={0.93} stopColor='#0a0a0a' />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id='prefix__as'
          cx={-391.18}
          cy={-421.66}
          r={314.1}
          gradientTransform='matrix(-.79 .08 .04 .35 413.97 1964.34)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.11} stopColor='#e9e9e9' />
          <stop offset={0.35} stopColor='#b0b0b0' />
          <stop offset={0.69} stopColor='#575757' />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id='prefix__av'
          cx={2914.06}
          cy={2373.55}
          r={30.39}
          gradientTransform='matrix(-2.98 0 0 .19 9324.33 809.72)'
          xlinkHref='#prefix__p'
        />
        <radialGradient
          id='prefix__ay'
          cx={2914.06}
          cy={1282.58}
          r={30.39}
          gradientTransform='matrix(-2.98 0 0 .56 9324.33 550.03)'
          xlinkHref='#prefix__p'
        />
        <radialGradient
          id='prefix__x'
          cx={1944.23}
          cy={452.74}
          r={12.72}
          gradientTransform='matrix(5.27 0 0 1.14 -8985.3 98.38)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.96} stopColor='#fff' />
        </radialGradient>
        <radialGradient
          id='prefix__aF'
          cx={2021.47}
          cy={452.74}
          r={12.72}
          gradientTransform='matrix(6.71 0 0 1.14 -12297.18 98.38)'
          xlinkHref='#prefix__x'
        />
        <radialGradient
          id='prefix__aO'
          cx={2231.08}
          cy={452.74}
          r={12.72}
          xlinkHref='#prefix__x'
        />
        <radialGradient
          id='prefix__aQ'
          cx={2246.82}
          cy={452.74}
          r={12.72}
          gradientTransform='matrix(6.71 0 0 1.14 -12297.18 98.38)'
          xlinkHref='#prefix__x'
        />
        <radialGradient
          id='prefix__aw'
          cx={-2567.58}
          cy={-1732.64}
          r={31.65}
          gradientTransform='matrix(2.91 0 0 .77 10847.07 3159.75)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#807d7e' />
          <stop offset={0.18} stopColor='#918f8f' />
          <stop offset={0.54} stopColor='#bebcbd' />
          <stop offset={1} stopColor='#fff' />
        </radialGradient>
        <radialGradient
          id='prefix__bs'
          cx={3384.19}
          cy={-5614.46}
          r={367.87}
          gradientTransform='matrix(-1.6 0 0 .1 7441.07 2378.68)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.17} stopColor='#040607' />
          <stop offset={0.28} stopColor='#222425' />
          <stop offset={0.53} stopColor='#5f6061' />
          <stop offset={0.74} stopColor='#8c8c8c' />
          <stop offset={0.9} stopColor='#a7a7a7' />
          <stop offset={1} stopColor='#b1b1b1' />
        </radialGradient>
        <radialGradient
          id='prefix__bA'
          cx={-391.18}
          cy={-421.66}
          r={314.1}
          gradientTransform='matrix(-.79 .08 .04 .35 413.97 1964.34)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#040607' />
          <stop offset={0.12} stopColor='#1a1c1d' />
          <stop offset={0.36} stopColor='#535455' />
          <stop offset={0.7} stopColor='#acadad' />
          <stop offset={1} stopColor='#fff' />
        </radialGradient>
        <radialGradient
          id='prefix__bC'
          cx={2914.06}
          cy={2373.55}
          r={30.39}
          gradientTransform='matrix(-2.98 0 0 .19 9324.33 809.72)'
          xlinkHref='#prefix__aw'
        />
        <radialGradient
          id='prefix__bE'
          cx={2914.06}
          cy={1282.58}
          r={30.39}
          gradientTransform='matrix(-2.98 0 0 .56 9102.82 499.99)'
          xlinkHref='#prefix__aw'
        />
        <clipPath id='prefix__aZ' transform='translate(-221.5 -50.04)'>
          <path fill='none' d='M3202.65 1746.76h266.82v105.18h-266.82z' />
        </clipPath>
        <clipPath id='prefix__bw' transform='translate(-221.5 -50.04)'>
          <path
            d='M1608.7 1385.1h862.73a9.52 9.52 0 019.52 9.52v149.28a9.52 9.52 0 01-9.52 9.52H1609.5a10.31 10.31 0 01-10.31-10.31v-148.49a9.52 9.52 0 019.51-9.52z'
            fill='none'
          />
        </clipPath>
      </defs>
      <path fill='#677273' d='M0 0h3600v1910H0z' />
      <g data-name='Layer 4' transform='translate(0 50)'>
        <path
          fill='url(#prefix__az)'
          d='M588.8 530.78L149.21 240.84V18L588.8 304.46v226.32z'
        />
        <path
          fill='url(#prefix__r)'
          d='M654.46 381.34h2251.68v1268.23H654.46z'
        />
        <path fill='url(#prefix__aA)' d='M588.8 304.46h2383v226.32h-2383z' />
        <path fill='#4d4d4d' d='M0 1831.79h3560.59v34.94H0z' />
        <path
          fill='url(#prefix__aB)'
          d='M3560.59 1827.85H0l666.98-178.28h2239.16l654.45 178.28z'
        />
        <path fill='#979797' d='M0 1827.85h3560.59v34.93H0z' />
        <path
          fill='url(#prefix__aC)'
          d='M2906.14 590.6H654.46l-65.66-59.82h2383l-65.66 59.82z'
        />
        {/* 
          Shelves on the wall
        <path
          fill='#dcdcdc00'
          d='M0 0h500v570H0z'
          transform='translate(2300 800)'
        />
        <path
          fill='#fbfbfb'
          d='M2797.54 885.85h-486.02l35.57-11.92h414.88l35.57 11.92z'
        />
        <path fill='#b1b1b1' d='M2311.52 885.85h486.02v2.5h-486.02z' />
        <path
          fill='#f1f2f2'
          d='M2797.54 1105.61h-486.02l35.57-18h414.88l35.57 18z'
        />
        <path fill='#979797' d='M2311.52 1105.61h486.02v3.77h-486.02z' />
        <path
          fill='#e9eaea'
          d='M2797.54 1325.13h-486.02l35.57-23.85h414.88l35.57 23.85z'
        />
        <path fill='#979797' d='M2311.52 1325.13h486.02v4.99h-486.02z' />
        <path
          d='M2820.55 612.94c0 2.76-22.83 5-51 5s-51-2.24-51-5 22.83-5 51-5 51 2.23 51 5z'
          transform='translate(-221.5 -50.04)'
          fill='url(#prefix__aM)'
        /> */}
        <path
          d='M1309.37 612.94c0 2.76-22.83 5-51 5s-51-2.24-51-5 22.83-5 51-5 51 2.23 51 5z'
          transform='translate(-220 -50.04)'
          fill='url(#prefix__F)'
        /> 
        <path
          d='M1309.37 612.94c0 2.76-22.83 5-51 5s-51-2.24-51-5 22.83-5 51-5 51 2.23 51 5z'
          transform='translate(550 -50.04)'
          fill='url(#prefix__F)'
        /> 
        <path
          d='M1309.37 612.94c0 2.76-22.83 5-51 5s-51-2.24-51-5 22.83-5 51-5 51 2.23 51 5z'
          transform='translate(1320 -50.04)'
          fill='url(#prefix__F)'
        /> 
        {eventActivities}
        <g mask='url(#prefix__aN)'>
          <path
            d='M2835.48 612.65c0 8-30 14.44-67 14.44s-67-6.46-67-14.44 30-14.44 67-14.44 67 6.46 67 14.44z'
            transform='translate(-221.5 -50.04)'
            fill='url(#prefix__aO)'
          />
        </g>
        <g mask='url(#prefix__aP)'>
          <path
            d='M2854.84 612.65c0 8-38.18 14.44-85.28 14.44s-85.26-6.46-85.26-14.44 38.17-14.44 85.26-14.44 85.28 6.46 85.28 14.44z'
            transform='translate(-221.5 -50.04)'
            fill='url(#prefix__aQ)'
          />
        </g>
        <g mask='url(#prefix__aX)'>
          <path
            opacity={0.3}
            fill='url(#prefix__aY)'
            style={{
              mixBlendMode: 'multiply',
            }}
            d='M0 1827.85l1776.62-178.28H666.98L0 1827.85z'
          />
        </g>
        {products}
        <g mask='url(#prefix__bh)'>
          <path
            opacity={0.5}
            fill='url(#prefix__bi)'
            style={{
              mixBlendMode: 'multiply',
            }}
            d='M186.28 265.6l56.01 37.2 339.9-2.09-74.45-48.54H186.28v13.43z'
          />
        </g>
        <path
          d='M3482.18 1806.48V9.36h-3333v242.6h2320.68c672.74 0 772.69-1.72 772.69 631.37v923.19z'
          fill={colorScheme.primary_colorDark}
        />
        <path
          d='M3703.68 1856.52V50h-3333v242.6h2320.68c672.74 0 782.69-1.72 782.69 631.37v932.55z'
          transform='translate(-221.5 -50.04)'
          fill='url(#prefix__bj)'
        />
        <path fill='url(#prefix__bk)' d='M149.21 0h37.07v1811.01h-37.07z' />
        <g mask='url(#prefix__bl)'>
          <path
            fill='url(#prefix__bm)'
            style={{
              mixBlendMode: 'multiply',
            }}
            d='M149.21 240.84h37.07v607.15h-37.07z'
          />
        </g>
        <path
          fill='url(#prefix__bn)'
          d='M654.46 1647.03h2251.68v5.09H654.46z'
        />
        {overview}
        <g mask='url(#prefix__bz)'>
          <path
            d='M444.25 1812.86c5.92 56.39 114.6 91.2 242.74 77.74s290.3-76.71 284.38-133.1-177.69-84.58-305.83-71.12-227.21 70.08-221.29 126.48z'
            transform='translate(-221.5 -50.04)'
            opacity={0.5}
            fill='url(#prefix__bA)'
            style={{
              mixBlendMode: 'multiply',
            }}
          />
        </g>
        {resources}
        {staff}
        {/* <path
          fill='#ffffff'
          d='M0 0h500v500H0z'
          transform='translate(1550 650)'
        /> */}
        {/* <image
          xlinkHref={props.exhibit.logo_address}
          height='500'
          width='500'
          x='1550'
          y='650'
        /> */}
      </g>
    </svg>
  );
}

export default ExhibitBooth;
