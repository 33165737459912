import { SET_CHAT_MEMBERSHIP, NEW_CHAT_MESSAGE, 
  SELECT_ACTIVE_PRIVATE_CHAT, SELECT_ACTIVE_GROUP_CHAT, 
  GET_PRIVATE_CHAT_MESSAGES,
  NEW_CHAT_MEMBERSHIP, VIEW_PRIVATE_CHAT, REMOVE_ALL_CHAT
} from '../Actions/types';

const initialState = {
  memberships: [],
  subscriptions: [],
  users: [],
  activePrivateChat: null,
  activePrivateMember: null,
  activePrivateChatMessages: [],
  activeGroupChat: null,
  activeGroupChatMessages: [],
  alert: null,
};

export default function(state=initialState, action) {
  switch (action.type) {
    case SET_CHAT_MEMBERSHIP:
      action.payload.memberships.map((membership) => membership.newMessage = 0)
        return {
          ...state,
          memberships: action.payload.memberships,
          subscriptions: action.payload.memberships,
          users: action.payload.users
      };
      case GET_PRIVATE_CHAT_MESSAGES:
        return {
          ...state,
          activePrivateChatMessages: action.payload.messages,
      };
      case VIEW_PRIVATE_CHAT:

      const membershipIndex = state.memberships.findIndex((membership => membership.channel.id === action.payload));

      if(membershipIndex > -1){
        let newMemberships = [...state.memberships]
        newMemberships[membershipIndex].newMessage = 0

        return { 
          ...state, 
          memberships: [...newMemberships]
       }
      }
      else {
        return state;
      }
      case NEW_CHAT_MEMBERSHIP:
        return {
          ...state,
          activeGroupChatMessages: [
            ...state.memberships,
            action.payload.membership
          ]
      };
    case NEW_CHAT_MESSAGE:
      if(action.payload.message.channel === state.activePrivateChat){
        return {
          ...state,
          activePrivateChatMessages: [
            ...state.activePrivateChatMessages,
            action.payload.message
          ],
          alert: action.payload.message
        }
      } 
      else {
        //this is where messages from other channels should modify the membership objects

        const membershipIndex = state.memberships.findIndex((membership => membership.channel.id === action.payload.message.channel));

        if(membershipIndex > -1){
          let newMemberships = [...state.memberships]
          newMemberships[membershipIndex].newMessage ++

          return { 
            ...state, 
            memberships: [...newMemberships],
            alert: action.payload.message
         }
        }
        else {
          return state
        }
      }
    
    case SELECT_ACTIVE_PRIVATE_CHAT:
      return {
        ...state,
        activePrivateChat: action.payload.channel,
        activePrivateMember: action.payload.member
      } 
    case SELECT_ACTIVE_GROUP_CHAT:
      return {
        ...state,
        activeGroupChat: action.payload.message
      }   
    case REMOVE_ALL_CHAT:
      return {
        ...state,
          memberships: [],
          subscriptions: [],
          users: [],
          activePrivateChat: null,
          activePrivateMember: null,
          activePrivateChatMessages: [],
          activeGroupChat: null,
          activeGroupChatMessages: [],
          alert: null,
      }          
    default:
      return state;
  }
}