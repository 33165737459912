import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import trackAction from '../../Utilities/tracker';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import IconButton from '@material-ui/core/IconButton';
import getValidUrl from '../../Utilities/getValidUrl'

//redux
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement'
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement'


const useStyles = theme => ({
  root: {
    width: '100%',
    '& .MuiTypography-root': {
      alignSelf: 'center',
    },
    '& .MuiChip-root	': {
      alignSelf: 'center',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0.5),
    },
  },
  expansionPanelMain: {
    padding: '0px 8px 0px 24px',
    backgroundColor: '#f2f2f2',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: '66.66%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0,
  },
  panelExpanded: {
    '& .MuiGrid-item': {
      textAlign: 'center',
    },
  },
  icon: {
    maxHeight: '30vh',
    maxWidth: '100%',
  },

  buttonLearn: {
    minWidth: '80%',
    fontSize: theme.typography.pxToRem(15),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  textBody: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: 'center',
    textAlign: 'left',
  },
});

class ExhibitProductItem extends Component {

  recordEvent = () => {

    const event = {
      action: "Clicked",
      resource_type: "Product",
      resource_id: this.props.product.product_id, 
      url_visited: '/exhibithall',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: this.props.product.account_id,
      resource_account_name: this.props.product.name,
      resource_name: this.props.product.name,
      //old fields
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    }


    trackAction(
    event
    )
  }

  render(){
    const { classes } = this.props;

  let favorited = false;

  if (this.props.briefcase.briefcase){
    for (let n = 0 ; n < this.props.briefcase.briefcase.length; n++){
      if (this.props.briefcase.briefcase[n].resource_id === this.props.product.product_id){
        favorited = true
        break
      }
    }
  }

  return (
    <Accordion
      className={classes.root}
      onClick={this.recordEvent}
    >
      <AccordionSummary
        className={classes.expansionPanelMain}
        expandIcon={<ExpandMoreIcon />}
      >
                { favorited ?
        <IconButton aria-label="remove from briefcase" onClick={()=>this.props.deleteBriefcaseElement(this.props.product.product_id)}>
          <WorkIcon />
        </IconButton>
        :
        <IconButton aria-label="add to briefcase" onClick={()=>this.props.addBriefcaseElement("Product", this.props.product.product_id, this.props.product.name, this.props.product.account_name)}>
          <WorkOutlineIcon />
        </IconButton>
        }
        <Typography variant='h6' align='left' className={classes.heading}>
          {this.props.product.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          className={classes.panelExpanded}
          container
          direction='column'
          spacing={2}
          alignItems='center'
          justify='space-between'
        >
          <Grid item xs={12} sm={12}>
            <img
              className={classes.icon}
              src={this.props.product.product_image_url}
              alt=''
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <pre style={{ whiteSpace: "pre-line", wordWrap: "break-word", margin: 0}}>
            <Typography className={classes.textBody}>
              {this.props.product.description}
            </Typography>
              </pre>
          </Grid>
          <Grid item container xs={12} direction='row' justify='center'>
            {this.props.product.product_video_url ? 
          <Grid item xs={6} sm={6}>
            <Button
              className={classes.buttonLearn}
              variant='contained'
              target="_blank"
              href={getValidUrl(this.props.product.product_video_url)}
            >
              Product Video
            </Button>
          </Grid> :
          <React.Fragment/> }
          {this.props.product.product_url ? 
          <Grid item xs={6} sm={6}>
            <Button
              className={classes.buttonLearn}
              variant='contained'
              target="_blank"
              href={getValidUrl(this.props.product.product_url)}
            >
              Learn More
            </Button>
          </Grid> :
          <React.Fragment/>
        }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ExhibitProductItem));