import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import BrochureStand from '../../Assets/SVG/brochure_stand.svg';
import SingleStandingKiosk from '../../Assets/SVG/singleStandingKiosk.svg';

const useStyles = makeStyles((theme) => ({
  clickable: {
    cursor: 'pointer',
  },
}));

function ExhibitBoothImage(props) {
  const classes = useStyles();
  const [colorScheme, setColorScheme] = useState({
    primary_color: '',
    primary_colorDark: '',
    primary_colorLight: '',
    secondary_color: '',
  });

  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    var R = parseInt(col.substring(0, 2), 16);
    var G = parseInt(col.substring(2, 4), 16);
    var B = parseInt(col.substring(4, 6), 16);
    // to make the colour less bright than the input
    // change the following three "+" symbols to "-"
    R = R + amt;
    G = G + amt;
    B = B + amt;

    if (R > 255) R = 255;
    else if (R < 0) R = 0;

    if (G > 255) G = 255;
    else if (G < 0) G = 0;

    if (B > 255) B = 255;
    else if (B < 0) B = 0;

    var RR =
      R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    var GG =
      G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    var BB =
      B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

    return (usePound ? '#' : '') + RR + GG + BB;
  };

  useEffect(() => {

    let primary;
    let secondary;
    if (props.exhibit.primary_color) {
      primary = props.exhibit.primary_color;
    } else {
      primary = '#6f1a1d';
    }
    if (props.exhibit.secondary_color) {
      secondary = props.exhibit.secondary_color;
    } else {
      secondary = '#6f1a1d';
    }
    let dark = LightenDarkenColor(primary, -40);
    let light = LightenDarkenColor(primary, 40);
    setColorScheme({
      primary_color: primary,
      primary_colorDark: dark,
      primary_colorLight: light,
      secondary_color: secondary,
    });
  }, [props.exhibit.primary_color, props.exhibit.secondary_color]);

  let noSelected = 'url(#deselected)';
  let yesSelected = 'url(#selected)';

  let overview;
  if (true) {
    overview = (
      <Tooltip title='Overview' placement='bottom'>
        <g
          className={classes.clickable}
          onClick={(event) => props.handleTabChange(event, 0)}
          style={{ filter: props.tabValue === 1 ? yesSelected : noSelected }}
        >
          {/* Curved desk
          <path
            d='M327.83 361.8v124.69s90.85 50.62 182.65 0V361.8s-87.7 14.76-182.65 0z'
            transform='translate(-30.52 -14.05)'
            fill='url(#prefix__p)'
          /> */}
          <rect
            width='182'
            height='150'
            x='298'
            y='350'
            // fill='#ffffff'
            fill='url(#prefix__p)'
          />
          <path
            d='M289.22 344.39l57.77-3s44.56 9.09 73.56.39l71.93 1.43s-92.92 25.9-203.26 1.18z'
            fill={colorScheme.primary_color}
          />
          <path
            d='M289.14 344.32v1.85s101.7 22.94 203.56-1.18v-1.78s-93.06 24.97-203.56 1.11z'
            fill={colorScheme.secondary_color}
          />
          <rect width='120' height='120' x='330' y='370' fill={colorScheme.secondary_color} />
          <rect width='110' height='110' x='335' y='375' fill='#ffffff' />
          <image
            xlinkHref={props.exhibit.logo_address}
            height='100'
            width='100'
            x='340'
            y='380'
          />
        </g>
      </Tooltip>
    );
  }

  let resources;
  if (true) {
    resources = (
      // <Tooltip title='Resources' placement='bottom'>
        <g
          // className={classes.clickable}
          // onClick={(event) => props.handleTabChange(event, 1)}
          // style={{ filter: props.tabValue === 1 ? yesSelected : noSelected }}
        >
          <path
            d='M537.79 360.44v6l17.6 49a6 6 0 000-5z'
            transform='translate(-30.52 -14.05)'
            fill='url(#prefix__x)'
          />
          <path
            d='M644.66 505.45s1.84-5-.84-7.71l-17.27-18.28-64.88 9.23 11.74 24.14s2.85 5.36.67 7.54z'
            transform='translate(-30.52 -14.05)'
            fill='url(#prefix__y)'
          />
          <path
            d='M561.67 488.77v5l12.41 26.73s2.14-2.13-.42-7.12z'
            transform='translate(-30.52 -14.05)'
            fill='url(#prefix__z)'
          />
          <path
            fill='url(#prefix__A)'
            d='M610.01 391.85l-63.57 7.24v94.15l58.34-11.67 5.23-89.72z'
          />
          <path
            fill='url(#prefix__B)'
            d='M546.41 493.28l-7.54-18.98v-82.15l7.88 4.53-.32 96.6h-.02z'
          />
          <path
            d='M672.82 400.17s2.26 3.14.25 4.53l-117.68 10.81a5.92 5.92 0 000-5c1.38-.41 117.43-10.34 117.43-10.34z'
            transform='translate(-30.52 -14.05)'
            fill='url(#prefix__C)'
          />
          <path
            fill='url(#prefix__D)'
            d='M642.3 386.12l-117.43 10.31-17.61-50.05 100.07-5.53 34.97 45.27z'
          />
          <path
            opacity={0.83}
            fill='url(#prefix__E)'
            d='M626.29 379.68l-90.46 7.94-16.51-37.46 82.98-4.95 23.99 34.47z'
          />
        </g>
      // </Tooltip>
    );
  }

  

  let products;
  if (true) {
    products = (
      <Tooltip title='Products' placement='left'>
        <g
          className={classes.clickable}
          onClick={(event) => props.handleTabChange(event, 1)}
          style={{ filter: props.tabValue === 2 ? yesSelected : noSelected }}
        >
          <image
            xlinkHref={BrochureStand}
            height='250'
            width='120'
            x='100'
            y='200'
          />
        </g>
      </Tooltip>
    );
  }

  let staff;
  if (true) {
    staff = (
        <image xlinkHref={SingleStandingKiosk} height='240' width='100' x='185' y='260' />
      // <Tooltip title='Staff' placement='bottom'>
      //   <g
      //     className={classes.clickable}
      //     onClick={(event) => props.handleTabChange(event, 3)}
      //     style={{ filter: props.tabValue === 3 ? yesSelected : noSelected }}
      //   >
      //     <g data-name='SingleStandingKiosk'>
      //   </g>
      //   </g>
      // </Tooltip>
    );
  }
  let eventActivities;
  if (true) {
    eventActivities = (
      <Tooltip title='Event Activities' placement='right'>
        <g
          className={classes.clickable}
          onClick={(event) => props.handleTabChange(event, 2)}
          style={{ filter: props.tabValue === 3 ? yesSelected : noSelected }}
          transform='translate(520 0)'
        >
          <path fill='url(#prefix__b)' d='M91.73 235.32h86.42v206.49H91.73z' />
          <path fill='url(#prefix__c)' d='M89.45 233.24h90.99v2.08H89.45z' />
          <path
            fill='url(#prefix__d)'
            d='M90.59 439.27l-4.78 9.35-.32 1.87h98.31v-1.71l-4.62-9.51H90.59z'
          />
          {/* <image
            xlinkHref={MerckLogo}
            height='40'
            width='80'
            x='95'
            y='280'
          /> */}
        </g>
      </Tooltip>
    );
  }

  return (
    <svg viewBox='0 0 796.5 553.72'>
      <defs>
        <filter id='selected'>
          <feDropShadow dx='0' dy='0' stdDeviation='6' floodColor='#a8c0ff'>
            <animate
              attributeName='stdDeviation'
              values='6;6;0;6;6'
              dur='4s'
              repeatCount='indefinite'
            />
          </feDropShadow>

          <feComponentTransfer>
            <feFuncA type='linear' slope='3' />
            {/* <!-- slope is the opacity of the shadow --> */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            {/* <!-- this contains the offset blurred image --> */}
            <feMergeNode in='SourceGraphic' />
            {/* <!-- this contains the element that the filter is applied to --> */}
          </feMerge>
        </filter>
        <filter id='deselected'>
          <feDropShadow dx='0' dy='0' stdDeviation='2' floodColor='#efefef'>
            <animate
              attributeName='stdDeviation'
              values='0;2;0;0;0;2;0'
              dur='10s'
              repeatCount='indefinite'
            />
          </feDropShadow>
          <feDropShadow dx='0' dy='0' stdDeviation='3' floodColor='#3f3f3f'>
            <animate
              attributeName='stdDeviation'
              values='0;3;0;0;0;3;0'
              dur='10s'
              repeatCount='indefinite'
            />
          </feDropShadow>

          <feComponentTransfer>
            <feFuncA type='linear' slope='2' />
            {/* <!-- slope is the opacity of the shadow --> */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            {/* <!-- this contains the offset blurred image --> */}
            <feMergeNode in='SourceGraphic' />
            {/* <!-- this contains the element that the filter is applied to --> */}
          </feMerge>
        </filter>
        <linearGradient
          id='prefix__j'
          x1={398.29}
          y1={409.38}
          x2={398.29}
          y2={154.45}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#444b4c' />
          <stop offset={0.71} stopColor='#231f20' />
        </linearGradient>
        <linearGradient
          id='prefix__k'
          x1={390.21}
          y1={87.2}
          x2={390.21}
          y2={257.19}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#e2e4e7' />
          <stop offset={0.17} stopColor='#edeff1' />
          <stop offset={0.41} stopColor='#f5f6f7' />
          <stop offset={0.9} stopColor='#f7f8f9' />
        </linearGradient>
        <linearGradient
          id='prefix__l'
          x1={390.22}
          y1={89.09}
          x2={390.22}
          y2={98.98}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#c9cdd3' />
          <stop offset={0.03} stopColor='#dadde1' />
          <stop offset={0.06} stopColor='#ebecee' />
          <stop offset={0.1} stopColor='#f6f7f8' />
          <stop offset={0.16} stopColor='#fdfdfd' />
          <stop offset={0.31} stopColor='#fff' />
          <stop offset={0.5} stopColor='#c9cdd3' />
          <stop offset={0.74} stopColor='#fff' />
          <stop offset={1} stopColor='#231f20' />
        </linearGradient>
        <linearGradient
          id='prefix__p'
          x1={327.83}
          y1={435.39}
          x2={510.48}
          y2={435.39}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#dfdfdf' />
          {/* <stop offset={0.24} stopColor='#fff' />
          <stop offset={0.47} stopColor='#fafbfb' />
          <stop offset={0.75} stopColor='#eceef0' />
          <stop offset={0.89} stopColor='#e2e4e7' /> */}
          <stop offset={1} stopColor='#d5d8dd' />
        </linearGradient>
        <linearGradient
          id='prefix__b'
          x1={134.94}
          y1={227.02}
          x2={134.94}
          y2={349.59}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#eaebed' />
          <stop offset={0.06} stopColor='#f1f2f3' />
          <stop offset={0.16} stopColor='#f8f8f9' />
          <stop offset={0.46} stopColor='#fafafb' />
        </linearGradient>
        <linearGradient
          id='prefix__c'
          x1={134.94}
          y1={233.56}
          x2={134.94}
          y2={235.32}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.45} stopColor='#c9cdd3' />
          <stop offset={0.61} stopColor='#fff' />
          <stop offset={1} stopColor='#231f20' />
        </linearGradient>
        <linearGradient
          id='prefix__d'
          x1={134.65}
          y1={437.4}
          x2={134.65}
          y2={454.23}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.11} stopColor='#737a7f' />
          <stop offset={0.18} stopColor='#fff' />
          <stop offset={0.34} stopColor='#9ea4ab' />
          <stop offset={0.54} stopColor='#fff' />
          <stop offset={0.84} stopColor='#444b4c' />
        </linearGradient>
        <linearGradient
          id='prefix__r'
          x1={647.92}
          y1={227.02}
          x2={647.92}
          y2={349.59}
          xlinkHref='#prefix__b'
        />
        <linearGradient
          id='prefix__s'
          x1={647.92}
          y1={233.56}
          x2={647.92}
          y2={235.32}
          xlinkHref='#prefix__c'
        />
        <linearGradient
          id='prefix__t'
          x1={647.62}
          y1={437.4}
          x2={647.62}
          y2={454.23}
          xlinkHref='#prefix__d'
        />
        <linearGradient
          id='prefix__e'
          x1={296.16}
          y1={417.17}
          x2={296.16}
          y2={360.72}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#d1d3d4' />
          <stop offset={1} stopColor='#a7a9ac' />
        </linearGradient>
        <linearGradient
          id='prefix__g'
          x1={239.54}
          y1={521.67}
          x2={239.54}
          y2={479.74}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.28} stopColor='#f9f9f9' />
          <stop offset={0.66} stopColor='#e7e8e9' />
          <stop offset={1} stopColor='#d1d3d4' />
        </linearGradient>
        <linearGradient
          id='prefix__u'
          x1={274.72}
          y1={521.51}
          x2={274.72}
          y2={489.04}
          xlinkHref='#prefix__e'
        />
        <linearGradient
          id='prefix__f'
          x1={203.74}
          y1={496.09}
          x2={203.74}
          y2={392.17}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.53} stopColor='#fdfdfd' />
          <stop offset={0.71} stopColor='#f6f7f7' />
          <stop offset={0.85} stopColor='#ebebec' />
          <stop offset={0.96} stopColor='#dadbdc' />
          <stop offset={1} stopColor='#d1d3d4' />
        </linearGradient>
        <linearGradient
          id='prefix__v'
          x1={239.17}
          y1={496.13}
          x2={239.17}
          y2={392.47}
          xlinkHref='#prefix__e'
        />
        <linearGradient
          id='prefix__h'
          x1={228.37}
          y1={416.15}
          x2={228.37}
          y2={400.43}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.31} stopColor='#fbfcfc' />
          <stop offset={0.59} stopColor='#f1f1f1' />
          <stop offset={0.86} stopColor='#dee0e0' />
          <stop offset={1} stopColor='#d1d3d4' />
        </linearGradient>
        <linearGradient
          id='prefix__w'
          x1={207.19}
          y1={398.1}
          x2={207.19}
          y2={341.14}
          xlinkHref='#prefix__f'
        />
        <linearGradient
          id='prefix__x'
          x1={563.95}
          y1={416.93}
          x2={563.95}
          y2={360.48}
          gradientTransform='matrix(-1 0 0 1 1110.8 0)'
          xlinkHref='#prefix__e'
        />
        <linearGradient
          id='prefix__y'
          x1={507.33}
          y1={521.42}
          x2={507.33}
          y2={479.49}
          gradientTransform='matrix(-1 0 0 1 1110.8 0)'
          xlinkHref='#prefix__g'
        />
        <linearGradient
          id='prefix__z'
          x1={542.51}
          y1={521.27}
          x2={542.51}
          y2={488.79}
          gradientTransform='matrix(-1 0 0 1 1110.8 0)'
          xlinkHref='#prefix__e'
        />
        <linearGradient
          id='prefix__A'
          x1={502.06}
          y1={509.9}
          x2={502.06}
          y2={405.97}
          gradientTransform='matrix(-1 0 0 1 1080.28 -14.05)'
          xlinkHref='#prefix__f'
        />
        <linearGradient
          id='prefix__B'
          x1={537.48}
          y1={509.93}
          x2={537.48}
          y2={406.28}
          gradientTransform='matrix(-1 0 0 1 1080.28 -14.05)'
          xlinkHref='#prefix__e'
        />
        <linearGradient
          id='prefix__C'
          x1={496.16}
          y1={415.91}
          x2={496.16}
          y2={400.18}
          gradientTransform='matrix(-1 0 0 1 1110.8 0)'
          xlinkHref='#prefix__h'
        />
        <linearGradient
          id='prefix__D'
          x1={505.5}
          y1={411.91}
          x2={505.5}
          y2={354.95}
          gradientTransform='matrix(-1 0 0 1 1080.28 -14.05)'
          xlinkHref='#prefix__f'
        />
        <radialGradient
          id='prefix__m'
          cx={321.17}
          cy={347.12}
          r={149.47}
          gradientTransform='rotate(45 321.167 347.122)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#e6e7e8' />
          <stop offset={1} stopColor='#fff' />
        </radialGradient>
        <radialGradient
          id='prefix__n'
          cx={563.87}
          cy={296.51}
          r={170.82}
          gradientTransform='rotate(5.61 563.746 296.45)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#f1f2f2' />
          <stop offset={1} stopColor='#fff' />
        </radialGradient>
        <radialGradient
          id='prefix__o'
          cx={143.57}
          cy={550.44}
          r={134.66}
          gradientTransform='matrix(1.88 0 0 .38 136.92 294.21)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#808285' />
          <stop offset={0.14} stopColor='#a1a3a5' />
          <stop offset={0.3} stopColor='#c3c4c5' />
          <stop offset={0.47} stopColor='#dddedf' />
          <stop offset={0.64} stopColor='#f0f0f1' />
          <stop offset={0.81} stopColor='#fbfbfb' />
          <stop offset={1} stopColor='#fff' />
        </radialGradient>
        <radialGradient
          id='prefix__a'
          cx={216.25}
          cy={535.23}
          r={54.72}
          gradientTransform='matrix(1.11 0 0 .11 -77.66 403.75)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.01} stopColor='#9ea4ab' />
          <stop offset={1} stopColor='#fff' />
        </radialGradient>
        <radialGradient
          id='prefix__q'
          cx={676.77}
          cy={535.23}
          r={54.72}
          xlinkHref='#prefix__a'
        />
        <radialGradient
          id='prefix__i'
          cx={184.7}
          cy={352.16}
          r={59.29}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0.04} stopColor='#d1d3d4' />
          <stop offset={1} stopColor='#58595b' />
        </radialGradient>
        <radialGradient
          id='prefix__E'
          cx={483.02}
          cy={365.97}
          r={59.29}
          gradientTransform='matrix(-1 0 0 1 1080.28 -14.05)'
          xlinkHref='#prefix__i'
        />
      </defs>
      <g data-name='Layer 1'>
        <path fill='#231f20' d='M0 385.49h796.41v168.23H0z' />
        <path fill='url(#prefix__j)' d='M.07 0H796.5v385.55H.07z' />
        <g id='floor' transform='translate(-8 0)'>
          <path
            fill={'#58595b'}
            d='M768 519.8L662.75 387.3l-525.83 2.21L33.4 519.84l-4.11 1.52v3.68l.21-.03H772l.22.03v-3.68L768 519.8z'
          />
          <path
            id='carpet'
            fill={colorScheme.primary_color}
            d='M136.92 385.86L29.29 521.36h742.85L662.75 383.64l-525.83 2.22z'
          />
        </g>
        <g id='wall'>
          <path
            id='wallLogo'
            fill='white'
            d='M184.86 98.71h410.71v286.37H184.86z'
          />
          {/* <image
            xlinkHref={props.exhibit.logo_address}
            height='288'
            width='288'
            x='245'
            y='97'
          /> */}
          <path fill='url(#prefix__l)' d='M185.07 87.35h410.29v11.63H185.07z' />
        </g>
        <circle
          cx={321.17}
          cy={347.12}
          r={149.47}
          transform='rotate(-45 288.95 376.933)'
          fill='url(#prefix__m)'
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <circle
          cx={563.87}
          cy={296.51}
          r={170.82}
          transform='rotate(-5.61 405.108 600.883)'
          fill='url(#prefix__n)'
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d='M617.24 499c0 22.23-89.83 40.25-200.63 40.25S216 521.25 216 499s89.83-40.26 200.64-40.26 200.6 18.05 200.6 40.26z'
          transform='translate(-30.52 -14.05)'
          fill='url(#prefix__o)'
          style={{
            mixBlendMode: 'multiply',
          }}
        />

        {eventActivities}
        {products}
        {staff}
        {resources}
        {overview}
      </g>
    </svg>
  );
}

export default ExhibitBoothImage;
