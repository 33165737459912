import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { eventId } from '../config';

// redux
import { connect } from 'react-redux';


const useStyles = theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
});

class Settings extends Component{
    constructor(props) {
        super(props);
        this.state = {
            optOut: false,
        };
      }

      componentDidMount(){
      Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/optout/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          }
        })
          .then((response) => {
            if(response.data.length){
              if(!response.data[0].opted_out){
                this.setState({
                  optOut: false
                })
              } else { 
                this.setState({
                  optOut: true
                })
              }
            } else {
              this.setState({
                optOut: false
              })
            }
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not process your submission. ${error}`)
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not process your submission. ${error}`)
        }
      });
      }

      optOut(){
        Auth.currentSession()
      .then((data) => {
        axios({
          method: 'post',
          url: `/optout/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            opted_out: true
          },
        })
          .then((response) => {
              this.setState({
                optOut: true
              })
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not process your submission. ${error}`)
            }
          });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not process your submission. ${error}`)
        }
      });

      }

      optIn(){

        Auth.currentSession()
        .then((data) => {
          axios({
            method: 'post',
            url: `/optout/${eventId}`,
            headers: {
              idtoken: data.idToken.jwtToken,
            },
            data: {
              opted_out: false
            },
          })
            .then((response) => {
                this.setState({
                  optOut: false
                })
            })
            .catch((error) => {
              if (this.props.debug.debug) {
                alert(`Error: Could not process your submission. ${error}`)
              }
            });
        })
        .catch((error) => {
          if (this.props.debug.debug) {
            alert(`Error: Could not process your submission. ${error}`)
          }
        });

      }


      handleChange = () => {

        if(this.state.optOut){
          this.optIn()
        } else {
          this.optOut()
        }

      }

    render(){
      const { classes } = this.props;
        return(
            <Grid container layout={"row"} spacing={2} className={classes.root} style={{marginTop: '15px'}}>
              <Grid item xs={12} md={12} lg={12}>
                <Card >
                  <CardContent>
                    <Typography variant='h4' align='center' gutterBottom>
                    Opt-Out
                    </Typography>
                    <Typography variant='body1' align='justify'>
                    Exhibitors and Sponsors help us make this event successful!  Exhibitors will be provided with your lead information, if you show interest in that Company or their products/services. 
                    Please check the privacy page for more information. If you would like your contact information to not be disseminated to exhibitors, you may opt-out below.  You may change your status at any time by going to the Settings page.
                    </Typography>
                    <br/>
                    <Button onClick={() => this.handleChange()}>
                      <Typography variant='body1' align='justify'>Opt In</Typography>
                        <Switch
                          checked={this.state.optOut}
                          onChange={this.handleChange}
                          color = "primary"
                        />
                      <Typography variant='body1' align='justify'>Opt Out</Typography>
                    </Button>
                    {this.state.optOut ? 
                    <Typography variant='body1' align='justify'>You have opted out</Typography>:
                    <Typography variant='body1' align='justify'></Typography>  
                    }
                  </CardContent>
                </Card>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
  return {
      debug: state.debug,
  }
}

const MyTheme = {
    button: { backgroundColor: "#E13E2F"},
  };

export default connect(mapStateToProps, null)(withStyles(useStyles)(withAuthenticator(Settings, false, [], null, MyTheme)));