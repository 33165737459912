/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7e51d4d6-b143-4498-b08a-ef3cd6e9ae18",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8rTzkYNvd",
    "aws_user_pools_web_client_id": "7au41i72oln14o7roo6khvm615",
    "oauth": {},
    "aws_user_files_s3_bucket": "planetconnectadminstorage104656-local",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
