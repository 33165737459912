import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NavBar from '../../Components/Navbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckIcon from '@material-ui/icons/Check';
import Privacy from '../Privacy'
import DialogWrapper from '../../Components/DialogWrapper'
import { landingPage, eventId, accountId } from '../../config';
import { 
        Link, 
        // Redirect, 
        withRouter 
    } from "react-router-dom";

import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import setAuthenticatedUser from '../../Store/Actions/setUser';
import setBriefcase from '../../Store/Actions/setBriefcase';
import setEventDetails from '../../Store/Actions/setEventDetails';

// const countryList = [
// "United States",
// "Afghanistan",
// "Åland Islands",
// "Albania",
// "Algeria",
// "American Samoa",
// "Andorra",
// "Angola",
// "Anguilla",
// "Antarctica",
// "Antigua and Barbuda",
// "Argentina",
// "Armenia",
// "Aruba",
// "Australia",
// "Austria",
// "Azerbaijan",
// "Bahamas",
// "Bahrain",
// "Bangladesh",
// "Barbados",
// "Belarus",
// "Belgium",
// "Belize",
// "Benin",
// "Bermuda",
// "Bhutan",
// "Bolivia",
// "Bosnia and Herzegovina",
// "Botswana",
// "Bouvet Island",
// "Brazil",
// "British Indian Ocean Territory",
// "Brunei Darussalam",
// "Bulgaria",
// "Burkina Faso",
// "Burundi",
// "Cambodia",
// "Cameroon",
// "Canada",
// "Cape Verde",
// "Cayman Islands",
// "Central African Republic",
// "Chad",
// "Chile",
// "China",
// "Christmas Island",
// "Cocos (Keeling) Islands",
// "Colombia",
// "Comoros",
// "Congo",
// "Congo, The Democratic Republic of The",
// "Cook Islands",
// "Costa Rica",
// "Cote D'ivoire",
// "Croatia",
// "Cuba",
// "Cyprus",
// "Czechia",
// "Denmark",
// "Djibouti",
// "Dominica",
// "Dominican Republic",
// "Ecuador",
// "Egypt",
// "El Salvador",
// "Equatorial Guinea",
// "Eritrea",
// "Estonia",
// "Ethiopia",
// "Falkland Islands (Malvinas)",
// "Faroe Islands",
// "Fiji",
// "Finland",
// "France",
// "French Guiana",
// "French Polynesia",
// "French Southern Territories",
// "Gabon",
// "Gambia",
// "Georgia",
// "Germany",
// "Ghana",
// "Gibraltar",
// "Greece",
// "Greenland",
// "Grenada",
// "Guadeloupe",
// "Guam",
// "Guatemala",
// "Guernsey",
// "Guinea",
// "Guinea-bissau",
// "Guyana",
// "Haiti",
// "Heard Island and Mcdonald Islands",
// "Holy See (Vatican City State)",
// "Honduras",
// "Hong Kong",
// "Hungary",
// "Iceland",
// "India",
// "Indonesia",
// "Iran, Islamic Republic of",
// "Iraq",
// "Ireland",
// "Isle of Man",
// "Israel",
// "Italy",
// "Jamaica",
// "Japan",
// "Jersey",
// "Jordan",
// "Kazakhstan",
// "Kenya",
// "Kiribati",
// "Korea, Democratic People's Republic of",
// "Korea, Republic of",
// "Kuwait",
// "Kyrgyzstan",
// "Lao People's Democratic Republic",
// "Latvia",
// "Lebanon",
// "Lesotho",
// "Liberia",
// "Libyan Arab Jamahiriya",
// "Liechtenstein",
// "Lithuania",
// "Luxembourg",
// "Macao",
// "Macedonia, The Former Yugoslav Republic of",
// "Madagascar",
// "Malawi",
// "Malaysia",
// "Maldives",
// "Mali",
// "Malta",
// "Marshall Islands",
// "Martinique",
// "Mauritania",
// "Mauritius",
// "Mayotte",
// "Mexico",
// "Micronesia, Federated States of",
// "Moldova, Republic of",
// "Monaco",
// "Mongolia",
// "Montenegro",
// "Montserrat",
// "Morocco",
// "Mozambique",
// "Myanmar",
// "Namibia",
// "Nauru",
// "Nepal",
// "Netherlands",
// "Netherlands Antilles",
// "New Caledonia",
// "New Zealand",
// "Nicaragua",
// "Niger",
// "Nigeria",
// "Niue",
// "Norfolk Island",
// "Northern Mariana Islands",
// "Norway",
// "Oman",
// "Pakistan",
// "Palau",
// "Palestinian Territory, Occupied",
// "Panama",
// "Papua New Guinea",
// "Paraguay",
// "Peru",
// "Philippines",
// "Pitcairn",
// "Poland",
// "Portugal",
// "Puerto Rico",
// "Qatar",
// "Reunion",
// "Romania",
// "Russian Federation",
// "Rwanda",
// "Saint Helena",
// "Saint Kitts and Nevis",
// "Saint Lucia",
// "Saint Pierre and Miquelon",
// "Saint Vincent and The Grenadines",
// "Samoa",
// "San Marino",
// "Sao Tome and Principe",
// "Saudi Arabia",
// "Senegal",
// "Serbia",
// "Seychelles",
// "Sierra Leone",
// "Singapore",
// "Slovakia",
// "Slovenia",
// "Solomon Islands",
// "Somalia",
// "South Africa",
// "South Georgia and The South Sandwich Islands",
// "Spain",
// "Sri Lanka",
// "Sudan",
// "Suriname",
// "Svalbard and Jan Mayen",
// "Swaziland",
// "Sweden",
// "Switzerland",
// "Syrian Arab Republic",
// "Taiwan, Province of China",
// "Tajikistan",
// "Tanzania, United Republic of",
// "Thailand",
// "Timor-leste",
// "Togo",
// "Tokelau",
// "Tonga",
// "Trinidad and Tobago",
// "Tunisia",
// "Turkey",
// "Turkmenistan",
// "Turks and Caicos Islands",
// "Tuvalu",
// "Uganda",
// "Ukraine",
// "United Arab Emirates",
// "United Kingdom",
// "United States",
// "United States Minor Outlying Islands",
// "Uruguay",
// "Uzbekistan",
// "Vanuatu",
// "Venezuela",
// "Viet Nam",
// "Virgin Islands, British",
// "Virgin Islands, U.S.",
// "Wallis and Futuna",
// "Western Sahara",
// "Yemen",
// "Zambia",
// "Zimbabwe",
// ]

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '80%'
    },
    formControlButton: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '60%'
    },
    button: {
        color: "white", 
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
          },
    },
    toggleButton: {
        '& .Mui-selected' : {
            backgroundColor: theme.palette.primary.light
        },
        '& .Mui-selected:hover' : {
            backgroundColor: theme.palette.primary.light
        },
    },
    acceptButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        width: '40%',
        marginLeft: '5%',
        marginRight: '5%',
        fontSize: '1rem',
    },
    closeButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        width: '40%',
        marginLeft: '5%',
        marginRight: '5%',
        fontSize: '1rem',
    },
    privacyButton: {
        position: 'fixed',
        bottom: '16px',
        left: '16px',
        color: "white", 
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
          },
      },
});

class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // global state
            formSubmitted: false,
            globalError: false,
            submissionSuccess: false,
            submissionFailure: false,
            mustConfirmEmail: false,
            justSignedUp: false,

            emailChecked: false,
            existingZohoRegistrant: false,
            existingCognitoUser: false,

            // login
            newPasswordRequired: false,
            newPassword: '',

            // password
            password: '',
            passwordError: null,
            passwordErrorMessage: '',

            // privacy
            privacySelected: false,
            privacyOpen: false,
            privacyAgree: false,

            // first name
            firstName: '',
            firstNameError: null,
            firstNameErrorMessage: '',

            // last name
            lastName: '',
            lastNameError: null,
            lastNameErrorMessage: '',

            // email
            email: '',
            emailError: null,
            emailErrorMessage: '',

            // job title
            jobTitle: '',
            jobTitleError: null,
            jobTitleErrorMessage: '',

            // site
            site: '',
            siteError: null,
            siteErrorMessage: '',

            // phone
            phone: '',
            phoneError: null,
            phoneErrorMessage: '',

            // business address
            businessAddress: '',
            businessAddressError: null,
            businessAddressErrorMessage: '',

            // city
            city: '',
            cityError: null,
            cityErrorMessage: '',

            // state
            state: '',
            stateError: null,
            stateErrorMessage: '',

            // department
            department: '',
            departmentError: null,
            departmentErrorMessage: '',
            
            // job title
            postalCode: '',
            postalCodeError: null,
            postalCodeErrorMessage: '',
            
            // country
            country: '',
            countryError: null,
            countryErrorMessage: '',

            // find out
            findOut: '',
            findOutError: null,
            findOutErrorMessage: '',
        
            // get out
            getOut: '',
            getOutError: null,
            getOutErrorMessage: '',
        }
    }

    handleChange(event, inputIdentifier) {
        this.setState({ [inputIdentifier]: event.target.value });
    }

    handleZohoRegistration = async () => {

        // check to see the user entered everything required

        this.setState({
            privacyAgree: false,
            globalError: false,
            emailError: null,
            emailErrorMessage: '',
            firstNameError: null,
            firstNameErrorMessage: '',
            lastNameError: null,
            lastNameErrorMessage: '',
            jobTitleError: null,
            jobTitleErrorMessage: '',
            departmentError: null,
            departmentErrorMessage: '',
        })

        let validation = true

        if (!this.state.privacySelected) {
            validation = false

            this.setState({
                privacyAgree: true,
                globalError: true,
            })

        }

        if (this.state.email.indexOf(" ") > 0){
            validation = false

            this.setState({
                emailError: true,
                emailErrorMessage: 'Email cannot contain spaces',
                globalError: true,
            })
        } 
        
        if(!this.state.email){
            validation = false
            this.setState({
                emailError: true,
                emailErrorMessage: 'Email is required',
                globalError: true,
            }) 
        }

        if(!this.state.email.includes('@') || !this.state.email.includes('.')){
            validation = false
            this.setState({
                emailError: true,
                emailErrorMessage: 'Email must contain:  @ and .',
                globalError: true,
            }) 
        }

        if(!this.state.firstName){
            validation = false
            this.setState({
                firstNameError: true,
                firstNameErrorMessage: 'First name is required',
                globalError: true,
            }) 
        }

        if(!this.state.lastName){
            validation = false
            this.setState({
                lastNameError: true,
                lastNameErrorMessage: 'Last name is required',
                globalError: true,
            }) 
        }

        if(!this.state.department){
            validation = false
            this.setState({
                departmentError: true,
                departmentErrorMessage: 'Department is required',
                globalError: true,
            }) 
        }
        

        if(validation){

        this.setState({
            formSubmitted: true,
            emailError: false,
            emailErrorMessage: '',
            globalError: false,
        })

        axios({
            method: 'POST',
            url: '/zoho/show-attendees/',
            data: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                jobTitle: this.state.jobTitle,
                site: this.state.site,
                phone: this.state.phone,
                businessAddress: this.state.businessAddress,
                city: this.state.city,
                state: this.state.state,
                postalCode: this.state.postalCode,
                country: this.state.country,
                notes: `${this.state.findOut},${this.state.getOut}`,
                showId: eventId,
                department: this.state.department,
                accountId,
            }
          }).then((response) => {

            this.setState({
                existingZohoRegistrant: true,
                formSubmitted: false,
            })

            }).catch((error) => {

                this.setState({
                    existingZohoRegistrant: false,
                    formSubmitted: false,
                    globalError: true,
                    emailError: true,
                    emailErrorMessage: 'Email type is not valid for registration. Please contact support.',
                })
          })
        }
    }


    handleValidation = () => {

        this.setState({
            formSubmitted: true
        })

        Auth.confirmSignUp(
            this.state.email,
            this.state.confirmationCode
        ).then((response) => {
            this.setState({
                userConfirmed: true,
                formSubmitted: false
            })
        }).catch((error) => {
            this.setState({
                confirmationCodeError: true,
                confirmationCodeErrorMessage: 'Incorrect Confirmation Code',
                formSubmitted: false
            })
        })
    }

    handleModal(value, inputIdentifier) {
        this.setState({ [inputIdentifier]: value });
    }

    checkEmail = async () => {
        
        this.setState({
            formSubmitted: true,
            emailError: false,
            emailErrorMessage: '',
            globalError: false, 
        })


        if (!this.state.email){
            this.setState({
                formSubmitted: false,
                emailError: true,
                emailErrorMessage: 'Please enter a valid email',
                globalError: true,
            })

            return
        }
        
        if (this.state.email.includes("+")){

            this.setState({
                formSubmitted: false,
                emailError: true,
                emailErrorMessage: "Email cannot contain a '+'",
                globalError: true,
            })

            return
        }

        if (!this.state.email.includes('@') || !this.state.email.includes('.')){

            this.setState({
                formSubmitted: false,
                emailError: true,
                emailErrorMessage: "Email must contain: @ and .",
                globalError: true,
            })

            return
        }

        // logic to be used if we need to check the domain of the user's email
        // if (
        //     !this.state.email.includes("@planetconnect.com")
        //     && !this.state.email.includes("@merck.com")
        //     && !this.state.email.includes("@msd.de")
        //     && !this.state.email.includes("msd.com")
        // ) {

        //     this.setState({
        //         formSubmitted: false,
        //         emailError: true,
        //         emailErrorMessage: "Your user is not allowed at this event",
        //         globalError: true,
        //     })

        //     return
        // }
        
        let existingZohoRegistrant = false
        let existingCognitoUser = false

        const existingRegistrant = await axios({
            method: 'GET',
            url: `/show-attendees/${eventId}/${this.state.email}`,
          })

          if (existingRegistrant.data.length){
              if (existingRegistrant.data[0].email.toLowerCase() === this.state.email.toLowerCase()){
                existingZohoRegistrant = true
              }
          } else {
            existingZohoRegistrant = false
        }


        // check to see if they are already a cognito user

        const code = '000000'

        await Auth.confirmSignUp(this.state.email, code, {
            forceAliasCreation: false
        }).then(data => console.log(data))
        .catch( err => {
                switch ( err.code ) {
                    case 'UserNotFoundException':
                        existingCognitoUser = false
                        break
                    case 'NotAuthorizedException':
                        existingCognitoUser = true
                        break
                    case 'AliasExistsException':
                        existingCognitoUser = true
                        break
                    case 'CodeMismatchException':
                        existingCognitoUser = true
                        break
                    case 'ExpiredCodeException':
                        existingCognitoUser = true
                        break
                    default:
                        existingCognitoUser = false
                        break
                }
            } )

            this.setState({
                formSubmitted: false,
                emailChecked: true,
                existingCognitoUser: existingCognitoUser,
                existingZohoRegistrant: existingZohoRegistrant
            })
        
    }

    signIn = () => {

        this.setState({
            formSubmitted: true
        })

        Auth.signIn(
            this.state.email,
            this.state.password
        ).then((response) => {
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.setState({
                    newPasswordRequired: true,
                    formSubmitted: false
                })
            } else {
                    this.props.setEventDetails()
                    this.props.setAuthenticatedUser()
                    this.props.setBriefcase()
                    this.props.history.push(`/lobby`);
            }
        }).catch((error) => {
            console.log(error)

            if (error.code === 'UserNotConfirmedException'){
                this.setState({
                    formSubmitted: false,
                    passwordError: true,
                    passwordErrorMessage: 'Please check your email for a confirmation link. If you have not recieved one, please check spam or contact support.',
                })
                // hfieshfieshihi@hfuieshf.com
            } else {
                this.setState({
                    formSubmitted: false,
                    passwordError: true,
                    passwordErrorMessage: 'Incorrect password. If you forgot your pasword, you may reset it below be clicking on the "Reset Password" button.',
                })
            }
        })
    }

    setPermanentPassword = () => {

        this.setState({
            formSubmitted: true
        })

        Auth.signIn(this.state.email, this.state.password)
            .then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    Auth.completeNewPassword(
                        user,
                        this.state.newPassword,
                    ).then(user => {
                        this.props.setEventDetails()
                        this.props.setAuthenticatedUser()
                        this.props.history.push(`/lobby`);
                    }).catch(e => {
                        this.setState({
                            emailError: true,
                            emailErrorMessage: 'Unable to set permanent password',
                            formSubmitted: false
                        })
                    });
                } else {
                    this.props.setEventDetails()
                    this.props.setAuthenticatedUser()
                    this.props.history.push(`/lobby`);
                }
            }).catch(e => {
                this.setState({
                    emailError: true,
                    emailErrorMessage: 'Unable to set permanent password',
                    formSubmitted: false
                })
            });
    }

    handleCognitoSignup = () => {

        this.setState({
            formSubmitted: true,
            passwordError: false,
            passwordErrorMessage: '',
        })

        Auth.signUp({
            username: this.state.email,
            password: this.state.password,
            attributes: {
                email: this.state.email,
            }
        }).then((response) => {
            this.setState({
                mustConfirmEmail: true,
                existingCognitoUser: true,
                formSubmitted: false,
                justSignedUp: true,
            })
        }).catch((error) => {
            switch(error.code){
                case "UsernameExistsException":
                    this.setState({
                        emailError: true,
                        emailErrorMessage: 'User already exists',
                        formSubmitted: false
                    })
                    break
                case "InvalidParameterException":
                    this.setState({
                        emailError: true,
                        emailErrorMessage: 'Please ensure you are using a valid email',
                        formSubmitted: false
                    })
                    break
                default:
                    this.setState({
                        passwordError: true,
                        passwordErrorMessage: 'Password must be at least 8 characters long.',
                        formSubmitted: false
                    })
                }
            })
    }

    handleCountrySelect = (event) => {
        this.setState({
            country: event.target.value
        })
      };

    //   resendVerificationCode() {
    //       console.log(this.state.email)
    //       Auth.resendSignup({username: this.state.email})
    //         .then((response) => {
    //             console.log(response)
    //         }).catch((error) => {
    //             console.log(error)
    //     })
    // }


    render() {
        const { classes } = this.props;

        if (this.state.newPasswordRequired) {
            return (
                <div>
                    <NavBar />
                    <div className={classes.grid} >
                        <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                            <img
                        src={this.props.event ? this.props.event.event.banner_location : landingPage.banner}
                        alt="Lobby"
                        style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            position: 'relative',
                            padding: 0,
                            margin: 0,
                            top: "-5px"
                        }}
                        />
                                <Paper className={classes.paper}>
                                    <Typography variant="h5">
                                        Create Permanent Password
                            </Typography>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField onChange={(event) => this.handleChange(event, "email")}
                                            value={this.state.email}
                                            label="Email"
                                            error={this.state.emailError} />
                                    </FormControl>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField onChange={(event) => this.handleChange(event, "newPassword")}
                                            type='password'
                                            value={this.state.newPassword}
                                            label="Password"
                                            error={this.state.emailError}
                                            helperText={this.state.emailErrorMessage} />
                                    </FormControl>
                                    <FormControl className={classes.formControlButton}>
                                        <Button variant="contained" onClick={this.setPermanentPassword} className={classes.button}>
                                            {this.state.formSubmitted ? <CircularProgress color="inherit" /> : <div>Submit</div>}
                                        </Button>
                                    </FormControl>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }

        if (this.state.emailChecked){
            if(!this.state.existingZohoRegistrant){
                
                return (
                    <div>
                        <NavBar />
                        <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                            <Grid item xs={12} sm={8} md={6} >
                            <img
                        src={this.props.event ? this.props.event.event.banner_location : landingPage.banner}
                        alt="Lobby"
                        style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            position: 'relative',
                            padding: 0,
                            margin: 0,
                            top: "-5px"
                        }}
                        />
                            <Paper className={classes.paper}>
                                <br/>
                            <Typography variant="h5">
                                Alright! Let's get you set up. 
                            </Typography>
                            <br/>
                            <Typography variant="body1">
                                Please fill out all fields below and confirm you have reviewed the Privacy Policy, then click Register
                            </Typography>
                                <br/>
                            <FormControl className={classes.formControl} 
                                        onKeyUp={(event) => {
                                            if (event.key === 'Enter')
                                                this.handleZohoRegistration();
                                        }}
                            >
                                <TextField
                                    required
                                    onChange={(event) => this.handleChange(event, "firstName")}
                                    value={this.state.firstName}
                                    label="First Name"
                                    error = {this.state.firstNameError}
                                    helperText={this.state.firstNameErrorMessage}
                                    autoFocus
                                />
                                <br/>
                                <TextField
                                    required
                                    onChange={(event) => this.handleChange(event, "lastName")}
                                    value={this.state.lastName}
                                    label="Last Name"
                                    error = {this.state.lastNameError}
                                    helperText={this.state.lastNameErrorMessage}
                                />
                                <br/>
                                <TextField
                                    required
                                    onChange={(event) => this.handleChange(event, "department")}
                                    value={this.state.department}
                                    label="Department"
                                    error = {this.state.departmentError}
                                    helperText={this.state.departmentErrorMessage}
                                />
                                <br/>
                                <TextField
                                    required
                                    onChange={(event) => this.handleChange(event, "email")}
                                    value={this.state.email}
                                    label="Email"
                                    disabled
                                    error = {this.state.emailError}
                                    helperText={this.state.emailErrorMessage}
                                />
                            </FormControl>
                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    onChange={(event) => this.handleChange(event, "jobTitle")}
                                    value={this.state.jobTitle}
                                    label="Job Title"
                                    error = {this.state.jobTitleError}
                                    helperText={this.state.jobTitleErrorMessage}
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "site")}
                                    value={this.state.site}
                                    label="Site"
                                    error = {this.state.siteError}
                                    helperText={this.state.siteErrorMessage}
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "phone")}
                                    value={this.state.phone}
                                    label="Phone Number"
                                    error = {this.state.phoneError}
                                    helperText={this.state.phoneErrorMessage}
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "businessAddress")}
                                    value={this.state.businessAddress}
                                    label="Business Address"
                                    error = {this.state.businessAddressError}
                                    helperText={this.state.businessAddressErrorMessage}
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "city")}
                                    value={this.state.city}
                                    label="City/Locality"
                                    error = {this.state.cityError}
                                    helperText={this.state.cityErrorMessage}
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "state")}
                                    value={this.state.state}
                                    label="State/Province"
                                    error = {this.state.stateError}
                                    helperText={this.state.stateErrorMessage}
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "postalCode")}
                                    value={this.state.postalCode}
                                    label="Postal Code"
                                    error = {this.state.postalCodeError}
                                    helperText={this.state.postalCodeErrorMessage}
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.country}
                                error={this.state.countryError}
                                onChange={this.handleCountrySelect}
                                >
                                    {countryList.map((country, index) => {
                                        return <MenuItem key={index} value={country}>{country}</MenuItem>
                                    })}
                                </Select>
                                {this.state.countryErrorMessage ? <FormHelperText>{this.state.countryErrorMessage}</FormHelperText>  : null}
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <br />
                                <Typography variant='body1' color='textSecondary'>
                                    How did you find out about this Analytical Symposium?
                                </Typography>
                                <br />
                                <TextField
                                    value={this.state.findOut}
                                    onChange={(event) => this.handleChange(event, "findOut")}
                                    fullWidth
                                    multiline
                                    rowsMax={4}
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <br />
                                <Typography variant='body1' color='textSecondary'>
                                    What do you hope to get out of this Analytical Symposium?
                                </Typography>
                                <br />
                                <TextField
                                    // id="outlined-full-width"
                                    // label="How did you find out about this Analytical Symposium?"
                                    // style={{ margin: 8 }}
                                    // placeholder="Placeholder"
                                    // helperText="Full width!"
                                    multiline
                                    rowsMax={4}
                                    value={this.state.getOut}
                                    onChange={(event) => this.handleChange(event, "getOut")}
                                    fullWidth
                                // margin="normal"
                                />
                            </FormControl> */}
                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    onChange={(event) => this.handleChange(event, "country")}
                                    value={this.state.country}
                                    label="Country"
                                    error = {this.state.countryError}
                                    helperText={this.state.countryErrorMessage}
                                />
                            </FormControl> */}

                            {/* <FormControl className={classes.formControl}>
                                <TextField
                                    type="password"
                                    onChange={(event) => this.handleChange(event, "password")}
                                    value={this.state.password}
                                    label="Password"
                                    error = {this.state.parameterError}
                                    helperText={this.state.parameterErrorMessage}
                                />
                            </FormControl> */}
                            <FormControl style={{margin: '24px 0px', width: '85%'}}>
                                <Grid container alignItems='center' justify='space-between'>
                                    <Grid item xs={2} className={classes.toggleButton}>
                                        <ToggleButton 
                                            value='check'
                                            selected={this.state.privacySelected}
                                            onChange={() => {
                                                this.setState({privacySelected: !this.state.privacySelected})
                                            }}
                                        >
                                            <CheckIcon style={{color: 'white'}}/>
                                        </ToggleButton>
                                    </Grid>
                                    <Grid item xs={6} style={{padding: '0px 16px'}}>
                                        <Typography variant='body2' color='textPrimary'>
                                            I have read and understand the Privacy Policy
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="contained" onClick={() => this.handleModal(true, 'privacyOpen')} className={classes.button} style={{height: '100%', width: '80%',}}>
                                            Privacy<br/>
                                            Policy
                                        </Button>
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormControl className={classes.formControlButton}>
                                <Button variant="contained" onClick={this.handleZohoRegistration} className={classes.button}>
                                {this.state.formSubmitted ? <CircularProgress color="inherit"/> : <div>Register</div>}
                                </Button>
                                <FormHelperText id="component-helper-text" error>{this.state.privacyAgree ? 'Please agree to the privacy policy to continue' : ''}</FormHelperText>
                                <FormHelperText id="component-helper-text" error>{this.state.globalError? 'Please rectify errors above' : ''}</FormHelperText>
                                </FormControl>
                            <DialogWrapper open={this.state.privacyOpen} handleClose={(e) => this.setState({privacyOpen: false})}>
                                <Privacy />
                                <Button className={classes.acceptButton} variant='contained' onClick={(e) => this.setState({privacyOpen: false, privacySelected: true})}>I Accept</Button>
                                <Button className={classes.closeButton} variant='contained' onClick={() => this.handleModal(false, 'privacyOpen')}>Close</Button>
                            </DialogWrapper>
                        </Paper>
                            </Grid>
                        </Grid>
                        <Button className={classes.privacyButton} variant='contained' onClick={() => this.handleModal(true, 'privacyOpen')}>View Privacy Policy</Button>
                    </div>
                )
            } else if(!this.state.existingCognitoUser){
                return (
                    <div>
                    <NavBar />
                    <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                        <Grid item xs={12} sm={8} md={6} >
                        <img
                        src={this.props.event ? this.props.event.event.banner_location : landingPage.banner}
                        alt="Lobby"
                        style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            position: 'relative',
                            padding: 0,
                            margin: 0,
                            top: "-5px"
                        }}
                        />
                    <Paper className={classes.paper}>
                        <br/>
                    <Typography variant="h5">
                        Perfect! Now let's make sure you can securely login
                    </Typography>
                        <br/>
                    <Typography variant="body1">
                        Please create a password using the form below
                    </Typography>
                        <br/>
                    {/* <FormControl className={classes.formControl}>
                        <TextField
                            onChange={(event) => this.handleChange(event, "username")}
                            value={this.state.username}
                            label="Username"
                            fullWidth
                            error = {this.state.usernameError}
                            helperText={this.state.usernameError ? this.state.usernameErrorMessage : "Username cannot contain spaces"}
                        />
                    </FormControl> */}
                    <FormControl className={classes.formControl}
                                onKeyUp={(event) => {
                                    if (event.key === 'Enter')
                                        this.handleCognitoSignup();
                                }}
                    >
                        <TextField
                            onChange={(event) => this.handleChange(event, "email")}
                            value={this.state.email}
                            label="Email"
                            disabled
                            error = {this.state.emailError}
                            helperText={this.state.emailErrorMessage}
                        />
                        <br/>
                        <TextField
                            type="password"
                            onChange={(event) => this.handleChange(event, "password")}
                            value={this.state.password}
                            label="Password"
                            error = {this.state.passwordError}
                            helperText={this.state.passwordErrorMessage ? this.state.passwordErrorMessage : "Password must be at least 8 characters long"}
                            autoFocus
                        />
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                        <Button variant="contained" onClick={this.handleCognitoSignup} className={classes.button}>
                        {this.state.formSubmitted ? <CircularProgress color="inherit"/> : <div>Next</div>}
                        </Button>
                    </FormControl>
                </Paper>
                <DialogWrapper open={this.state.mustConfirmEmail} handleClose={() => this.handleModal(false, 'mustConfirmEmail')}>
                    <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                            <Grid item xs={12} sm={8} md={6} >
                            <Typography variant="h5">
                                    Please check your inbox to confirm your email
                            </Typography>
                            <Typography variant="body1">
                                    If you cannot find the confirmation email, wait a few minutes and check your spam folder. 
                                    If the problem persists, please reach out for chat support in the bottom right of your screen.
                            </Typography>
                            </Grid>
                        </Grid>
                    <Button className={classes.closeButton} variant='contained' onClick={() => this.handleModal(false, 'mustConfirmEmail')}>Next</Button>
                </DialogWrapper>
                </Grid>
                </Grid>
            </div>
                )
            } else {
                return (
                    <div>
                <NavBar />
                <div className={classes.grid}>
                    <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                        <Grid item xs={12} sm={8} md={6} >
                        <img
                        src={this.props.event ? this.props.event.event.banner_location : landingPage.banner}
                        alt="Lobby"
                        style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            position: 'relative',
                            padding: 0,
                            margin: 0,
                            top: "-5px"
                        }}
                        />
                            <Paper className={classes.paper}>
                                {this.state.justSignedUp ? 
                                <React.Fragment>
                                    <br/>
                                    <Typography variant="h5">
                                        Beautiful! One last step.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body1">
                                        Check your inbox for an email from eregistration@planetconnect.com for your verification link, then return here to sign in. 
                                        The verification link will only be valid for 1 hour.
                                    </Typography>
                                    <br/>
                                    {/* <FormControl className={classes.formControlButton}>
                                        <Button variant="contained" onClick={() => this.resendVerificationCode()} className={classes.button}>
                                            Resend Verification
                                        </Button>
                                    </FormControl> */}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <br/>
                                    <Typography variant="h5">
                                        Please input your password below to sign in.
                                    </Typography>
                                    <br/>
                                    <Typography variant="body1">
                                        If you have forgotten your password, you may reset it below.
                                    </Typography>
                                    <br/>
                                </React.Fragment>
                                }

                                <FormControl className={classes.formControl} fullWidth
                                            onKeyUp={(event) => {
                                                if (event.key === 'Enter')
                                                    this.signIn();
                                            }}
                                >
                                    <TextField onChange={(event) => this.handleChange(event, "email")}
                                        value={this.state.email}
                                        label="Email"
                                        error={this.state.emailError}
                                        disabled />
                                <br/>
                                    <TextField onChange={(event) => this.handleChange(event, "password")}
                                        type='password'
                                        value={this.state.password}
                                        label="Password"
                                        error={this.state.passwordError}
                                        helperText={this.state.passwordErrorMessage} 
                                        autoFocus
                                    />
                                </FormControl>
                                <FormControl className={classes.formControlButton}>
                                    <Button variant="contained" onClick={this.signIn} className={classes.button}>
                                        {this.state.formSubmitted ? <CircularProgress color="inherit" /> : <div>Enter</div>}
                                    </Button>
                                </FormControl>
                                <FormControl className={classes.formControlButton} >
                                    <Button color="inherit" component={Link} to={'/forgotpassword'}>Reset Password</Button>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            <DialogWrapper open={this.state.privacyOpen} handleClose={() => this.handleModal(false, 'privacyOpen')}>
                <Privacy />
                <Button className={classes.closeButton} variant='contained' onClick={() => this.handleModal(false, 'privacyOpen')}>Close</Button>
            </DialogWrapper>
            <Button className={classes.privacyButton} variant='contained' onClick={() => this.handleModal(true, 'privacyOpen')}>View Privacy Policy</Button>
            </div>
                )
            }
        }



        return (
            <div>
                <NavBar />
                <Grid style={{ paddingTop: "85px" }} container layout={"row"} justify="center" spacing={0}>
                    <Grid item xs={12} sm={8} md={6} >
                    <img
                        src={this.props.event ? this.props.event.event.banner_location : landingPage.banner}
                        alt="Lobby"
                        style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            position: 'relative',
                            padding: 0,
                            margin: 0,
                            top: "-5px"
                        }}
                        />
                    <Paper className={classes.paper}>
                    <br/>
                    <Typography variant="h5">
                    Welcome to the {this.props.event.event.title}!
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        Let's get started! Please provide your email address below and click next.
                    </Typography>
                    <FormControl className={classes.formControl} 
                                onKeyUp={(event) => {
                                    if (event.key === 'Enter')
                                        this.checkEmail();
                                }}
                    >
                        <TextField
                            required
                            onChange={(event) => this.handleChange(event, "email")}
                            value={this.state.email}
                            label="Email"
                            error = {this.state.emailError}
                            helperText={this.state.emailErrorMessage}
                            autoFocus
                        />
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                    <br/>
                        <Button type='submit' variant="contained" onClick={this.checkEmail} className={classes.button}>
                        {this.state.formSubmitted ? <CircularProgress color="inherit"/> : <div>Next</div>}
                        </Button>
                        {/* <FormHelperText id="component-helper-text" error>{this.state.privacyAgree ? 'Please agree to the privacy policy to continue' : ''}</FormHelperText>
                        <FormHelperText id="component-helper-text" error>{this.state.globalError? 'Please rectify errors above' : ''}</FormHelperText> */}
                        <br/>
                        </FormControl>
                    <DialogWrapper open={this.state.privacyOpen} handleClose={(e) => this.setState({privacyOpen: false})}>
                        <Privacy />
                        <Button className={classes.acceptButton} variant='contained' onClick={(e) => this.setState({privacyOpen: false, privacySelected: true})}>I Accept</Button>
                        <Button className={classes.closeButton} variant='contained' onClick={() => this.handleModal(false, 'privacyOpen')}>Close</Button>
                    </DialogWrapper>
                </Paper>
                    </Grid>
                </Grid>
                <Button className={classes.privacyButton} variant='contained' onClick={() => this.handleModal(true, 'privacyOpen')}>View Privacy Policy</Button>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setAuthenticatedUser: setAuthenticatedUser,
        setBriefcase: setBriefcase,
        setEventDetails: setEventDetails,
    }, dispatch);
  }

  function mapStateToProps(state) {
    return {
      event: state.event,
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(SignUpForm)));