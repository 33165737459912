import React, { Component } from 'react';
import PubNubReact from 'pubnub';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { withAuthenticator } from 'aws-amplify-react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import enterListener from '../../Utilities/listeners';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../../Store/Actions/getChatMessages';

const useStyles = theme => ({
    root: {
        width: '95%',
        margin: theme.spacing(2),
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '80%'
    },
    formControlButton: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '50%'
    },
    button: {
        color: "white",
        backgroundColor: theme.palette.primary.main
    },
    own: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        padding: '5px',
        marginBottom: '3px',
        borderRadius: '15px',
        wordWrap: "break-word",
        whiteSpace: "pre-line",
    },
    other: {
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
    expansionPanelMain: { padding: '0px 8px 0px 8px' },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '60%',
        flexShrink: 0,
    },
    icon: {
        minWidth: '10em',
        maxWidth: '30em',
        maxHeight: '30em',
        width: '100%',
    },
    merck: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    }
});

class PrivateChat extends Component {
    constructor(props) {
        super(props);
        this.pubnub = new PubNubReact({
            publishKey: this.props.event.event.publish_key,
            subscribeKey: this.props.event.event.subscribe_key,
            uuid: this.props.user.user.email,
        });
        this.state = {
            messages: [],
            input: '',
            channelOccupants: [],
            member: '',
        };
    }

    listener = event => {
        enterListener(event, this.sendMessage);
      };

    async componentDidMount(){

        await this.pubnub.addListener({
            status: function (statusEvent) {
                console.log("Personal Chat Status: ", statusEvent)
            },
            message: (response) => { 
                console.log("Personal Chat Message: ", response)
            },
            presence: function (presenceEvent) {
                console.log("Personal Chat Presence: ", presenceEvent)
                }
            })

            document.addEventListener("keydown", this.listener);

            await this.getNewMessages()
    }

    // componentDidMount = async () => {
    //     await this.getNewMessages()
    // }

    async componentWillUnmount(){
        document.removeEventListener("keydown", this.listener);

        await this.pubnub.removeListener()
    }

    getNewMessages = async () => {
        await this.props.getChatMessages(this.props.user.user, this.props.chat.activePrivateChat, 'private')
    }

    componentDidUpdate(prevProps) {
        if (this.props.chat.activePrivateChat !== prevProps.chat.activePrivateChat) {
            this.getNewMessages()
        }
    }

    handleChange(event, inputIdentifier) {
        this.setState({ [inputIdentifier]: event.target.value });
    }

    sendMessage = async () => {
        if (this.state.input) {
        try {
           await this.pubnub.publish(
                {
                    channel: this.props.chat.activePrivateChat,
                    message: {
                        text: this.state.input,
                        from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
                        accountName: this.props.user.user.account_name,
                        department: this.props.user.user.department,
                        email: this.props.user.user.email,
                        first_name: this.props.user.user.first_name,
                        last_name: this.props.user.user.last_name,
                        roles: this.props.user.user.roles,
                        site: this.props.user.user.site,
                        title: this.props.user.user.title,
                    }
                },
                await this.setState({
                    input: '',
                })
            );
        } catch (error){
            await this.setState({
                input: '',
            })
            console.log(error)
        }
        }
    }


    render() {
        const { classes } = this.props;

        if(!this.props.chat.activePrivateChat){
            return null
        }
        
        let previousSender;
        let messages = this.props.chat.activePrivateChatMessages.map((message) => {
            if (!message.entry.text) {
                return null
            }

            let align = 'left'
            let color = 'other'

            if (message.entry.email === this.props.user.user.email) {
                align = 'right'
                color = 'own'
            }

            if (previousSender === message.entry.email) {
                return (
                    <React.Fragment key={message.timetoken} >
                        <Typography variant="body1" component='p' align={align} className={classes[color]}>
                        {message.entry.text}
                        </Typography>
                    </React.Fragment>
                )
            } else {
                previousSender = message.entry.email
                return (
                    <React.Fragment key={message.timetoken} >
                        <br />
                        <Typography variant="subtitle1" component='p' align={align}>
                            <b>{message.entry.from ? message.entry.from : 'Anonymous'}</b>
                        </Typography>
                        <Typography variant="body1" component='p' align={align} className={classes[color]}>
                        {message.entry.text}
                        </Typography>
                    </React.Fragment>
                )
            }
        })

        return (
                <Grid
                    className={classes.root}
                    container
                    layout={'row'}
                    spacing={2}
                    alignItems='center'
                    justify='space-between'
                    >
                    <Grid item xs={12} >
                    <br/><br/>
                    {messages}
                    <br/>
                    <FormControl className={classes.formControl} fullWidth>
                        <TextField onChange={(event) => this.handleChange(event, "input")}
                            value={this.state.input}
                        />
                    </FormControl>
                    <br/>
                    <FormControl className={classes.formControlButton}>
                        <Button variant="contained" onClick={() => this.sendMessage()} className={classes.button}>
                            Send
                        </Button>
                    </FormControl>
                    <br/>
                    <br/><br/>
                    </Grid>
                </Grid>
        );
    }
}

const MyTheme = {
    button: { backgroundColor: '#6f1a1d' },
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getChatMessages: getChatMessages,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
        chat: state.chat,
        event: state.event
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(withAuthenticator(PrivateChat, false, [], null, MyTheme))));
