import React, { Component } from 'react';
import axios from 'axios';
import { withAuthenticator } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Auth } from 'aws-amplify';
import ImageMapper from 'react-image-mapper';
import { connect } from 'react-redux';
import AuditoriumImage from '../Assets/NetworkingSessions.jpg';
import Dialog from '../Components/DialogWrapper';
import trackAction from '../Utilities/tracker';
import moment from 'moment';
import { eventId } from '../config';

// table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

// config
import { drawerWidth } from '../config';

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  imageMapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  gridContainer: {
    margin: '0px -24px -24px -24px',
  },
  table: {
  },
  header: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'fixed',
    zIndex: '2',
    paddingRight: theme.spacing(5),
  },
  title: {
    marginTop: theme.spacing(-1),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'fixed',
    zIndex: '2',
    backgroundColor: '#efefef',
    borderRadius: '8px'
  },
  backButton: {
    position: 'relative',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  preSelectedRow: {
    background: '#6f1a1d33',
  },
});

class Auditorium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webinars: [],
      imageWidth: window.innerWidth * 0.955,
      modalOpen: true,
      upcomingWebinar: true,
      archivedWebinar: false,
      roomName: "Discussion Forum",
      preSelectedPresentation: null,
    };
  }

  MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'UpcomingWebinars',
        shape: 'poly',
        coords: [
          478, 630, //bottom left
          478, 220, //top left
          1525, 220, //top right
          1525, 630, //bottom right
        ],
        strokeColor: '',
        lineWidth: '4',
        href: '/auditorium',
      },
    ],
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateCoordinates);

    Auth.currentSession()
      .then((data) => {
        axios({
          method: 'get',
          url: `/agenda/${eventId}`,
          headers: { idtoken: data.idToken.jwtToken },
          params: {
            order_by: 'start_time',
            order_dir: 'asc',
            filter_column: 'accepted_type',
            filter_value: "Networking",
          },
        }).then((response) => {

          if (this.props.location.search) {
            const query = new URLSearchParams(this.props.location.search);
            for (let param of query.entries()) {
              if (param[0] === 'presentation_id') {
                  this.setState({
                    modalOpen: true,
                    upcomingWebinar: true,
                    preSelectedPresentation: param[1],
                  });
                }
              }
            }


          let authorizedPresentations = [];
          
          for (let x = 0; x < response.data.data.length; x++) {
            let presentation = response.data.data[x];
              // this line of code is because I needed the presentation dates to match after formatting through moment.js
              presentation.date = moment(presentation.start_time, "YYYY-MM-DD").format();
              if (presentation.start_time) {
                authorizedPresentations.push(presentation);
              }
          }


          // Sort by month, sort by day, sort by time
          authorizedPresentations = authorizedPresentations.sort((a, b) => moment(a.date).format('MM') - moment(b.date).format('MM')) // by month
          .sort((a, b) => moment(a.date).format('DD') - moment(b.date).format('DD')) // by day
          .sort((a, b) => {
            if (a.date === b.date) {
              return moment.duration(a.start_time).asMinutes() - moment.duration(b.start_time).asMinutes()
            } else {
              return 1
            }
          }); // by time

            this.setState({
              webinars: authorizedPresentations,
            });
          },
          (error) => {
            this.setState({
              webinars: [],
            });
            if (this.props.debug.debug) {
              alert(`Error: Could not display the Auditorium. ${error}`)
            }
          }
        )
        .catch((error) => {
          if (this.props.debug.debug) {
            alert(`Error: Could not display the Auditorium. ${error}`)
          }
        });
      })
      .catch((error) => {
        this.setState({
          webinars: [],
        });
        if (this.props.debug.debug) {
          alert(`Error: Could not display the Auditorium. ${error}`)
        }
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCoordinates);
  }

  updateCoordinates = () => {
    let width = window.innerWidth * 0.955;

    this.setState({
      imageWidth: width,
    });
  };

  imageClick(event) {
    if (event.name === 'UpcomingWebinars') {
      this.setState({
        modalOpen: true,
        upcomingWebinar: true,
      });
    } else {
      this.setState({
        modalOpen: true,
        archivedWebinar: true,
      });
    }
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      upcomingWebinar: false,
      archivedWebinar: false,
    });
  }

  

  viewPresentation(webinar) {

    const event = {
      action: "Clicked",
      resource_type: "Discussion",
      resource_id: webinar.abstract_id,
      url_visited: '/discussionforum',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: webinar.account_id,
      resource_account_name: webinar.account_name,
      resource_name: webinar.title,
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    }

      trackAction(
        event
        )

    if (webinar.presentation_link) {
    window.open(webinar.presentation_link, '_blank');
  }
  }


  viewArchive(webinar) {

    const event = {
      action: "Clicked",
      resource_type: "DicussionRecording",
      resource_id: webinar.abstract_id,
      url_visited: '/discussionforum',
      // user_account_id: this.props.user.user.account_id,
      // new fields
      resource_account_id: webinar.account_id,
      resource_account_name: webinar.account_name,
      resource_name: webinar.title,
      // user_account_name: this.props.user.user.account_name,
      // user_email: this.props.user.user.email,
      // user_first_name: this.props.user.user.first_name,
      // user_last_name: this.props.user.user.last_name,
      // user_title: this.props.user.user.title,
      // user_department: this.props.user.user.department,
      // user_site: this.props.user.user.site
    }

    trackAction(
      event
      )
  if (webinar.archive_link) {
  window.open(webinar.archive_link, '_blank');
}
}

  render() {
    const { classes } = this.props;

    const allRoutes = JSON.parse(this.props.event.event.available_routes).routes
    let pageTitle = ''
    allRoutes.forEach(route => {
      if (route.route === '/discussionforum') {
        pageTitle = route.title
      }
    })

    let webinars;
    if (this.state.modalOpen) {
      if (this.state.archivedWebinar) {
        webinars = (
            <React.Fragment>
              <Typography variant='h4' gutterBottom align='center'>
                Recorded Presentations
              </Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Title</TableCell>
                      <TableCell align='center'>Time</TableCell>
                      <TableCell align='center'>Date</TableCell>
                      <TableCell align='center'>Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.webinars
                      .map((webinar, index) => {

                        return (
                          <React.Fragment key={index}>
                            <TableRow className={this.state.preSelectedPresentation === webinar.abstract_id ? classes.preSelectedRow : ''}>
                              <TableCell align='left' style={{maxWidth: '40vw'}}>
                                <b>Title: </b>
                                  {webinar.title}
                                <br /><br />
                                {webinar.presenter_first_name ? <React.Fragment><b>Presenter: </b>{webinar.presenter_first_name + ' ' + webinar.presenter_last_name}</React.Fragment> : <React.Fragment></React.Fragment>}
                                <br />
                                {webinar.account_name ? <React.Fragment><b>Company: </b>{webinar.account_name} </React.Fragment> : <React.Fragment></React.Fragment>}
                              <br/>
                              {webinar.cleared_for_public_view === "Yes - Approved" ? <React.Fragment><b>Visibility: </b>Public</React.Fragment> : <React.Fragment><b>Visibility: </b>Merck Private</React.Fragment>}
                              </TableCell>
                              <TableCell align='center'>
                                {webinar.start_time ? <React.Fragment>{`${moment(webinar.start_time, 'HH:mm:ss').format('hh:mm a')} `}</React.Fragment> : <React.Fragment></React.Fragment>}
                              </TableCell>
                              <TableCell align='center'>
                                {webinar.date ? `${moment(webinar.date.substring(0, 10)).format('dddd')}, ${moment(webinar.date.substring(0, 10)).format('MMM')} ${moment(webinar.date.substring(0, 10)).format('Do')}` : <React.Fragment></React.Fragment>}
                              </TableCell>
                              <TableCell align='center'>
                                {webinar.archive_link ?
                                  <Button
                                    variant='outlined'
                                    color='primary'
                                    target='#blank'
                                    onClick={() =>
                                      this.viewArchive(webinar)
                                    }
                                >
                                  View Replay
                                </Button>
                              :
                              <Button
                                variant='outlined'
                                color='primary'
                                disabled
                              >
                                Coming Soon
                              </Button>
                              }
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
        );
      } else {
        webinars = (
          <React.Fragment>
            <Typography variant='h4' gutterBottom align='center'>
              Upcoming Discussions
            </Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Title</TableCell>
                    <TableCell align='center'>Time</TableCell>
                    <TableCell align='center'>Date</TableCell>
                    <TableCell align='center'>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.webinars
                    .map((webinar, index) => {
                      let date = webinar.date.split("T")[0];
                      let time = webinar.end_time;
                      let timeNow = moment().format('HH:mm:ss');
                      let dateNow = moment().format("YYYY-MM-DD");
                      switch (true) {
                        case (date < dateNow):
                          return null;
                        case (date === dateNow && timeNow >= time):
                          return null;
                        default:
                          return (
                            <React.Fragment key={index}>
                              <TableRow className={this.state.preSelectedPresentation === webinar.abstract_id ? classes.preSelectedRow : ''}>
                                <TableCell align='left' style={{ maxWidth: '40vw' }}>
                                  <b>Title: </b>
                                  {webinar.title}
                                  <br /><br />
                                  {webinar.presenter_first_name ? <React.Fragment><b>Presenter: </b>{webinar.presenter_first_name + ' ' + webinar.presenter_last_name + ' - ' + webinar.presenter_title}</React.Fragment> : <React.Fragment></React.Fragment>}
                                  <br />
                                  {webinar.account_name ? <React.Fragment><b>Company: </b>{webinar.account_name} </React.Fragment> : <React.Fragment></React.Fragment>}
                                  <br />
                                  {webinar.cleared_for_public_view === "Yes - Approved" ? <React.Fragment><b>Visibility: </b>Public</React.Fragment> : <React.Fragment><b>Visibility: </b>Merck Private</React.Fragment>}
                                </TableCell>
                                <TableCell align='center'>
                                  {webinar.start_time ? moment(webinar.start_time).format('h:mm a') : <React.Fragment></React.Fragment>}
                                </TableCell>
                                <TableCell align='center'>
                                  {webinar.date ? moment(webinar.date).format('dddd, MMMM Do') : <React.Fragment></React.Fragment>}
                                </TableCell>

                                <TableCell align='center'>
                                  <Button
                                    variant='outlined'
                                    color='primary'
                                    target='#blank'
                                    onClick={() =>
                                      this.viewPresentation(webinar)
                                    }
                                  >
                                    Launch
                              </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        );
      }
    }

    let pageWidth = !this.props.sidebar.open ? this.state.imageWidth : window.innerWidth - drawerWidth;


    return (
        <Grid
          container
          className={classes.gridContainer}
          align='center'
          justify='center'
        >
          <Grid item xs={12} className={classes.title}>
            <Typography variant='h4'>
              {pageTitle}
            </Typography>
          </Grid>
          <Grid item className={classes.imageMapper} xs={12}>
            <Dialog
              open={this.state.modalOpen}
              handleClose={this.handleClose.bind(this)}
              scroll='body'
            >
              {webinars}
            </Dialog>
            <ImageMapper
              width={pageWidth}
              imgWidth={2000}
              src={AuditoriumImage}
              map={this.MAP}
              onClick={(event) => this.imageClick(event)}
            />
          </Grid>
        </Grid>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: '#6f1a1d' },
};

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar,
    user: state.user,
    debug: state.debug,
    event: state.event
  };
}

export default connect(mapStateToProps, null)(withStyles(useStyles)(withRouter(withAuthenticator(Auditorium, false, [], null, MyTheme))));
