import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
import Divider from '@material-ui/core/Divider';
import { fallbackImages } from '../../config';
import PubNubReact from 'pubnub';

import { Auth } from 'aws-amplify';
import axios from 'axios';
import { eventId } from '../../config';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  root: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media: {
    height: 0,
    paddingTop: '40%', // 16:9
    margin: '0% 0%',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  body: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linePhoto: {
    borderRadius: "50%",
    width: "5rem",
    maxWidth: '100%',
    padding: theme.spacing(.5),
  },
  lineSpeakerName: {
    fontWeight: "bold",
    textAlign: "left",
  },
  lineSpeakerTitle: {
    fontSize: "1rem",
    textAlign: "left",
  },
  lineSpeakerCompany: {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "left",
  },
  sponsorLevel: {
    width: '100%',
    color: 'white',
    position: 'relative',
    textShadow: '0px 5px 20px #000000CC',
    fontSize: '150%',
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
  },
});

class PosterCard extends Component {
  constructor(props) {
    super(props);
    this.pubnub = new PubNubReact({
      publishKey: this.props.event.event.publish_key,
      subscribeKey: this.props.event.event.subscribe_key,
      uuid: this.props.user.user.email,
      autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
      restore: true, // enable catchup on missed messages
  });
    this.state = {
      isHovering: false,
      buttonHover: false,
      posterPresenterPresent: false,
    };
  }

  componentDidMount = async () => {

    try {

        await this.pubnub.addListener({
          message: (response) => { 
            if(response.message.text === 'livenow'){
              this.setState({
                posterPresenterPresent: true,
              })
            } else if(response.message.text === 'goingdark'){
              this.setState({
                posterPresenterPresent: false,
              })
            }

          },
        })

        await this.pubnub.subscribe({
          channels: [`${this.props.poster.abstract_id}livenow`],
          withPresence: false,
      });

      await this.pubnub.history({
        channel: `${this.props.poster.abstract_id}livenow`,
        count: 1, // how many items to fetch
        stringifiedTimeToken: true, // false is the default
    },
        (status, response) => {
          if (response.messages) {
            if(response.messages.length){
              if(response.messages[0].entry.text === 'livenow'){
                this.setState({
                  posterPresenterPresent: true,
                })
              } else if(response.messages[0].entry.text === 'goingdark'){
                this.setState({
                  posterPresenterPresent: false,
                })
              }
            }
          }
        }
    );

    } catch (error) {
        console.log(error)
    }
}

async componentWillUnmount() {
    
    await this.pubnub.removeListener()
    
}

  handleExpandClick() {
    this.setState(prevState => ({
        expanded: !prevState.expanded
    }));
}

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event });
  }

  async upvotePoster() {

    try {

      const data = await Auth.currentSession()

      if (!this.props.poster.liked) {
        await axios({
          method: 'POST',
          url: `/likes/${eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.poster.abstract_id,
            resource_type: this.props.poster.accepted_type
          }
        })
        this.props.handleUpvote()
      } else {
        await axios({
          method: 'DELETE',
          url: `/likes/${eventId}/${this.props.poster.abstract_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.poster.abstract_id,
          }
        })
        this.props.handleUpvote()
      }
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const { classes } = this.props;

    let favorited = false;
    if (this.props.briefcase.briefcase) {
      for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
        if (
          this.props.briefcase.briefcase[n].resource_id ===
          this.props.poster.abstract_id
        ) {
          favorited = true;
          break;
        }
      }
    }

    let characterCount = 0;
    let splitTitle = this.props.poster.title.split(" ");
    let modifiedTitle = ''

    splitTitle.map((string) => {

      characterCount = characterCount + string.length;

      if (characterCount <= 65){

        modifiedTitle = modifiedTitle + " " + string;

      }

      return string

    })

    if(characterCount > 65){
      modifiedTitle = modifiedTitle + "...";
    }

    let cardImage;
    if (this.props.poster.supporting_file_url) {
      cardImage=this.props.poster.supporting_file_url
    } else if (this.props.topicMap[this.props.poster.topic]) {
      cardImage=this.props.topicMap[this.props.poster.topic]
    } else if (this.props.event.event.topic_map[this.props.poster.topic]) {
      cardImage=this.props.event.event.topic_map[this.props.poster.topic]
    } else {
      cardImage=fallbackImages.posterCard
    }
      
    return (
      <Card
        className={classes.root}
        raised={this.state.isHovering}
        onMouseEnter={() => this.handleChange(true, 'isHovering')}
        onMouseLeave={() => this.handleChange(false, 'isHovering')}
        onClick={
          this.state.buttonHover
            ? null
            : (event) =>
                this.props.toggleFullscreenHandlerOn(
                  event,
                  this.props.poster.abstract_id
                )
        }
      >
        <CardMedia
          className={classes.media}
          image={cardImage}
          title='Poster Presentation'
        />
        <CardContent>
          <Typography
            className={classes.title}
            variant='h5'
            color='textPrimary'
            align="center"
          >
            {modifiedTitle}
          </Typography>
          <Divider className={classes.divider} variant='middle' />

          {this.props.poster.contacts.map((contact, index) => {


            
            if(contact.role.toLowerCase() === 'presenter'){

              return (
                <Grid container key={index} style={{alignItems: 'center',}}>
                  {contact.photo_url ? (
                    <Grid item xs={2} >
                      <img
                      className={classes.linePhoto}
                      src={contact.photo_url}
                      alt={"Presenter"}
                      />
                    </Grid>
                  ) : (
                    null
                  )}
                  <Grid item xs={10} style={{paddingLeft: '8px'}}>
                    <Typography
                      className={classes.lineSpeakerName}
                      align='center'
                      variant='h6'
                      color='textPrimary'
                    >
                      {contact.first_name + ' ' + contact.last_name}
                    </Typography>
                    {contact.title
                      ?
                      <Typography
                        className={classes.lineSpeakerTitle}
                        align='center'
                      >
                        {contact.title}
                      </Typography>
                      :
                      <React.Fragment />
                    }
                    {contact.account_name
                      ?
                      <Typography
                        className={classes.lineSpeakerCompany}
                        align='center'
                      >
                        {contact.account_name}
                      </Typography>
                      :
                      <React.Fragment />
                    }
                  </Grid>
                </Grid>
              )

            }

            return null

          })}

          <Divider className={classes.divider} variant='middle' />
          <br/>
          <Typography variant='body1' color='textSecondary' component='p'  align='left'>
          <b>Topic: </b>{this.props.poster.topic}
          </Typography>
          <Typography variant='body1' color='textSecondary' component='p'  align='left'>
            {this.props.poster.cleared_for_public_view === "Yes - Approved" ? <React.Fragment><b>Access: </b>Public</React.Fragment> : <React.Fragment><b>Access: </b>Private</React.Fragment>}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {favorited ? (
            <IconButton
              aria-label='remove from briefcase'
              onClick={() =>
                this.props.deleteBriefcaseElement(this.props.poster.abstract_id)
              }
              onMouseEnter={() => this.handleChange(true, 'buttonHover')}
              onMouseLeave={() => this.handleChange(false, 'buttonHover')}
            >
              <WorkIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label='add to briefcase'
              onClick={() =>
                this.props.addBriefcaseElement(
                  'Poster',
                  this.props.poster.abstract_id,
                  this.props.poster.title,
                  this.props.poster.account_id,
                  this.props.user.user.account_id
                )
              }
              onMouseEnter={() => this.handleChange(true, 'buttonHover')}
              onMouseLeave={() => this.handleChange(false, 'buttonHover')}
            >
              <WorkOutlineIcon />
            </IconButton>
          )}
            <IconButton
                aria-label='upvote'
                onClick={() => {
                  this.upvotePoster()
                }}
                onMouseEnter={() => this.handleChange(true, 'buttonHover')}
                onMouseLeave={() => this.handleChange(false, 'buttonHover')}
              >
                {this.props.poster.liked ? <ThumbUpIcon /> : <ThumbUpIconOutlined />}
              <Typography variant='subtitle1' style={{marginLeft: '4px'}}>{this.props.poster.count}</Typography>
            </IconButton>
        </CardActions>
        {this.state.posterPresenterPresent ?
        <Typography
              variant='h5'
              className={classes.sponsorLevel}
              align='center'
              color='textPrimary'
              style={{
                backgroundImage: `linear-gradient(${this.props.event.event.main_color}, ${this.props.event.event.light_color})`,
              }}
            >
              Live Now
            </Typography>
            : null
            }
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addBriefcaseElement: addBriefcaseElement,
      deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(PosterCard));
