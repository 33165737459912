import React, { Component } from 'react';
import PubNubReact from 'pubnub';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { withAuthenticator } from 'aws-amplify-react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../../Store/Actions/getChatMessages';
import addChatSubscription from '../../Store/Actions/addChatSubscription';
import setChatMembership from '../../Store/Actions/setChatMembership';
import selectActivePrivateChat from '../../Store/Actions/selectActivePrivateChat';
import setChatbar from '../../Store/Actions/setChatbar';

const useStyles = theme => ({
    root: {
        width: '100%',
        '& .MuiTypography-root': {
          alignSelf: 'center',
        },
        '& .MuiChip-root	': {
          alignSelf: 'center',
        },
        '& .MuiAccordionSummary-content': {
          margin: theme.spacing(0.5),
        },
      },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '80%'
    },
    formControlButton: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '50%'
    },
    button: {
        color: "white",
        backgroundColor: theme.palette.primary.main
    },
    own: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        padding: '5px',
        marginBottom: '3px',
        borderRadius: '15px',
        maxWidth: '100%',
    },
    other: {
    },
      expansionPanelMain: {padding: '0px 8px 0px 8px'},
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '60%',
        flexShrink: 0,
      },
      icon: {
        minWidth: '10em',
        maxWidth: '30em',
        maxHeight: '30em',
        width: '100%',
      },
     merck: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
     }
});

class Chat extends Component {
    constructor(props) {
        super(props);
        this.pubnub = new PubNubReact({
            publishKey: this.props.event.event.publish_key,
            subscribeKey: this.props.event.event.subscribe_key,
            uuid: this.props.user.user.email,
            autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
            restore: true, // enable catchup on missed messages
        });
        this.state = {
            messages: [],
            input: '',
            channelOccupants: [],
            liveNow: false,
        };
    }

    componentDidMount = async () => {

        try {

            await this.pubnub.addListener({
                status: function (statusEvent) {
                },
                message: (response) => { 
                  const newMessage = {
                    channel: response.channel,
                    timetoken: response.timetoken,
                    entry: {
                        from: response.message.from,
                        text: response.message.text,
                        accountName: response.message.accountName,
                        department: response.message.department,
                        email: response.message.email,
                        first_name: response.message.first_name,
                        last_name: response.message.last_name,
                        roles: response.message.roles,
                        site: response.message.site,
                        title: response.message.title,
                        }
                    }
                    this.setState({
                        messages: [...this.state.messages, newMessage]
                    })
                },
                presence: (presenceEvent) => {
                    if(presenceEvent.action === 'join'){

                        this.pubnub.objects.getUUIDMetadata({
                            uuid: presenceEvent.uuid
                        }).then((response) => {

                            this.setState({
                                channelOccupants: [...this.state.channelOccupants, response.data]
                            })

        
                        })

                    } else if (presenceEvent.action === 'leave'){

                        const array = [...this.state.channelOccupants]

                        var elementPos = array.map((x) => x.id ).indexOf(presenceEvent.uuid)
                        
                        if (elementPos !== -1) {
                          array.splice(elementPos, 1);
                          this.setState({channelOccupants: array})
                        }
                    }
                }
            })

            if(this.props.poster.presenters.find((e) => this.props.user.user.email === e.email)){

                await this.pubnub.history({
                    channel: `${this.props.poster.abstract_id}livenow`,
                    count: 1, // how many items to fetch
                    stringifiedTimeToken: true, // false is the default
                },
                    (status, response) => {
                      if(response.messages.length){
                        if(response.messages[0].entry.text === 'livenow'){
                          this.setState({
                            liveNow: true,
                          })
                        } else if(response.messages[0].entry.text === 'goingdark'){
                          this.setState({
                            liveNow: false,
                          })
                        }
                      }
                    }
                );

            } 
                await this.pubnub.subscribe({
                    channels: [this.props.channel],
                    withPresence: true,
                });


            const currentUsers = await this.pubnub.hereNow({
                channels: [this.props.channel],
                includeUUIDs: true,
                includeState: true
            });

            let newUsers = [];

            const detailedUsers = await currentUsers.channels[this.props.channel].occupants.map(occupant => {
                return (
                this.pubnub.objects.getUUIDMetadata({
                    uuid: occupant.uuid,
                }).then((response) => {
                    newUsers.push(response.data)
                })
                )
            })

            await Promise.all(detailedUsers).then(res => {
                this.setState({
                    channelOccupants: [...this.state.channelOccupants, ...newUsers]
                })
            })

            await this.pubnub.history({
                channel: this.props.channel,
                count: 100, // how many items to fetch
                stringifiedTimeToken: true, // false is the default
            },
                (status, response) => {
                    this.setState({
                        messages: [...this.state.messages, ...response.messages]
                    })
                }
            );

        } catch (error) {
            console.log(error)
        }
    }

    async componentWillUnmount() {

            await this.pubnub.unsubscribe({
                channels: [this.props.channel]
            });
        
        await this.pubnub.removeListener()
        
    }

    handleChange(event, inputIdentifier) {
        this.setState({ [inputIdentifier]: event.target.value });
    }

    inviteUser = async (user) => {

        // inviteUser

        let emailArray = [this.props.user.user.email, user].sort()
        let emails = `${emailArray[0]}${emailArray[1]}${'planetconnect'}`

        const hashCode = s => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0)

        const hashedEmails = hashCode(emails)

        await this.pubnub.objects.setChannelMembers({
            channel: hashedEmails,
            uuids: [ 
                this.props.user.user.email, { 
                    id: this.props.user.user.email, 
                    custom: { trialPeriod: false } 
                },
                user, { 
                    id: user, 
                    custom: { trialPeriod: false } 
                }
            ],
          });
    
          await this.pubnub.publish(
            {
                channel: user,
                message: {
                    text: `${this.props.user.user.first_name} ${this.props.user.user.last_name} has invited you to chat!`,
                    from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
                    accountName: this.props.user.user.account_name,
                    department: this.props.user.user.department,
                    email: this.props.user.user.email,
                    first_name: this.props.user.user.first_name,
                    last_name: this.props.user.user.last_name,
                    roles: this.props.user.user.roles,
                    site: this.props.user.user.site,
                    title: this.props.user.user.title,
                    channel: hashedEmails,
                },
            });

            await this.pubnub.publish(
                {
                    channel: this.props.user.user.email,
                    message: {
                        text: `You're entering a new chat!`,
                        from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
                        accountName: this.props.user.user.account_name,
                        department: this.props.user.user.department,
                        email: this.props.user.user.email,
                        first_name: this.props.user.user.first_name,
                        last_name: this.props.user.user.last_name,
                        roles: this.props.user.user.roles,
                        site: this.props.user.user.site,
                        title: this.props.user.user.title,
                        channel: hashedEmails,
                    },
                });
                await this.props.setChatbar(true)
                await this.props.selectActivePrivateChat(hashedEmails, user)
    }

    sendMessage = async () => {
        if (this.state.input) {
            
            await this.pubnub.publish(
                {
                    channel: this.props.channel,
                    message: {
                        text: this.state.input,
                        from: `${this.props.user.user.first_name} ${this.props.user.user.last_name}`,
                        accountName: this.props.user.user.account_name,
                        department: this.props.user.user.department,
                        email: this.props.user.user.email,
                        first_name: this.props.user.user.first_name,
                        last_name: this.props.user.user.last_name,
                        roles: this.props.user.user.roles,
                        site: this.props.user.user.site,
                        title: this.props.user.user.title,
                    },
                });

                await this.setState({
                    input: '',
                })
        }
    }

     handleSwitch = async () =>{

        if(!this.state.liveNow){

            await this.pubnub.publish(
                {
                    channel: `${this.props.channel}livenow`,
                    message: {
                        text: 'livenow',
                        from: `${this.props.user.user.email}`,
                    }
                });
    
    
            this.setState({
                liveNow: true
            })

        } else {

            await this.pubnub.publish(
                {
                    channel: `${this.props.channel}livenow`,
                    message: {
                        text: 'goingdark',
                        from: `${this.props.user.user.email}`,
                    }
                });
    
    
            this.setState({
                liveNow: false
            })

        }
    }

    render() {
        const { classes } = this.props;

        let occupants = this.state.channelOccupants.map((occupant, index) => {

            if(occupant.id === this.props.user.user.email){
                return <React.Fragment key={occupant.email+index}></React.Fragment>
            }

            let color = 'other'
            
            return (
                <Accordion
                key={occupant.email}
                className={classes.root}
              >
                <AccordionSummary
                  className={classes.expansionPanelMain + " " + classes[color]}
                  expandIcon={<ExpandMoreIcon />}
                >
                <Typography variant="body1" component='div'>
                        {occupant.name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    layout={'row'}
                    spacing={2}
                    alignItems='center'
                    justify='space-between'
                  >
                    <Grid item xs={12}>
                    <Typography variant="body1" align='left' component='div'>
                        {occupant.custom.accountName? <p><b>Company:</b> {occupant.custom.accountName}</p>: <p></p>}
                    </Typography>
                    <Typography variant="body1" align='left' component='div'>
                        {occupant.custom.title ? <p><b>Title:</b> {occupant.custom.title}</p>: <p></p>}   
                    </Typography>
                    <Typography variant="body1" align='left' component='div'>
                        {occupant.custom.department ? <p><b>Department:</b> {occupant.custom.department}</p>: <p></p>}
                    </Typography>
                    <Typography variant="body1" align='left' component='div'>
                        {occupant.custom.site ? <p><b>Site:</b> {occupant.custom.site}</p>: <p></p>}
                    </Typography>
                    {this.props.user.user.email !== occupant.email && !this.props.chat.users.find((user) => user.email === occupant.email) ?  //|| this.props.chat.indexOf(occupant.email)
                    <FormControl className={classes.formControlButton}>
                        <Button variant="contained" className={classes.button} onClick={() => this.inviteUser(occupant.id)} >
                             Invite to Chat
                        </Button>
                    </FormControl>
                    : <div></div>}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
        })


        let previousSender;
        let messages = this.state.messages.map((message) => {
            if (!message.entry.text) {
                return null
            }

            let align = 'left'
            let color = 'other'

            if (message.entry.email === this.props.user.user.email) {
                align = 'right'
                color = 'own'
            }

            if (previousSender === message.entry.email) {
                return (
                    <React.Fragment key={message.timetoken}>
                        <Typography variant="body1" component='div' align={align} >
                            <div className={classes[color]}>{message.entry.text}</div>
                        </Typography>
                    </React.Fragment>
                )
            } else {
                previousSender = message.entry.email
                return (
                    <React.Fragment key={message.timetoken}>
                        <br />
                        <Typography variant="h6" component='div' align={align}>
                            {message.entry.from ? message.entry.from : 'Anonymous'}
                        </Typography>
                        <Typography variant="body1" component='div' align={align} className={classes[color]}>
                            {message.entry.text}
                        </Typography>
                    </React.Fragment>
                )
            }
        })

        return (
            <div className={classes.grid} >
                <Grid container layout={"row"} justify="center" spacing={0} >
                {this.props.poster.presenters.find((e) => this.props.user.user.email === e.email) ?
                <Grid item xs={12} style={{ maxHeight: '80vw', overflowY: 'scroll', overflowX: 'hidden', padding: '2vw' }}>
                    <Typography variant="h5" component='div' align='center'>
                        Live Now
                    </Typography>
                    <Switch
                        checked={this.state.liveNow}
                        onChange={this.handleSwitch}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </Grid>
                :
                null
                }
                    <Grid item xs={4} style={{ maxHeight: '80vw', overflowY: 'scroll', overflowX: 'hidden', padding: '2vw' }}>
                        <Typography variant="h5" component='div' align='center'>
                            Here Now
                        </Typography>
                        <br/><br/>
                        {occupants}
                    </Grid>
                    <Grid item xs={8} style={{ maxHeight: '80vw', overflowY: 'scroll', padding: '2vw' }}>
                        <Typography variant="h5" component='div' align='center'>
                            Group Conversation
                        </Typography>
                        <br/><br/>
                        <Paper className={classes.paper}>
                            {messages}
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField onChange={(event) => this.handleChange(event, "input")}
                                    value={this.state.input}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlButton}>
                                <Button variant="contained" onClick={this.sendMessage} className={classes.button}>
                                    Send
                             </Button>
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const MyTheme = {
    button: { backgroundColor: '#6f1a1d' },
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getChatMessages: getChatMessages,
        addChatSubscription: addChatSubscription,
        setChatMembership: setChatMembership,
        selectActivePrivateChat: selectActivePrivateChat,
        setChatbar: setChatbar,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        user: state.user,
        chat: state.chat,
        event: state.event,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(withAuthenticator(Chat, false, [], null, MyTheme))));