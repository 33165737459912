import { GET_PRIVATE_CHAT_MESSAGES, GET_GROUP_CHAT_MESSAGES } from './types';
import PubNub from 'pubnub';

export default function getChatMessages(user, channel, type) {
  return async (dispatch, getState) => {
  
    const items = getState().debug;
    const event = getState().event;
          
    const pubnub = new PubNub({
      publishKey: event.event.publish_key,
      subscribeKey: event.event.subscribe_key,
      uuid: user.email,
    });

    try { 

        await pubnub.history({
            channel: channel,
            count: 20, // how many items to fetch
            stringifiedTimeToken: true, // false is the default
        },
            (status, response) => {
                dispatch(getChatMessagesAsync(response.messages, channel, type));
            }
        );

  } catch (error) {
    if (items.debug) {
      alert(`Error: Could not get the chat messages. ${error}`)
    }
  }
    
  }
}

function getChatMessagesAsync(messages, channel, type ='private'){

    if (type === 'group'){
        return {
            type: GET_GROUP_CHAT_MESSAGES,
            payload: {
              messages,
              channel
            }
          }
    } else {
        return {
            type: GET_PRIVATE_CHAT_MESSAGES,
            payload: {
              messages,
              channel
            }
          } 
    }

}